import type { SimpleProjectDto } from "api/types";
import { Button } from "components/Button/Button";
import { Form } from "components/Form/Form";
import { FormContent, FormSplitContent } from "components/Form/FormContent";
import { FormField } from "components/Form/FormField";
import { FormHiddenInput } from "components/Form/FormHiddenInput";
import { FormInput } from "components/Form/FormInput";
import { FormMultiSelect } from "components/Form/FormMultiSelect";
import { FormTextArea } from "components/Form/FormTextArea";
import type { ModalBaseProps } from "components/Modal/Modal";
import { Modal } from "components/Modal/Modal";
import { Capture2, Subtitle2 } from "components/Text/Text";
import { createRequiredStringRule } from "helpers/rules";
import { parseAsNumber, sortAlphabetically } from "helpers/util";
import { isValidEmail } from "helpers/validation";
import { useConnectedProjects } from "hooks/Network/useConnectedProjects";
import type React from "react";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import type { AlertType, SaveRequest } from "../Layout";

const MIN_PERCENTAGE = 1;
const MAX_PERCENTAGE = 100;
const MIN_THRESHOLD = 1;

export interface AlertDetailFormValues {
  id: string;
  enabled: boolean;
  emailEnabled: boolean;
  type: AlertType;
  name: string;
  email: string;
  projects: SimpleProjectDto[];
  threshold?: string;
  keywords?: string;
}

type AlertDetailsModalProps = ModalBaseProps & {
  alert: AlertDetailFormValues;
  onSave: ({ id, type, payload }: { id: string; type: AlertType; payload: SaveRequest }) => Promise<void>;
};

export function AlertDetailsModal({ alert, isOpened, onOpenChange, onSave }: AlertDetailsModalProps): React.ReactNode {
  const { t } = useTranslation();
  const { data: projects = [] } = useConnectedProjects();
  const form = useForm<AlertDetailFormValues>({ defaultValues: alert });

  useEffect(() => {
    form.reset(alert);
  }, [alert, form]);

  function handleClose() {
    form.reset();
    onOpenChange(false);
  }

  async function handleSave() {
    const formValues = form.getValues();
    const payload: SaveRequest = {
      enabled: formValues.enabled,
      enableEmail: formValues.emailEnabled,
      name: formValues.name,
      email: formValues.email,
      projectIds: formValues.projects.map((project) => project.id),
      threshold: parseAsNumber(formValues.threshold),
      keywords: formValues.keywords?.split(","),
    };

    await onSave({ id: formValues.id, type: formValues.type, payload });
    form.reset();
    onOpenChange(false);
  }

  function addAllProjects() {
    form.setValue("projects", projects);
  }

  function clearProjectSelection() {
    form.setValue("projects", []);
  }

  function getAlertTitle() {
    switch (alert.type) {
      case "likes":
        return t("page.alerts.modal.alert-type.title.likes");
      case "comments":
        return t("page.alerts.modal.alert-type.title.comments");
      case "keywords_post":
        return t("page.alerts.modal.alert-type.title.post-keywords");
      case "keywords_comments":
        return t("page.alerts.modal.alert-type.title.comment-keywords");
    }
  }

  function getAlertDescription() {
    switch (alert.type) {
      case "likes":
        return t("page.alerts.modal.alert-type.description.likes");
      case "comments":
        return t("page.alerts.modal.alert-type.description.comments");
      case "keywords_post":
        return t("page.alerts.modal.alert-type.description.post-keywords");
      case "keywords_comments":
        return t("page.alerts.modal.alert-type.description.comment-keywords");
    }
  }

  return (
    <Modal.Root
      title={alert.id ? t("page.alerts.modal.edit.title") : t("page.alerts.modal.create.title")}
      isOpened={isOpened}
      onOpenChange={onOpenChange}
    >
      <div className="flex flex-col gap-1">
        <Subtitle2>{getAlertTitle()}</Subtitle2>
        <Capture2>{getAlertDescription()}</Capture2>
      </div>
      <Form formMethods={form} onSubmit={handleSave}>
        <FormContent maxWidth="xl">
          <FormHiddenInput<AlertDetailFormValues> name="id" />
          <FormHiddenInput<AlertDetailFormValues> name="enabled" />
          <FormHiddenInput<AlertDetailFormValues> name="emailEnabled" />
          <FormHiddenInput<AlertDetailFormValues> name="type" />
          <FormField label={t("page.alerts.modal.form.name.title")} required>
            <FormInput<AlertDetailFormValues, "name">
              name="name"
              placeholder={t("page.alerts.modal.form.name.placeholder")}
              rules={{
                validate: {
                  required: createRequiredStringRule(t, "page.alerts.modal.form.name.title"),
                },
              }}
            />
          </FormField>
          <FormField
            label={t("page.alerts.modal.form.email.title")}
            tooltip={t("page.alerts.modal.form.email.tooltip")}
            required
          >
            <FormInput<AlertDetailFormValues, "email">
              name="email"
              placeholder={t("page.alerts.modal.form.email.placeholder")}
              rules={{
                validate: {
                  required: createRequiredStringRule(t, "page.alerts.modal.form.email.title"),
                  isValid(value) {
                    value = value?.trim();

                    if (!value) {
                      return;
                    }

                    return isValidEmail(value) ? undefined : t("components.form.error.invalid-email-address");
                  },
                },
              }}
            />
          </FormField>
          <FormSplitContent>
            <FormField label={t("page.alerts.modal.form.projects.title")} required>
              <FormMultiSelect<AlertDetailFormValues, "projects">
                name="projects"
                placeholder={t("page.alerts.modal.form.projects.placeholder")}
                items={projects ? projects : []}
                sortSelectedItems={sortAlphabetically}
                keySelector={(x) => x.id}
                renderOption={(x) => x.name}
                rules={{
                  required: t("components.form.error.required", {
                    inputName: t("page.alerts.modal.form.projects.title"),
                  }),
                }}
              />
            </FormField>
            <div className="flex flex-col gap-2">
              <Button className="w-full" styling="secondary" onClick={addAllProjects}>
                {t("page.alerts.modal.form.projects.add-all")}
              </Button>
              <Button className="w-full" styling="danger" onClick={clearProjectSelection}>
                {t("page.alerts.modal.form.projects.clear-all")}
              </Button>
            </div>
          </FormSplitContent>
          {alert.type === "likes" && (
            <FormField
              label={t("page.alerts.modal.form.percentage-threshold.title")}
              tooltip={t("page.alerts.modal.form.percentage-threshold.tooltip")}
              required
            >
              <FormInput<AlertDetailFormValues>
                name="threshold"
                inputMode="numeric"
                type="number"
                placeholder={t("page.alerts.modal.form.percentage-threshold.placeholder")}
                rules={{
                  required: t("components.form.error.required", {
                    inputName: t("page.alerts.modal.form.percentage-threshold.title"),
                  }),
                  min: {
                    message: t("page.alerts.modal.form.percentage-threshold.rules.min", { count: MIN_PERCENTAGE }),
                    value: MIN_PERCENTAGE,
                  },
                  max: {
                    message: t("page.alerts.modal.form.percentage-threshold.rules.max", { count: MAX_PERCENTAGE }),
                    value: MAX_PERCENTAGE,
                  },
                }}
              />
            </FormField>
          )}
          {alert.type === "comments" && (
            <FormField
              label={t("page.alerts.modal.form.exact-threshold.title")}
              tooltip={t("page.alerts.modal.form.exact-threshold.tooltip")}
              required
            >
              <FormInput<AlertDetailFormValues>
                name="threshold"
                inputMode="numeric"
                type="number"
                placeholder={t("page.alerts.modal.form.exact-threshold.placeholder")}
                rules={{
                  required: t("components.form.error.required", {
                    inputName: t("page.alerts.modal.form.exact-threshold.title"),
                  }),
                  min: {
                    message: t("page.alerts.modal.form.exact-threshold.rules.min", { count: MIN_THRESHOLD }),
                    value: MIN_THRESHOLD,
                  },
                }}
              />
            </FormField>
          )}
          {(alert.type === "keywords_post" || alert.type === "keywords_comments") && (
            <FormField
              label={t("page.alerts.modal.form.keywords.title")}
              description={t("page.alerts.modal.form.keywords.subtitle")}
              tooltip={t("page.alerts.modal.form.keywords.tooltip")}
              required
            >
              <FormTextArea<AlertDetailFormValues>
                name="keywords"
                placeholder={t("page.alerts.modal.form.keywords.placeholder")}
                rules={{
                  required: t("components.form.error.required", {
                    inputName: t("page.alerts.modal.form.keywords.title"),
                  }),
                }}
              />
            </FormField>
          )}
        </FormContent>
        <div className="flex justify-end gap-4">
          <Button styling="secondary" onClick={handleClose}>
            {t("common.action.cancel")}
          </Button>
          <Button styling="primary" type="submit" isLoading={false}>
            {t("common.action.save")}
          </Button>
        </div>
      </Form>
    </Modal.Root>
  );
}
