import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useApi } from "api/hooks/useApi";
import { useImageResolver } from "api/hooks/useImageResolver";
import type {
  CommunityFeedAudienceGroupDto,
  CommunityFeedAudienceProjectConnectionDto,
  CommunityFeedAudienceProjectDto,
  CommunityFeedSelectableAudienceDto,
  CommunityGroupDetailsV2Dto,
  MessageCreateRequest,
} from "api/types";
import { Button } from "components/Button/Button";
import type { FormDocument } from "components/DocumentInput/useDocumentFile";
import { useFlashToast } from "components/FlashToast/FlashToast";
import { Form } from "components/Form/Form";
import { FormCheckbox } from "components/Form/FormCheckbox";
import { FormContent } from "components/Form/FormContent";
import { FormDocumentInput } from "components/Form/FormDocumentInput";
import { FormField } from "components/Form/FormField";
import { FormImageInput } from "components/Form/FormImageInput";
import { FormInput } from "components/Form/FormInput";
import { FormSelect } from "components/Form/FormSelect";
import { FormTextArea } from "components/Form/FormTextArea";
import { formatDistance } from "components/FormattedDistance/FormattedDistance";
import { LoadingIcon } from "components/Icons/Icons";
import type { FormImage } from "components/ImageInput/useImageInput";
import type { ModalBaseProps } from "components/Modal/Modal";
import { Modal } from "components/Modal/Modal";
import { Capture2 } from "components/Text/Text";
import { validateSize } from "helpers/file-size";
import { commonAPIDataSelector } from "helpers/Network/selectors";
import { createRequiredStringRule } from "helpers/rules";
import { useProjectId } from "hooks/Network/useProjectId";
import { useSessionUser } from "hooks/Network/useSessionUser";
import { useUploadDocument } from "hooks/Network/useUploadDocument";
import { useUploadImage } from "hooks/Network/useUploadImage";
import { orderBy } from "lodash-es";
import { GroupIcon } from "modules/community-groups/components/GroupIcons";
import { MAX_AMOUNT_IMAGES_PER_POST } from "modules/messages/constants";
import { useConfig } from "providers/ConfigProvider";
import { QUERY_KEYS } from "query-keys";
import { useMemo } from "react";
import { useForm, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { PromotedGroupCard } from "./PromotedGroupCard";

interface ResidentCreatePost {
  onClose: () => void;
  messageType: "askHelp" | "inform" | "sellItem";
  defaultGroupId?: string;
  promotedGroup?: CommunityGroupDetailsV2Dto;
}

type GroupItem =
  | { type: "group"; value: CommunityFeedAudienceGroupDto }
  | { type: "project"; value: CommunityFeedAudienceProjectDto }
  | { type: "projectConnection"; value: CommunityFeedAudienceProjectConnectionDto };

export interface FormValues {
  group?: GroupItem;
  audience?: CommunityFeedSelectableAudienceDto;
  title: string;
  message: string;
  hideFromAdmin: boolean;
  images: FormImage[];
  documents: FormDocument[];
}

const MAX_LENGTH = {
  SUBJECT: 255,
  DESCRIPTION: 10000,
};

export function ResidentCreatePost({
  onClose,
  messageType,
  defaultGroupId,
  promotedGroup,
}: ResidentCreatePost): React.ReactNode {
  const projectId = useProjectId();
  const { t } = useTranslation();
  const sessionUser = useSessionUser();
  const showFlashToast = useFlashToast();
  const queryClient = useQueryClient();
  const api = useApi();

  const {
    data: postAudience,
    isLoading: isLoadingAudience,
    error: errorLoadingAudience,
  } = useQuery({
    queryKey: QUERY_KEYS.MESSAGES_AUDIENCE(projectId, promotedGroup ? { ExcludeProjectConnections: true } : undefined),
    queryFn: async () =>
      api.getCommunityFeedAudienceV1(promotedGroup ? { ExcludeProjectConnections: true } : undefined),
    select: commonAPIDataSelector,
  });

  const createMessage = useMutation({
    mutationFn: ({ payload }: { payload: MessageCreateRequest }) => api.postMessagesV1(payload).then((x) => x.data),
    onSuccess: () => {
      showFlashToast({ type: "success", title: t("page.message-feed.create.notifications.success") });
      void queryClient.invalidateQueries({ queryKey: QUERY_KEYS.MESSAGES(projectId) });
      if (promotedGroup) {
        void queryClient.invalidateQueries({
          queryKey: QUERY_KEYS.COMMUNITY_GROUP_DETAILS(projectId, promotedGroup.id),
        });
      }
    },
    onError() {
      showFlashToast({ type: "error", title: t("page.message-feed.create.notifications.error") });
    },
  });

  const { isUploadingImage, uploadFormImage } = useUploadImage();
  const { isUploadingDocument, uploadFormDocument } = useUploadDocument();

  async function handleSubmit(values: FormValues) {
    const imageUploadPromises = values.images.map((image) => uploadFormImage(image));
    // Curently only 1 document is allowed
    const documentUploadPromises = values.documents.map((document) => uploadFormDocument(document));

    const imageIds = (await Promise.allSettled(imageUploadPromises))
      .map((image) => (image.status === "fulfilled" ? image.value?.id : ""))
      .filter(Boolean) as string[];
    const documentIds = (await Promise.allSettled(documentUploadPromises))
      .map((document) => (document.status === "fulfilled" ? document.value?.id : ""))
      .filter(Boolean) as string[];

    const payload: MessageCreateRequest = {
      type: "undefined",
      messageLabel: messageType,
      groupId: values.group?.type === "group" ? values.group.value.id : undefined,
      projectConnectionId:
        values.group?.type === "projectConnection"
          ? values.group.value.id
          : values.audience?.type === "projectConnection"
            ? values.audience.projectConnection?.id
            : undefined,
      title: values.title,
      content: values.message,
      isHiddenFromAdmins:
        !promotedGroup && values.audience?.type !== "projectConnection" && values.group?.type !== "projectConnection"
          ? values.hideFromAdmin
          : false,
      imageIds,
      documentIds,
      relatedGroupId: promotedGroup ? promotedGroup.id : undefined,
    };

    await createMessage.mutateAsync({ payload });
    onClose();
  }

  const groups = useMemo(() => {
    if (!postAudience) {
      return [];
    }

    function toType<T extends GroupItem["type"]>(type: T): (value: GroupItem["value"]) => GroupItem {
      return (value) =>
        ({
          type,
          value,
        }) as GroupItem;
    }

    const audience: GroupItem[] = [];
    if (messageType === "sellItem") {
      audience.push(
        ...postAudience.interestGroups.filter((x) => x.id === sessionUser.marketPlaceGroupId).map(toType("group")),
      );
    } else if (messageType === "inform") {
      audience.push(
        ...postAudience.interestGroups.filter((x) => x.id !== sessionUser.marketPlaceGroupId).map(toType("group")),
      );
      audience.push(...postAudience.realEstateGroups.map(toType("group")));
      if (postAudience.project) {
        audience.push(toType("project")(postAudience.project));
      }
      audience.push(...postAudience.projectConnections.map(toType("projectConnection")));
    } else {
      audience.push(...postAudience.helpCategories.map(toType("group")));
      audience.push(
        ...postAudience.interestGroups.filter((x) => x.id !== sessionUser.marketPlaceGroupId).map(toType("group")),
      );
      audience.push(...postAudience.realEstateGroups.map(toType("group")));
      if (postAudience.project) {
        audience.push(toType("project")(postAudience.project));
      }
      audience.push(...postAudience.projectConnections.map(toType("projectConnection")));
    }

    if (promotedGroup) {
      return audience.filter((x) => x.value.id !== promotedGroup.id);
    }

    return audience;
  }, [messageType, postAudience, sessionUser, promotedGroup]);

  const isPosting = isUploadingImage || isUploadingDocument || createMessage.isPending;

  return (
    <div className="flex flex-col">
      {isLoadingAudience && (
        <div className="flex justify-center py-16">
          <LoadingIcon className="w-8" />
        </div>
      )}
      {!isLoadingAudience && errorLoadingAudience && (
        <Capture2 as="p" className="flex justify-center py-16">
          <span className="block min-h-8 text-red-dark ">
            {t("page.message-feed.resident-create-post.loading.error")}
          </span>
        </Capture2>
      )}
      {!isLoadingAudience && !errorLoadingAudience && (
        <CreatePostForm
          defaultGroupId={defaultGroupId}
          promotedGroup={promotedGroup}
          messageType={messageType}
          groups={groups}
          isPosting={isPosting}
          onSubmit={handleSubmit}
          onClose={onClose}
        />
      )}
    </div>
  );
}

function CreatePostForm({
  defaultGroupId,
  promotedGroup,
  messageType,
  onSubmit,
  groups,
  isPosting,
}: {
  defaultGroupId?: string;
  promotedGroup?: CommunityGroupDetailsV2Dto;
  messageType: "askHelp" | "inform" | "sellItem";
  groups: GroupItem[];
  isPosting: boolean;
  onSubmit: (values: FormValues) => void;
  onClose: () => void;
}) {
  const { t } = useTranslation();
  const sessionUser = useSessionUser();
  const isMultipleImagesAllowedForCommunityPost = useConfig("isMultipleImagesAllowedForCommunityPost");

  const defaultGroup = defaultGroupId
    ? groups.find((x) => x.type === "group" && x.value.id === defaultGroupId)
    : messageType === "sellItem"
      ? groups[0]
      : undefined;

  const form = useForm<FormValues>({
    defaultValues: {
      title: promotedGroup
        ? t("page.message-feed.resident-create-post.form.title.placeholder.promote-group", {
            groupName: promotedGroup.name,
          })
        : undefined,
      message: promotedGroup
        ? t("page.message-feed.resident-create-post.form.content.placeholder.promote-group")
        : undefined,
      documents: [],
      images: [],
      group: defaultGroup,
      audience:
        defaultGroup && defaultGroup.type === "group"
          ? orderBy(defaultGroup.value.selectableAudience, (x) => x.type === defaultGroup.value.defaultPostingLevel)[0]
          : undefined,
    },
  });

  const group = useWatch({ control: form.control, name: "group" });
  const audience = useWatch({ control: form.control, name: "audience" });
  const documents = useWatch({ control: form.control, name: "documents" });
  const images = useWatch({ control: form.control, name: "images" });

  const promotedGroupImage =
    promotedGroup && promotedGroup.image ? promotedGroup.image : sessionUser.project.backgroundImage;

  return (
    <Form data-testid="resident-create-post-form" formMethods={form} onSubmit={onSubmit}>
      <FormContent className="mb-0" maxWidth="none">
        <FormField htmlFor="group" label={t("page.message-feed.resident-create-post.form.audience.title")} required>
          <FormSelect<FormValues, GroupItem>
            id="group"
            name="group"
            placeholder={t("page.message-feed.resident-create-post.form.audience.placeholder")}
            items={groups}
            keySelector={(x) => x.type + x.value.id}
            renderSelected={(x) => <GroupOption group={x} isGroup={x.type === "group"} isPreview />}
            renderOption={(x) => <GroupOption group={x} isGroup={x.type === "group"} />}
            groupSelector={(x) => {
              if (x.type === "group") {
                switch (x.value.type) {
                  case "helpCategory":
                    return t("page.message-feed.resident-create-post.form.audience.option-groups.help-categories");
                  case "interest":
                    return t("page.message-feed.resident-create-post.form.audience.option-groups.interest-groups");
                  default:
                    return t("page.message-feed.resident-create-post.form.audience.option-groups.real-estate");
                }
              } else {
                if (x.type === "project") {
                  return t("page.message-feed.resident-create-post.form.audience.option-groups.message-all");
                }

                return t("page.message-feed.resident-create-post.form.audience.option-groups.connections");
              }
            }}
            onChange={(x) => {
              if (x?.type === "group") {
                const defaultAudience = x.value.selectableAudience.find((a) => a.type === x.value.defaultPostingLevel);

                form.setValue("audience", defaultAudience);
                if (defaultAudience?.type === "projectConnection") {
                  form.setValue("hideFromAdmin", false);
                }
              }

              if (x?.type === "projectConnection") {
                form.setValue("hideFromAdmin", false);
              }
            }}
            disabled={messageType === "sellItem" || defaultGroup != null}
            rules={{
              required: t("components.form.error.required", {
                inputName: t("page.message-feed.resident-create-post.form.audience.title"),
              }),
            }}
          />
          {group?.type === "group" &&
          (group.value.selectableAudience.length > 1 ||
            (group.value.selectableAudience.length === 1 &&
              group.value.selectableAudience[0].type === "projectConnection")) ? (
            <div className="pt-1">
              <Capture2>{t("page.admin-create-post.form.audience.audience.label")}</Capture2>
              <FormSelect<FormValues, CommunityFeedSelectableAudienceDto>
                className="mt-2"
                name="audience"
                items={group.value.selectableAudience}
                onChange={(x) => {
                  form.setValue("audience", x);

                  if (x?.type === "projectConnection") {
                    form.setValue("hideFromAdmin", false);
                  }
                }}
                keySelector={(x) => x.type + (x.project?.id || x.projectConnection?.id)}
                renderOption={(x) => (
                  <div className="flex w-full items-center justify-between">
                    <span>{x.project?.name || x.projectConnection?.name || ""}</span>
                    <span className="text-sm italic text-grey-dark">
                      {t("page.message-feed.resident-create-post.form.audience.group.members", {
                        count: x.project?.audienceCount || x.projectConnection?.audienceCount || 0,
                      })}
                    </span>
                  </div>
                )}
                groupSelector={(x) => {
                  if (x.type === "project") {
                    return t("page.message-feed.resident-create-post.form.audience.option-groups.message-all");
                  }

                  return t("page.message-feed.resident-create-post.form.audience.option-groups.connections");
                }}
                disabled={group.value.selectableAudience.length === 1}
              />
            </div>
          ) : null}
        </FormField>
        <FormField htmlFor="title" label={t("page.message-feed.resident-create-post.form.title.title")} required>
          <FormInput<FormValues>
            id="title"
            name="title"
            placeholder={
              messageType === "askHelp"
                ? t("page.message-feed.resident-create-post.form.title.placeholder.help")
                : messageType === "inform"
                  ? t("page.message-feed.resident-create-post.form.title.placeholder.inform")
                  : t("page.message-feed.resident-create-post.form.title.placeholder.marketplace")
            }
            rules={{
              validate: {
                required: createRequiredStringRule(t, "page.message-feed.resident-create-post.form.title.title"),
              },
              maxLength: {
                message: t("components.form.error.max-length", { length: MAX_LENGTH.SUBJECT }),
                value: MAX_LENGTH.SUBJECT,
              },
            }}
          />
        </FormField>
        <FormField htmlFor="message" label={t("page.message-feed.resident-create-post.form.content.title")} required>
          <FormTextArea<FormValues>
            id="message"
            name="message"
            placeholder={
              messageType === "askHelp"
                ? t("page.message-feed.resident-create-post.form.content.placeholder.help")
                : messageType === "inform"
                  ? t("page.message-feed.resident-create-post.form.content.placeholder.inform")
                  : t("page.message-feed.resident-create-post.form.content.placeholder.marketplace")
            }
            rules={{
              validate: {
                required: createRequiredStringRule(t, "page.message-feed.resident-create-post.form.content.title"),
              },
              maxLength: {
                message: t("components.form.error.max-length", { length: MAX_LENGTH.DESCRIPTION }),
                value: MAX_LENGTH.DESCRIPTION,
              },
            }}
          />
        </FormField>
        {!promotedGroup &&
        (!audience || audience?.type !== "projectConnection") &&
        (!group || group.type !== "projectConnection") ? (
          <FormCheckbox<FormValues>
            name="hideFromAdmin"
            label={t("page.message-feed.resident-create-post.form.hide-from-admins.title")}
          />
        ) : null}
        {!promotedGroup ? (
          <FormField label={t("page.message-feed.resident-create-post.form.image-or-document.title")} htmlFor="image">
            <div className="flex gap-2">
              {documents.length === 0 && (
                <FormImageInput<FormValues, "images">
                  name="images"
                  id="image"
                  nOfImages={isMultipleImagesAllowedForCommunityPost ? MAX_AMOUNT_IMAGES_PER_POST : 1}
                  texts={{
                    add: t("page.message-feed.resident-create-post.form.image-or-document.add-image"),
                  }}
                  rules={{
                    validate: {
                      size(images) {
                        if (images) {
                          return validateSize(t, images);
                        }
                      },
                    },
                  }}
                />
              )}
              {images.length === 0 && (
                <FormDocumentInput<FormValues, "documents">
                  name="documents"
                  id="documents"
                  accept="application/pdf"
                  withPreview
                  texts={{
                    add: t("page.message-feed.resident-create-post.form.image-or-document.add-document"),
                  }}
                  rules={{
                    validate: {
                      size(documents) {
                        if (documents) {
                          return validateSize(t, documents);
                        }
                      },
                    },
                  }}
                />
              )}
            </div>
          </FormField>
        ) : (
          <PromotedGroupCard
            id={promotedGroup.id}
            name={promotedGroup.name}
            description={promotedGroup.description}
            image={promotedGroupImage}
            isReadonly
            isResidentGroup={promotedGroup.isResidentGroup}
          />
        )}
        <Modal.Actions>
          <Modal.Close>
            <Button styling="secondary" disabled={isPosting}>
              {t("common.action.cancel")}
            </Button>
          </Modal.Close>
          <Button styling="primary" type="submit" isLoading={isPosting}>
            {t("common.action.post")}
          </Button>
        </Modal.Actions>
      </FormContent>
    </Form>
  );
}

function GroupOption({ group, isGroup, isPreview }: { group: GroupItem; isGroup: boolean; isPreview?: boolean }) {
  const resolveImage = useImageResolver();
  const { i18n, t } = useTranslation();

  return (
    <div className="flex w-full items-center justify-between">
      <div className="flex items-center gap-2">
        {group.type !== "group" ? null : group.value.isResidentGroup && group.value.image ? (
          <img src={resolveImage(group.value.image, "sm")} alt={group.value.name} className="size-6 rounded-full" />
        ) : (
          <span className="size-6 p-1">
            <GroupIcon icon={group.value.icon} size={16} />
          </span>
        )}
        <div className="flex flex-col">
          <span>{group.value.name}</span>
          {!isPreview && isGroup && (
            <span className="text-xs text-grey">
              {"lastActivityAt" in group.value && group.value.lastActivityAt
                ? t("page.admin-create-post.form.audience.group.last-activity", {
                    time: formatDistance(i18n, { start: new Date(group.value.lastActivityAt) }),
                  })
                : t("page.admin-create-post.form.audience.group.no-activity")}
            </span>
          )}
        </div>
      </div>
      {group.type === "group" && group.value.selectableAudience.length === 1 ? (
        <span className="text-sm italic text-grey-dark">
          {t("page.message-feed.resident-create-post.form.audience.group.members", {
            count:
              group.value.selectableAudience[0].project?.audienceCount ||
              group.value.selectableAudience[0].projectConnection?.audienceCount ||
              0,
          })}
        </span>
      ) : null}
    </div>
  );
}

type ResidentCreatePostModalProps = ModalBaseProps & {
  postType: "askHelp" | "inform" | "sellItem" | "promote" | null;
  group?: CommunityGroupDetailsV2Dto;
};

export function ResidentCreatePostModal({
  isOpened,
  onOpenChange,
  postType,
  group,
}: ResidentCreatePostModalProps): React.ReactNode {
  const { t } = useTranslation();

  if (!postType) {
    return null;
  }

  let title = "";
  let description = "";
  if (postType === "promote" ? group : undefined) {
    title = t("page.message-feed.resident-create-post.title.promote-group");
    description = t("page.message-feed.resident-create-post.subtitle.promote-group");
  } else {
    switch (postType) {
      case "askHelp":
        title = t("page.message-feed.resident-create-post.title.help");
        description = t("page.message-feed.resident-create-post.subtitle.help");
        break;
      case "inform":
        title = t("page.message-feed.resident-create-post.title.inform");
        description = t("page.message-feed.resident-create-post.subtitle.inform");
        break;
      case "sellItem":
        title = t("page.message-feed.resident-create-post.title.marketplace");
        description = t("page.message-feed.resident-create-post.subtitle.marketplace");
        break;
    }
  }

  return (
    <Modal.Root isScrollable {...{ title, description, isOpened, onOpenChange }}>
      <ResidentCreatePost
        onClose={() => onOpenChange(false)}
        messageType={postType === "promote" ? "inform" : postType}
        defaultGroupId={postType === "promote" ? undefined : group?.id}
        promotedGroup={postType === "promote" ? group : undefined}
      />
    </Modal.Root>
  );
}
