import { useQuery } from "@tanstack/react-query";
import { useApi } from "api/hooks/useApi";
import type { AdminTicketCommentDto, TicketStatusDto, UserDto } from "api/types";
import iconAnnotationInfo from "assets/icons/annotation-info.svg";
import iconLock01 from "assets/icons/lock-01.svg";
import type { BaseCommentFieldProps } from "components/CommentField/CommentField";
import { CommentFieldWithAvatar } from "components/CommentField/CommentField";
import type { FormDocument } from "components/DocumentInput/useDocumentFile";
import { useDocumentInput } from "components/DocumentInput/useDocumentFile";
import { Icon } from "components/Icon/Icon";
import type { FormImage } from "components/ImageInput/useImageInput";
import { useImageInput } from "components/ImageInput/useImageInput";
import { Notice } from "components/Notice/Notice";
import { Capture2 } from "components/Text/Text";
import { AnimatePresence } from "framer-motion";
import { motion } from "framer-motion";
import { commonAPIDataSelector } from "helpers/Network/selectors";
import { useProjectId } from "hooks/Network/useProjectId";
import { useBool } from "hooks/useBool";
import { usePostHog } from "posthog-js/react";
import { useConfig } from "providers/ConfigProvider";
import { QUERY_KEYS } from "query-keys";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { twJoin } from "tailwind-merge";

import { TicketAICopilotModal } from "./TicketAICopilotModal";
import { TicketCommentFieldAIActions } from "./TicketCommentFieldAIActions";
import { TicketResponsibilitySuggestionModal } from "./TicketResponsibilitySuggestionModal";

const MIN_TICKET_RESPONSIBILITY_SUGGESTION_CONFIDENCE = 40;

interface CommentPayload {
  content: string;
  image?: FormImage;
  document?: FormDocument;
}

type TicketCommentFieldProps = {
  canAddPublicComment: boolean;
  canAddInternalNote: boolean;
  allowedAttachments?: BaseCommentFieldProps["allowedAttachments"];
  autoFocus?: boolean;
  user: UserDto;
  onComment: (comment: CommentPayload & { internal?: boolean }) => Promise<unknown>;
  onEditComment?: ({ commentId, content, image, document }: CommentPayload & { commentId: string }) => Promise<void>;
  showCopilot?: boolean;
  ticketId: string;
  editingComment?: AdminTicketCommentDto;
  onCancelEditComment?: () => void;
  commentCount: number;
  status: TicketStatusDto["type"];
} & (
  | {
      showResponsibilitySuggestion?: never;
      hasResponsibilityAssignee?: never;
    }
  | {
      showResponsibilitySuggestion: boolean;
      hasResponsibilityAssignee: boolean;
    }
);

enum ResponsibilitySuggestionState {
  UNKNOWN,
  AGREED,
  DISAGREED,
}

export function TicketCommentField({
  canAddInternalNote,
  canAddPublicComment,
  allowedAttachments,
  autoFocus,
  user,
  onComment,
  onEditComment,
  onCancelEditComment,
  showCopilot,
  showResponsibilitySuggestion,
  ticketId,
  editingComment,
  hasResponsibilityAssignee,
  commentCount,
  status,
}: TicketCommentFieldProps): React.ReactNode {
  // Copilot
  const [originalText, setOriginalText] = useState("");
  const [copilotText, setCopilotText] = useState("");
  const [isCopilotText, setIsCopilotText] = useState(false);
  // Form
  const [value, setValue] = useState("");
  const [commentState, setCommentState] = useState<"note" | "comment">(canAddPublicComment ? "comment" : "note");
  const [images, setImages] = useState<FormImage[]>([]);
  const [documents, setDocuments] = useState<FormDocument[]>([]);
  // Modals
  const [isCopilotModalOpened, copilotModalOpenHandlers] = useBool(false);
  const [isResponsibilitySuggestionModalOpened, responsibilitySuggestionModalHandlers] = useBool(false);
  // Misc
  const [isEdit, setIsEdit] = useState(false);
  const [responsibilitySuggestionState, setResponsibilitySuggestionState] = useState<ResponsibilitySuggestionState>(
    ResponsibilitySuggestionState.UNKNOWN,
  );
  const isAiTicketResponsibilitySuggestionAvailable = useConfig("isAiTicketResponsibilitySuggestionAvailable");

  const { t } = useTranslation();
  const api = useApi();
  const projectId = useProjectId();
  const postHog = usePostHog();

  const { data: responsibilitySuggestion } = useQuery({
    queryKey: QUERY_KEYS.TICKETS_RESPONSIBILITY_SUGGESTION(projectId, ticketId),
    queryFn: () => api.getAdminTicketsResponsibilitySuggestionV1(ticketId),
    select: commonAPIDataSelector,
    enabled:
      !!showResponsibilitySuggestion &&
      !!ticketId &&
      !!hasResponsibilityAssignee &&
      isAiTicketResponsibilitySuggestionAvailable,
  });

  const { addImages, removeImage, removeImages } = useImageInput({ onChange: setImages });
  const { addDocuments, removeDocument, removeDocuments } = useDocumentInput({
    maximumFiles: 1,
    onChange: setDocuments,
  });

  function resetBodyToOriginal() {
    setIsCopilotText(false);
    setValue(originalText);
  }

  function resetBodyToCopilot() {
    setIsCopilotText(true);
    setValue(copilotText);
  }

  function resetInput() {
    removeImages();
    removeDocuments();
    setValue("");
    setOriginalText("");
    setCopilotText("");
    setIsCopilotText(false);
    setIsEdit(false);
    onCancelEditComment?.();
  }

  function onDisagreeWithSuggestion() {
    responsibilitySuggestionModalHandlers.setFalse();
    setResponsibilitySuggestionState(ResponsibilitySuggestionState.DISAGREED);
  }

  async function onSend() {
    if (isEdit && editingComment && onEditComment) {
      await onEditComment({
        commentId: editingComment.id,
        content: value,
        image: images[0],
        document: documents[0],
      });
    } else {
      await onComment({
        content: value,
        image: images[0],
        document: documents[0],
        internal: commentState === "note",
      });
    }
    resetInput();
  }

  function onExpandAIResponsibilitySuggestion() {
    responsibilitySuggestionModalHandlers.setTrue();

    postHog.capture("clicked_expand_ai_responsibility_suggestion", {
      ticketId,
    });
  }

  useEffect(() => {
    if (editingComment) {
      setCommentState(editingComment.internal ? "note" : "comment");
      setValue(editingComment.content ?? "");
      setImages(editingComment.images ?? []);
      setDocuments(editingComment.documents ?? []);
      setIsEdit(true);
    }
  }, [editingComment]);

  const ref = useRef<HTMLTextAreaElement>(null);

  // Use has just disgreed with the suggestion
  const hasUserDisagreedWithSuggestion =
    responsibilitySuggestionState === ResponsibilitySuggestionState.DISAGREED &&
    !!responsibilitySuggestion &&
    responsibilitySuggestion.rating === "bad";
  // The suggestion is relevant if it's for the tenant, not rated and has a high enough confidence
  const isResponsibilitySuggestionRelevant =
    !!responsibilitySuggestion &&
    responsibilitySuggestion.assignee === "tenant" &&
    responsibilitySuggestion.rating === "notRated" &&
    responsibilitySuggestion.confidence >= MIN_TICKET_RESPONSIBILITY_SUGGESTION_CONFIDENCE;
  // The suggestion is useful if the ticket is of status `new` and has no comments
  const isResponsibilitySuggestionUseful = commentCount === 0 && status === "new";
  const isResponsibilitySuggestionShown =
    ticketId &&
    showResponsibilitySuggestion &&
    isResponsibilitySuggestionUseful &&
    (isResponsibilitySuggestionRelevant || hasUserDisagreedWithSuggestion) &&
    isAiTicketResponsibilitySuggestionAvailable;

  if (!canAddInternalNote && !canAddPublicComment) {
    return null;
  }

  return (
    <>
      <div className="flex flex-col gap-4">
        <AnimatePresence>
          {isResponsibilitySuggestionShown && (
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.2, delay: 0.6 }}
            >
              <Notice
                type={hasUserDisagreedWithSuggestion ? "positive" : "info"}
                message={
                  hasUserDisagreedWithSuggestion
                    ? t("page.tickets.details.responsibility-suggestion-feedback")
                    : t("page.tickets.details.responsibility-suggestion-tenant")
                }
                icon={iconAnnotationInfo}
                onExpand={hasUserDisagreedWithSuggestion ? undefined : onExpandAIResponsibilitySuggestion}
              />
            </motion.div>
          )}
        </AnimatePresence>
        <div className="flex flex-col">
          <div className="flex">
            {canAddPublicComment ? (
              <button
                data-testid="public-comment-btn"
                className={twJoin(
                  "rounded-tl-lg px-4 py-1.5",
                  commentState === "comment"
                    ? "bg-aop-basic-blue text-white"
                    : "bg-blue-lightest text-grey-dark hocus:bg-blue-lighter",
                  canAddInternalNote ? undefined : "rounded-tr-lg",
                )}
                type="button"
                onClick={() => setCommentState("comment")}
                disabled={isEdit}
              >
                <Capture2>{t("page.tickets.details.comment-field.comment")}</Capture2>
              </button>
            ) : null}
            {canAddInternalNote ? (
              <button
                data-testid="internal-note-btn"
                className={twJoin(
                  "flex items-center gap-1 rounded-tr-lg px-4 py-1.5",
                  commentState === "note"
                    ? "bg-yellow-dark text-yellow-lightest"
                    : "bg-yellow-lightest text-yellow-darkest hocus:bg-yellow-lighter",
                  canAddPublicComment ? undefined : "rounded-tl-lg",
                )}
                type="button"
                onClick={() => setCommentState("note")}
                disabled={isEdit}
              >
                <Icon name={iconLock01} />
                <Capture2>{t("page.tickets.details.comment-field.note")}</Capture2>
              </button>
            ) : null}
          </div>
          <div
            className={twJoin(
              "rounded-lg rounded-tl-none border p-4",
              commentState === "comment" ? "border-aop-basic-blue" : "border-yellow-dark",
            )}
          >
            <div className="flex flex-col gap-2">
              <CommentFieldWithAvatar
                ref={ref}
                onSubmit={onSend}
                user={user}
                allowedAttachments={allowedAttachments}
                tooltips={{
                  send:
                    commentState === "note"
                      ? t("page.tickets.details.comment-field.send-note-tooltip")
                      : t("page.tickets.details.comment-field.send-message-tooltip"),
                }}
                onAddImages={addImages}
                onRemoveImage={removeImage}
                onAddDocument={addDocuments}
                onRemoveDocument={removeDocument}
                images={images}
                documents={documents}
                placeholder={
                  commentState === "note"
                    ? t("page.tickets.details.comment-field.placeholder-note")
                    : t("page.tickets.details.comment-field.placeholder-message")
                }
                isNote={commentState === "note"}
                onChange={(value) => {
                  setValue(value);
                  setOriginalText(value);
                }}
                isEdit={isEdit}
                onCancel={resetInput}
                value={value}
                autoFocus={autoFocus}
                actionComponent={
                  commentState === "comment" && (
                    <div className="flex w-full flex-wrap-reverse justify-between gap-y-2">
                      {showCopilot && (
                        <TicketCommentFieldAIActions
                          onGenerate={copilotModalOpenHandlers.setTrue}
                          onUndo={resetBodyToOriginal}
                          onRedo={resetBodyToCopilot}
                          {...{ isCopilotText, copilotText }}
                        />
                      )}
                    </div>
                  )
                }
              />
            </div>
          </div>
        </div>
      </div>
      {ticketId && (
        <TicketAICopilotModal
          isOpened={isCopilotModalOpened}
          onOpenChange={copilotModalOpenHandlers.set}
          onGenerate={(value) => {
            setValue(value);
            setCopilotText(value);
            setIsCopilotText(true);
            ref.current?.focus();
          }}
          ticketId={ticketId}
          defaultInput={{ input: value || "" }}
        />
      )}
      {isResponsibilitySuggestionShown && (
        <TicketResponsibilitySuggestionModal
          isOpened={isResponsibilitySuggestionModalOpened}
          onOpenChange={responsibilitySuggestionModalHandlers.set}
          description={responsibilitySuggestion.description}
          ticketId={ticketId}
          onDisagree={onDisagreeWithSuggestion}
        />
      )}
    </>
  );
}
