import { Checkbox } from "antd";
import { Button } from "components/Button/Button";
import type { ModalBaseProps } from "components/Modal/Modal";
import { Modal } from "components/Modal/Modal";
import type React from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";

type UserDeleteModalProps = ModalBaseProps & {
  deletionDate: string | undefined;
  isDeletingUser: boolean;
  onClickSetDeletionDate: () => void;
  onDeleteUser: () => void;
};

export function UserDeleteModal({
  isOpened,
  onOpenChange,
  deletionDate,
  isDeletingUser,
  onClickSetDeletionDate,
  onDeleteUser,
}: UserDeleteModalProps): React.ReactNode {
  const [isConfirmed, setIsConfirmed] = useState(false);
  const { t } = useTranslation();

  const onClickSetDate = () => {
    onOpenChange(false);
    onClickSetDeletionDate();
  };

  return (
    <Modal.Root
      title={t("page.user-detail.delete-or-date-modal.title")}
      description={
        deletionDate
          ? t("page.user-detail.delete-or-date-modal.description-already-set")
          : t("page.user-detail.delete-or-date-modal.description")
      }
      {...{ isOpened, onOpenChange }}
    >
      <label className="inline-flex gap-2" htmlFor="checkbox-confirm">
        <Checkbox
          id="checkbox-confirm"
          name="isConfirmed"
          checked={isConfirmed}
          onChange={(e) => setIsConfirmed(e.target.checked)}
        />
        <span>{t("page.user-detail.delete-or-date-modal.confirm-checkbox")}</span>
      </label>
      <Modal.Actions>
        <Button onClick={onClickSetDate} styling="secondary">
          {deletionDate
            ? t("page.user-detail.delete-or-date-modal.action.update-deletion-date")
            : t("page.user-detail.delete-or-date-modal.action.set-deletion-date")}
        </Button>
        <Button
          className="w-full sm:w-auto"
          onClick={onDeleteUser}
          styling="danger"
          disabled={!isConfirmed}
          data-testid="delete-user-modal-confirm"
          isLoading={isDeletingUser}
        >
          {t("page.user-detail.delete-or-date-modal.action.delete")}
        </Button>
      </Modal.Actions>
    </Modal.Root>
  );
}
