import type { ProjectOverviewItemDto, ProjectStatsDto } from "api/types";
import iconStar01 from "assets/icons/star-01.svg";
import type { LegendDataIconProps } from "components/Charts/LegendDataComponent";
import { LegendDataIcon } from "components/Charts/LegendDataComponent";
import { FormattedDate } from "components/FormattedDate/FormattedDate";
import { Icon } from "components/Icon/Icon";
import { Select } from "components/Select/Select";
import { Capture1, Capture2, Subtitle2 } from "components/Text/Text";
import { ToggleButtonGroup } from "components/ToggleButtonGroup/ToggleButtonGroup";
import { orderBy } from "lodash-es";
import { ScrollableBarChart } from "modules/analytics/components/ScrollableBarChart";
import { dataColors } from "modules/analytics/theme";
import { memo, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

interface Props {
  items: ProjectOverviewItemDto[];
  benchmark: ProjectStatsDto | undefined;
  month: Date;
  showEngagement: boolean;
}

export const GraphView = memo(function GraphView({ items, benchmark, month, showEngagement }: Props): React.ReactNode {
  const { t } = useTranslation();
  const [view, setView] = useState<"feelAtHome" | "rating" | "adoption" | "engagement">("feelAtHome");
  const [sort, setSort] = useState<"a-z" | "low-high" | "high-low">("a-z");

  const chartData = useMemo(() => {
    const statAccessor = (stats?: ProjectStatsDto) =>
      (view === "adoption"
        ? stats?.adoptionPercentage
        : view === "engagement"
          ? stats?.engagedResidentPercentage
          : view === "rating"
            ? stats?.averageTicketRating
            : stats?.feelingAtHomeAverageRatingAllTime) ?? -1;

    const orderedItems = orderBy(
      items,
      sort === "a-z"
        ? (a) => a.name
        : sort === "high-low"
          ? (x) => -statAccessor(x.stats)
          : (x) => statAccessor(x.stats),
    );

    return {
      items: orderedItems.map((x) => ({
        x: x.name,
        y: statAccessor(x.stats) ?? 0,
      })),
      benchmark: orderedItems.map((x) => ({ x: x.name, y: statAccessor(benchmark) ?? 0 })),
    };
  }, [benchmark, items, sort, view]);

  const options = [
    { label: t("page.portfolio.table.header.fah-rating"), value: "feelAtHome" },
    { label: t("page.portfolio.table.header.avg-rating"), value: "rating" },
    { label: t("page.portfolio.table.header.adoption"), value: "adoption" },
    ...(showEngagement ? ([{ label: t("page.portfolio.table.header.engagement"), value: "engagement" }] as const) : []),
  ] as const;

  return (
    <div className="hidden flex-col gap-4 md:flex">
      <div className="flex items-center gap-5">
        <Subtitle2 className="mr-auto" as="h2">
          {t("page.portfolio.analytics.title")}
        </Subtitle2>
      </div>
      <div className="flex flex-col gap-2 rounded bg-white px-5 py-4">
        <Capture1>
          {view === "adoption"
            ? t("page.portfolio.analytics.graph-title.adoption")
            : view === "engagement"
              ? t("page.portfolio.analytics.graph-title.engagement")
              : view === "rating"
                ? t("page.portfolio.analytics.graph-title.avg-rating")
                : t("page.portfolio.analytics.graph-title.fah-rating")}
        </Capture1>
        <div className="flex flex-col flex-wrap justify-between gap-y-2 md:flex-row md:items-center">
          <div className="flex gap-1">
            <Capture2>{t("page.portfolio.analytics.graph-subtitle")}</Capture2>
            <Capture2>
              <FormattedDate date={month} format="monthYear" />
            </Capture2>
          </div>
          <div className="flex flex-wrap gap-2">
            <ToggleButtonGroup
              options={options}
              selected={options.find((x) => x.value === view)!}
              onChange={(option) => setView(option.value)}
            />
          </div>
          <div className="flex items-center gap-2">
            <Capture2>{t("page.portfolio.analytics.sort")}</Capture2>
            <Select
              items={["a-z", "high-low", "low-high"] as const}
              keySelector={(x) => x}
              renderOption={(x) =>
                x === "a-z"
                  ? t("page.portfolio.analytics.sort.type.a-z")
                  : x === "high-low"
                    ? t("page.portfolio.analytics.sort.type.high-low")
                    : t("page.portfolio.analytics.sort.type.low-high")
              }
              selected={sort}
              onChange={setSort}
            />
          </div>
        </div>
        <div className="relative pl-8">
          <div className="absolute inset-y-0 -left-0 my-auto size-0 -rotate-90">
            <Capture2 className="flex items-center justify-center whitespace-nowrap" as="p">
              {view === "feelAtHome" ? <span>{t("page.portfolio.table.header.fah-rating")} %</span> : null}
              {view === "engagement" ? <span>{t("page.portfolio.table.header.engagement")} %</span> : null}
              {view === "adoption" ? <span>{t("page.portfolio.table.header.adoption")} %</span> : null}
              {view === "rating" ? (
                <span className="flex items-center gap-2">
                  <span>{t("page.portfolio.table.header.avg-rating")}</span>
                  <Icon name={iconStar01} className="fill-current stroke-transparent" />
                </span>
              ) : null}
            </Capture2>
          </div>
          <ScrollableBarChart
            type="text"
            primaryData={chartData.items}
            benchmark={view === "rating" || view === "feelAtHome" ? chartData.benchmark : []}
            benchmarkLabel={t("page.portfolio.analytics.benchmark")}
            formatYTick={(x) => x}
            maxY={view === "rating" || view === "feelAtHome" ? 10 : 100}
            arrowHoverText={t("page.portfolio.analytics.graph-arrow-hover")}
            renderLabel={(x) => (
              <table>
                <tbody>
                  <tr>
                    <td colSpan={4}>
                      <FormattedDate date={month} format="monthYear" />
                    </td>
                  </tr>
                  {view === "rating" || view === "feelAtHome" ? (
                    <>
                      <StarRow
                        title={x.x}
                        value={x.primary?.y || 0}
                        icon={{
                          fill: dataColors.darkPrimary,
                          type: "square",
                        }}
                      />
                      {x.benchmark?.y ? (
                        <StarRow
                          title={t("page.portfolio.analytics.benchmark")}
                          value={x.benchmark.y}
                          icon={{
                            fill: dataColors.benchmark,
                            type: "minus",
                          }}
                        />
                      ) : null}
                    </>
                  ) : (
                    <>
                      <PercentageRow
                        title={x.x}
                        value={x.primary?.y || 0}
                        icon={{
                          fill: dataColors.darkPrimary,
                          type: "square",
                        }}
                      />
                      {x.benchmark?.y ? (
                        <PercentageRow
                          title={t("page.portfolio.analytics.benchmark")}
                          value={x.benchmark.y}
                          icon={{
                            fill: dataColors.benchmark,
                            type: "minus",
                          }}
                        />
                      ) : null}
                    </>
                  )}
                </tbody>
              </table>
            )}
          />
        </div>
        <Capture2 className="mt-2 text-center" as="p">
          {t("page.portfolio.analytics.bottom-title")}
        </Capture2>
      </div>
      <div className="h-32" />
    </div>
  );
});

function StarRow({ title, icon, value }: { title: string; icon: LegendDataIconProps; value: number }) {
  return (
    <tr>
      <td className="pr-2">
        <LegendDataIcon {...icon} />
      </td>
      <td>{title}</td>
      <td className="pl-2">
        <Icon name={iconStar01} size={16} />
      </td>
      <td>
        <strong>{value}</strong>
      </td>
    </tr>
  );
}

function PercentageRow({ title, icon, value }: { title: string; icon: LegendDataIconProps; value: number }) {
  return (
    <tr>
      <td className="pr-2">
        <LegendDataIcon {...icon} />
      </td>
      <td colSpan={2}>{title}</td>
      <td>
        <strong>{value}%</strong>
      </td>
    </tr>
  );
}
