import type { AssetBookingDto, BookableAssetDto, BookableSlotDto } from "api/types";
import { FormCheckbox } from "components/Form/FormCheckbox";
import { FormField } from "components/Form/FormField";
import { Subtitle2 } from "components/Text/Text";
import { isDefined } from "helpers/util";
import type React from "react";
import { useMemo, useState } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { getMinutesOfDayFromTime, getTimeslotRange } from "../helpers";
import type { CreateOrEditBookingFormValues } from "../pages/CreateOrEditBookings/Layout";
import { BookingTimeslotButton } from "./BookingTimeslotButton";

interface BookingTimeslotSelectorProps {
  timeslot: BookableAssetDto["timeslot"];
  timeslots: BookableSlotDto[];
  booking: AssetBookingDto | undefined;
  isBookMultipleTimeslotsAllowed: boolean;
}

export function BookingTimeslotSelector({
  timeslot,
  timeslots,
  booking,
  isBookMultipleTimeslotsAllowed,
}: BookingTimeslotSelectorProps): React.ReactNode {
  const [potentialEndTime, setPotentialEndTime] = useState<number | null>(null);

  const { t } = useTranslation();
  const form = useFormContext<CreateOrEditBookingFormValues>();
  const startTime = useWatch({ control: form.control, name: "startTime" });
  const endTime = useWatch({ control: form.control, name: "endTime" });

  const onToggleBookAll = (checked: boolean) => {
    form.setValue("bookAllDay", checked, { shouldValidate: true, shouldDirty: true, shouldTouch: true });
    if (checked) {
      const { startTime } = getTimeslotRange(timeslots[0]);
      const { endTime } = getTimeslotRange(timeslots[timeslots.length - 1]);

      form.setValue("startTime", startTime, { shouldValidate: true, shouldDirty: true, shouldTouch: true });
      form.setValue("endTime", endTime, { shouldValidate: true, shouldDirty: true, shouldTouch: true });
    } else {
      form.setValue("startTime", null, { shouldValidate: true, shouldDirty: true, shouldTouch: true });
      form.setValue("endTime", null, { shouldValidate: true, shouldDirty: true, shouldTouch: true });
    }
  };

  const onHoverPotentialEndTime = (time: number | null) => {
    if (endTime) return;

    setPotentialEndTime(time);
  };

  // A timeslot is unbookable if:
  // 1. It's `unavailable`
  // 2, It's `booked` by another booking
  const firstUnavailableTimeslot = useMemo(() => {
    if (!isDefined(startTime)) return undefined;

    let unbookableTimeslots = timeslots.filter(
      (currTimeslot) =>
        (currTimeslot.state === "booked" || currTimeslot.state === "unavailable") &&
        getMinutesOfDayFromTime(currTimeslot.startTime, "start") > startTime,
    );

    if (booking) {
      // Remove the timeslot from the booking to be edited
      unbookableTimeslots = unbookableTimeslots.filter((currTimeslot) => {
        const { startTime: slotStartTime, endTime: slotEndTime } = getTimeslotRange(currTimeslot);

        if (currTimeslot.state === "booked") {
          return !(
            getMinutesOfDayFromTime(booking.startTime, "start") <= slotStartTime &&
            getMinutesOfDayFromTime(booking.endTime, "end") >= slotEndTime
          );
        } else {
          return true;
        }
      });
    }

    return unbookableTimeslots.sort((timeslotA, timeslotB) => {
      const { startTime: startTimeA } = getTimeslotRange(timeslotA);
      const { startTime: startTimeB } = getTimeslotRange(timeslotB);

      return startTimeA - startTimeB;
    })[0];
  }, [startTime, timeslots, booking]);
  const isEveryTimeslotAvailable =
    timeslots.length > 0 && timeslots.every((currTimeslot) => currTimeslot.state === "available");
  const isBookableDayNonBreaking = timeslots.some((currTimeslot) => currTimeslot.state === "unavailable");
  const isBookAllTimeslotsAllowed =
    isEveryTimeslotAvailable && timeslot !== "allDay" && isBookMultipleTimeslotsAllowed && isBookableDayNonBreaking;
  let label = t("page.bookings.book-asset.form.booking-timeslot.restricted");
  if (isBookMultipleTimeslotsAllowed) {
    if (!isDefined(startTime)) {
      label = t("page.bookings.book-asset.form.booking-timeslot.first");
    } else if (isDefined(startTime) && !isDefined(endTime)) {
      label = t("page.bookings.book-asset.form.booking-timeslot.second");
    }
  }
  if (isDefined(startTime) && isDefined(endTime)) {
    label = t("page.bookings.book-asset.form.booking-timeslot.third");
  }

  return (
    <div className="flex flex-col gap-2">
      <FormField {...{ label }}>
        <div className="flex flex-wrap gap-2">
          {timeslots.length > 0 &&
            timeslots.map((currTimeslot, idx) => {
              // The timeslot is booked by another booking (self or another user)
              let isBookedByOtherBooking = false;

              if (currTimeslot.state === "booked") {
                isBookedByOtherBooking = true;

                if (booking) {
                  const { startTime: timeslotStartTime, endTime: timeslotEndTime } = getTimeslotRange(currTimeslot);
                  const userBookingStartTime = getMinutesOfDayFromTime(booking.startTime, "start");
                  const userBookingEndTime = getMinutesOfDayFromTime(booking.endTime, "end");

                  if (userBookingStartTime <= timeslotStartTime && userBookingEndTime >= timeslotEndTime) {
                    isBookedByOtherBooking = false;
                  }
                }
              }

              return (
                <BookingTimeslotButton
                  key={idx}
                  timeslot={currTimeslot}
                  onHover={onHoverPotentialEndTime}
                  {...{
                    potentialEndTime,
                    firstUnavailableTimeslot,
                    isBookedByOtherBooking,
                    isBookMultipleTimeslotsAllowed,
                  }}
                />
              );
            })}
          {timeslots.length === 0 && (
            <Subtitle2 className="font-normal">
              {t("page.bookings.book-asset.form.booking-timeslot.no-slots.available")}
            </Subtitle2>
          )}
        </div>
      </FormField>
      {isBookAllTimeslotsAllowed && (
        <FormCheckbox
          name="bookAllDay"
          label={t("page.bookings.book-asset.form.booking-timeslot.all-day")}
          onChange={(event) => onToggleBookAll(event.target.checked)}
        />
      )}
    </div>
  );
}
