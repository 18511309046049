import type { CommunityFeedAudienceGroupDto, MessageCreatedEntityDto } from "api/types";
import MailManImage from "assets/images/mail-man.svg?react";
import { Button } from "components/Button/Button";
import type { ModalBaseProps } from "components/Modal/Modal";
import { Modal } from "components/Modal/Modal";
import { Subtitle2 } from "components/Text/Text";
import { useSlug } from "hooks/useSlug";
import type React from "react";
import { Trans, useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { routes } from "routes";

type AdminCreatePostSuccessModalProps = ModalBaseProps & {
  audienceReached: MessageCreatedEntityDto | null;
  wasPostedToGroup: boolean;
  groupPosted: CommunityFeedAudienceGroupDto | undefined;
};

export function AdminCreatePostSuccessModal({
  isOpened,
  onOpenChange,
  audienceReached,
  wasPostedToGroup,
  groupPosted,
}: AdminCreatePostSuccessModalProps): React.ReactNode {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const slug = useSlug();

  return (
    <Modal.Root title={t("page.admin-create-post.success-modal.title")} {...{ isOpened, onOpenChange }}>
      <div className="flex flex-col items-center gap-4">
        <MailManImage />
        {audienceReached?.addressCount !== undefined ||
        audienceReached?.unknownAddressCount !== undefined ||
        audienceReached?.audienceResidentsCount !== undefined ? (
          <div className="flex flex-col items-center gap-1">
            <Subtitle2 className="font-normal">
              {t("page.admin-create-post.success-modal.audience-reached.description.delivered-to")}
            </Subtitle2>
            <div>
              {audienceReached.addressCount !== undefined && audienceReached.addressCount > 0 && (
                <Subtitle2 className="font-normal">
                  <Trans
                    i18nKey="page.admin-create.post.success-modal.audience-reached.description.addresses"
                    values={{
                      addressCount: audienceReached.addressCount,
                    }}
                    count={audienceReached.addressCount}
                    components={{
                      b: <b />,
                    }}
                  />
                </Subtitle2>
              )}
              {audienceReached.unknownAddressCount !== undefined && audienceReached.unknownAddressCount > 0 && (
                <Subtitle2 className="font-normal">
                  <Trans
                    i18nKey="page.admin-create.post.success-modal.audience-reached.description.unknown-addresses"
                    values={{
                      unknownAddressCount: audienceReached.unknownAddressCount,
                    }}
                    count={audienceReached.unknownAddressCount}
                    components={{
                      b: <b />,
                    }}
                  />
                </Subtitle2>
              )}
            </div>
            {audienceReached.audienceResidentsCount !== undefined ? (
              audienceReached.audienceResidentsCount === 0 ? (
                <Subtitle2 className="font-normal">
                  <Trans
                    i18nKey="page.admin-create-post.success-modal.audience-reached.description.residents.none"
                    values={{
                      residentCount: audienceReached.audienceResidentsCount,
                    }}
                    components={{
                      b: <b />,
                    }}
                  />
                </Subtitle2>
              ) : (
                <Subtitle2 className="font-normal">
                  <Trans
                    i18nKey="page.admin-create-post.success-modal.audience-reached.description.residents"
                    values={{
                      residentCount: audienceReached.audienceResidentsCount,
                    }}
                    count={audienceReached.audienceResidentsCount}
                    components={{
                      b: <b />,
                    }}
                  />
                </Subtitle2>
              )
            ) : null}
          </div>
        ) : (
          <Subtitle2 className="font-normal">{t("page.admin-create-post.success-modal.description")}</Subtitle2>
        )}
        <Modal.Actions>
          <Modal.Close>
            <Button styling="tertiary"> {t("page.admin-create-post.success-modal.button.another-message")}</Button>
          </Modal.Close>
          <Button
            styling="primary"
            onClick={() =>
              wasPostedToGroup
                ? navigate(
                    groupPosted?.type === "helpCategory"
                      ? routes.helpCategories.details({ slug, id: groupPosted.id })
                      : routes.interestGroups.details({ slug, id: groupPosted!.id }),
                  )
                : navigate(routes.messageFeed.list({ slug }))
            }
          >
            {wasPostedToGroup
              ? t("page.admin-create-post.success-modal.button.group-done")
              : t("page.admin-create-post.success-modal.button.done")}
          </Button>
        </Modal.Actions>
      </div>
    </Modal.Root>
  );
}
