import { createColumnHelper, getCoreRowModel, useReactTable } from "@tanstack/react-table";
import type { AddressDto, UserDto } from "api/types";
import iconDownload01 from "assets/icons/download-01.svg";
import iconRefreshCcw01 from "assets/icons/refresh-ccw-01.svg";
import { Anchor } from "components/Anchor/Anchor";
import { Button } from "components/Button/Button";
import { IconButton } from "components/Button/IconButton";
import { ConfirmModal } from "components/ConfirmModal/ConfirmModal";
import type { ContextMenuAction } from "components/ContextMenu/ContextMenu";
import { ContextMenu } from "components/ContextMenu/ContextMenu";
import { DeleteModal, useDeleteModal } from "components/DeleteModal/DeleteModal";
import { Icon } from "components/Icon/Icon";
import { MultiSelect } from "components/MultiSelect/MultiSelect";
import { DocumentPaper } from "components/Paper/DocumentPaper";
import { SearchInput } from "components/SearchInput/SearchInput";
import { Table } from "components/Table/Table";
import { formatAddress } from "helpers/address";
import { useSessionUser } from "hooks/Network/useSessionUser";
import { useBool } from "hooks/useBool";
import { useDebounce } from "hooks/useDebounce";
import { usePermission } from "hooks/usePermission";
import { useSlug } from "hooks/useSlug";
import { AddressesExportModal } from "modules/addresses/components/AddressesExportModal";
import { useDeferredValue, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { routes } from "routes";

export interface LayoutProps {
  addresses: AddressDto[];
  addressTypes: string[];
  onRefreshLocationCode: (addressId: string) => Promise<unknown>;
  onDelete: (address: AddressDto) => Promise<unknown>;
}

const DEBOUNCE_WAIT = 200;

export function Layout({ addresses, addressTypes, onRefreshLocationCode, onDelete }: LayoutProps): React.ReactNode {
  const slug = useSlug();
  const sessionUser = useSessionUser();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { componentProps: deleteModalProps, openDeleteModal } = useDeleteModal<AddressDto>("address-delete-modal");
  const [locationCodeModalAddressId, setLocationCodeModalAddressId] = useState<string>();
  const [isRefreshingLocationCode, refreshingLocationCodeHandlers] = useBool();
  const [query, setQuery] = useState("");
  const [addressTypeFilter, setAddressTypeFilter] = useState<string[]>([]);
  const deferredQuery = useDeferredValue(useDebounce(query.toLowerCase().trim(), DEBOUNCE_WAIT));
  const [isExportModalOpened, exportModalOpenHandlers] = useBool();

  const mappedAddresses = useMemo(
    () =>
      addresses.map((x) => ({
        ...x,
        address: formatAddress(x),
        registeredUsers: x.users.filter((u) => u.registeredAt),
        notRegisteredUsers: x.users.filter((u) => !u.registeredAt),
        searchableString: `${formatAddress(x)}|||${x.postalCode}|||${x.building.name})|||${x.floor}|||${
          x.typeConstraint?.key ?? ""
        }|||${x.city}|||${x.locationCode}|||${x.users.map((x) => x.fullName).join("|||")}|||${x.users
          .map((x) => x.email)
          .join("|||")}`.toLowerCase(),
      })),
    [addresses],
  );

  const hasPermission = usePermission();
  const canManage = hasPermission((x) => x.addressManagement.canManageAddresses);
  const canEdit = hasPermission((x) => x.addressManagement.canEditAddress);

  const filteredAddresses = useMemo(
    () =>
      mappedAddresses
        .filter((x) => x.searchableString.includes(deferredQuery))
        .filter((x) => addressTypeFilter.length === 0 || addressTypeFilter.includes(x.typeConstraint?.key ?? "")),
    [mappedAddresses, deferredQuery, addressTypeFilter],
  );

  const anyUsers = useMemo(() => addresses.some((x) => x.users.length > 0), [addresses]);

  type ColumnType = (typeof mappedAddresses)[number];

  const columns = useMemo(() => {
    const helper = createColumnHelper<ColumnType>();

    return [
      helper.accessor("building.name", {
        header: t("page.addresses.list.building"),
      }),
      helper.accessor("city", {
        header: t("page.addresses.list.city"),
      }),
      helper.accessor("postalCode", {
        header: t("page.addresses.list.postal-code"),
      }),
      helper.accessor("address", {
        header: t("page.addresses.list.address"),
        cell: (cell) =>
          canEdit ? (
            <Anchor to={routes.addresses.edit({ slug, id: cell.row.original.id })}>{cell.getValue()}</Anchor>
          ) : (
            <div>{cell.getValue()}</div>
          ),
      }),
      helper.accessor("typeConstraint", {
        header: t("page.addresses.list.type"),
        cell: (cell) => <div>{cell.getValue()?.key ?? "-"}</div>,
      }),
      ...(sessionUser.project.type === "addressBased"
        ? [
            helper.accessor("locationCode", {
              header: t("page.addresses.list.location-code"),
              cell: (cell) => (
                <div className="flex items-center justify-between gap-2">
                  <span>{cell.getValue()}</span>
                  {canEdit ? (
                    <IconButton
                      styling="tertiary"
                      className="text-aop-basic-blue"
                      title={t("page.addresses.list.refresh-location-code")}
                      onClick={() => setLocationCodeModalAddressId(cell.row.original.id)}
                    >
                      <Icon name={iconRefreshCcw01} size={16} />
                    </IconButton>
                  ) : null}
                </div>
              ),
            }),
          ]
        : []),
      ...(anyUsers
        ? [
            helper.accessor("registeredUsers", {
              header: t("page.addresses.list.onboarded-users"),
              cell: (cell) => <UsersList users={cell.getValue()} />,
            }),
            helper.accessor("notRegisteredUsers", {
              header: t("page.addresses.list.invited-users"),
              cell: (cell) => <UsersList users={cell.getValue()} />,
            }),
          ]
        : []),
      helper.accessor("id", {
        header: "",
        cell: (cell) => {
          const id = cell.getValue();
          const actions: ContextMenuAction[] = [];

          if (canEdit) {
            actions.push({
              dataTestId: "context-menu-edit-btn",
              callback: () => {
                navigate(routes.addresses.edit({ slug, id: id }));
              },
              text: t("common.action.edit"),
            });
          }

          if (canManage) {
            actions.push({
              dataTestId: "context-menu-delete-btn",
              callback: () => openDeleteModal(cell.row.original),
              text: t("common.action.delete"),
              status: {
                disabled: cell.row.original.users.some((x) => x.deletedAt == null),
                disabledText: t("page.addresses.list.actions.delete.forbidden"),
              },
            });
          }

          return (
            <div className="flex justify-end px-2">
              <ContextMenu actions={actions} />
            </div>
          );
        },
      }),
    ];
  }, [anyUsers, canEdit, canManage, slug, navigate, openDeleteModal, sessionUser.project.type, t]);

  const tableInstance = useReactTable({
    columns,
    data: filteredAddresses,
    getCoreRowModel: getCoreRowModel(),
  });

  const registeredAddressCount = mappedAddresses.filter((x) => x.registeredUsers.some((x) => !x.isAdmin)).length;
  const addressCount = mappedAddresses.length === 0 ? 0 : mappedAddresses.length - 1;

  return (
    <DocumentPaper
      theme="minimal"
      title={t("page.addresses.title")}
      subTitle={t("page.addresses.subtitle")}
      actions={
        <>
          {!deferredQuery && (
            <Button styling="secondary" onClick={exportModalOpenHandlers.setTrue}>
              <span className="flex items-center gap-2">
                <Icon name={iconDownload01} size={16} />
                {t("page.addresses.download-export")}
              </span>
            </Button>
          )}
          {canManage && (
            <Button type="link" href={routes.addresses.new({ slug })} data-testid="address-create-link">
              {t("page.addresses.actions.create")}
            </Button>
          )}
        </>
      }
      header={
        <div className="flex flex-col flex-wrap items-center justify-between gap-4 md:flex-row">
          <div className="flex flex-col items-center gap-4 md:flex-row">
            <SearchInput
              className="h-10"
              value={query}
              onChange={(e) => setQuery(e.target.value)}
              placeholder={t("page.addresses.list.search.placeholder")}
            />
            {addressTypes.length > 1 ? (
              <div className="min-w-52">
                <MultiSelect
                  placeholder={t("page.addresses.list.filter.placeholder")}
                  selected={addressTypeFilter}
                  items={addressTypes}
                  renderOption={(x) => x}
                  keySelector={(x) => x}
                  onChange={setAddressTypeFilter}
                />
              </div>
            ) : null}
          </div>
          <p className="pl-2 text-right text-grey-darker">
            <span>{t("page.addresses.list.address-count", { count: filteredAddresses.length })}</span>
            {sessionUser.project.type === "companyBased" ? null : (
              <>
                <span> </span>
                <span>
                  {t("page.addresses.list.registered", {
                    registered: registeredAddressCount,
                    addresses: addressCount,
                    percentage: addressCount === 0 ? 0 : Math.round((registeredAddressCount / addressCount) * 100),
                  })}
                </span>
              </>
            )}
          </p>
        </div>
      }
    >
      <div className="overflow-auto">
        {addresses.length === 0 || filteredAddresses.length === 0 ? (
          <div className="rounded-lg bg-white p-5">
            <p>{addresses.length === 0 ? t("page.addresses.list.empty") : t("page.addresses.list.no-result")}</p>
          </div>
        ) : (
          <div className="flex flex-col gap-8 pb-8">
            <Table
              table={tableInstance}
              data-testid="address-list"
              getRowProps={() => ({
                "data-testid": "address-item",
              })}
            />
          </div>
        )}
      </div>
      <AddressesExportModal
        isOpened={isExportModalOpened}
        onOpenChange={exportModalOpenHandlers.set}
        {...{ addresses }}
      />
      <DeleteModal
        title={t("page.addresses.actions.delete.title")}
        description={t("page.addresses.actions.delete.description")}
        onDelete={onDelete}
        deleteBtnProps={{
          "data-testid": "modal-confirm-delete",
        }}
        {...deleteModalProps}
      />
      <ConfirmModal
        id="location-code-modal"
        data-testid="location-code-modal"
        title={t("page.addresses.list.location-code.refresh.title")}
        description={t("page.addresses.list.location-code.refresh.description")}
        isOpen={!!locationCodeModalAddressId}
        onOpenChange={(state) => {
          if (!state) {
            setLocationCodeModalAddressId(undefined);
          }
        }}
        shouldCloseOnEsc
        onReject={() => setLocationCodeModalAddressId(undefined)}
        rejectBtnProps={{
          text: t("page.addresses.list.location-code.refresh.actions.cancel"),
        }}
        onResolve={async () => {
          try {
            if (locationCodeModalAddressId) {
              refreshingLocationCodeHandlers.setTrue();
              await onRefreshLocationCode(locationCodeModalAddressId);
            }
          } finally {
            refreshingLocationCodeHandlers.setFalse();

            setLocationCodeModalAddressId(undefined);
          }
        }}
        resolveBtnProps={{
          text: t("page.addresses.list.location-code.refresh.actions.confirm"),
        }}
        isLoading={isRefreshingLocationCode}
      />
    </DocumentPaper>
  );
}

function UsersList({ users }: { users: UserDto[] }) {
  const slug = useSlug();

  return (
    <div className="flex flex-col py-2">
      {users.map((x) =>
        x.canViewProfile ? (
          <Anchor key={x.id} to={routes.users.details({ slug, id: x.id })}>
            <span className="my-0.5 text-sm">{x.fullName}</span>
          </Anchor>
        ) : (
          <span key={x.id}>{x.fullName}</span>
        ),
      )}
    </div>
  );
}
