import type { CreatableTicketCategoryDto } from "api/types";
import asteriskIcon from "assets/icons/asterisk-01.svg";
import chevronLeftIcon from "assets/icons/chevron-left.svg";
import fileIcon from "assets/icons/file-05.svg";
import { Button } from "components/Button/Button";
import { Form } from "components/Form/Form";
import { FormCheckbox } from "components/Form/FormCheckbox";
import { FormErrorWrapper } from "components/Form/FormErrorWrapper";
import { FormField } from "components/Form/FormField";
import { FormImageInput } from "components/Form/FormImageInput";
import { FormInput } from "components/Form/FormInput";
import { FormSelect } from "components/Form/FormSelect";
import { FormTextArea } from "components/Form/FormTextArea";
import { Icon } from "components/Icon/Icon";
import type { FormImage } from "components/ImageInput/useImageInput";
import type { ModalBaseProps } from "components/Modal/Modal";
import { Modal } from "components/Modal/Modal";
import { Capture2, Headline4, Subtitle2 } from "components/Text/Text";
import { AnimatePresence, motion } from "framer-motion";
import { validateSize } from "helpers/file-size";
import { createRequiredStringRule } from "helpers/rules";
import { useSessionUser } from "hooks/Network/useSessionUser";
import { useEffect } from "react";
import { useForm, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";

type TicketVisibility = "private" | "sharedWithBuilding" | "sharedWithCommunity";

const visibilityOptions: TicketVisibility[] = ["private", "sharedWithBuilding", "sharedWithCommunity"];

type ResidentCreateTicketModalProps = ModalBaseProps & {
  onSend: (payload: FormValues) => Promise<void>;
  isSending: boolean;
  categories: CreatableTicketCategoryDto[];
};

export interface FormValues {
  category: CreatableTicketCategoryDto | undefined;
  subject: string;
  description: string;
  visibility?: TicketVisibility;
  images?: FormImage[];
  isRegulationsAccepted?: boolean;
}

const MAX_LENGTH = {
  SUBJECT: 255,
  DESCRIPTION: 10000,
};

const variants = {
  enter: (direction: "left" | "right") => {
    return {
      x: direction === "right" ? 1000 : -1000,
      opacity: 0,
    };
  },
  center: {
    zIndex: 1,
    x: 0,
    opacity: 1,
  },
  exit: (direction: "left" | "right") => {
    return {
      zIndex: 0,
      x: direction === "left" ? 1000 : -1000,
      opacity: 0,
    };
  },
};

export function ResidentCreateTicketModal({
  isOpened,
  onOpenChange,
  onSend,
  isSending,
  categories,
}: ResidentCreateTicketModalProps): React.ReactNode {
  const { t } = useTranslation();
  const sessionUser = useSessionUser();
  const form = useForm<FormValues>({});

  useEffect(() => {
    if (!isOpened) {
      form.reset();
    }
  }, [form, isOpened]);

  const handleSubmit = async (values: FormValues) => {
    const payload: FormValues = {
      category: values.category,
      subject: values.subject,
      description: values.description,
      visibility: values.category!.notificationType === "canBeCollective" ? values.visibility : undefined,
      images: values.images,
    };

    await onSend(payload);
  };

  function onSelectCategory(category: CreatableTicketCategoryDto | undefined) {
    form.setValue("category", category);
    if (category?.notificationType === "canBeCollective") {
      form.setValue("visibility", "private");

      return;
    }
  }

  const selectedCategory = useWatch({ control: form.control, name: "category" });

  return (
    <Modal.Root
      title={t("page.resident-tickets.create-modal.title")}
      data-testid="create-ticket-modal"
      isScrollable
      {...{ onOpenChange, isOpened }}
    >
      <Form formMethods={form} onSubmit={handleSubmit} className="flex grow">
        <AnimatePresence mode="wait" initial={false} custom={selectedCategory ? "right" : "left"}>
          <motion.div
            key={selectedCategory?.id}
            custom={selectedCategory ? "right" : "left"}
            initial="enter"
            animate="center"
            exit="exit"
            transition={{
              x: { type: "spring", stiffness: 300, damping: 30 },
              opacity: { duration: 0.1 },
            }}
            variants={variants}
            className="flex grow flex-col justify-between gap-6"
          >
            {!selectedCategory ? (
              <>
                <div className="flex flex-col gap-2">
                  <Subtitle2>{t("page.resident-tickets.create-modal.form.category.title")}</Subtitle2>
                  {categories.map((category) => (
                    <button
                      data-testid="ticket-category-item"
                      key={category.id}
                      type="button"
                      onClick={() => onSelectCategory(category)}
                      className="flex w-full items-center gap-2 rounded-lg border border-grey-lighter p-4 text-left hover:bg-grey-lightest"
                    >
                      <Icon name={asteriskIcon} size={16} className="hidden" />
                      <Subtitle2>{category.name}</Subtitle2>
                    </button>
                  ))}
                </div>
                <Modal.Actions>
                  <Modal.Close>
                    <Button styling="secondary" disabled={isSending}>
                      {t("common.action.cancel")}
                    </Button>
                  </Modal.Close>
                </Modal.Actions>
              </>
            ) : (
              <>
                <div className="flex flex-col gap-2">
                  <Button
                    styling="ghostPrimary"
                    onClick={() => onSelectCategory(undefined)}
                    icon={<Icon name={chevronLeftIcon} />}
                  >
                    {t("page.resident-tickets.create-modal.change-category")}
                  </Button>
                  <div className="mb-4 flex items-center gap-2">
                    <Icon name={asteriskIcon} size={24} className="hidden" />
                    <Headline4 as="h2">{selectedCategory.name}</Headline4>
                  </div>
                  <Subtitle2 as="h3">{t("page.resident-tickets.create-modal.subtitle")}</Subtitle2>
                  {selectedCategory.customIntroText ? (
                    <Capture2 as="p" className="whitespace-pre-line pb-4">
                      {selectedCategory.customIntroText}
                    </Capture2>
                  ) : (
                    <Capture2 as="p" className="whitespace-pre-line pb-4">
                      {t("page.resident-tickets.create-modal.intro-text")}
                    </Capture2>
                  )}
                </div>
                {selectedCategory.notificationType === "canBeCollective" && (
                  <FormField label={t("page.resident-tickets.create-modal.form.visibility.title")} required>
                    <FormSelect<FormValues, TicketVisibility>
                      name="visibility"
                      items={visibilityOptions}
                      keySelector={(x) => x}
                      renderOption={(x) => {
                        switch (x) {
                          case "private":
                            return t("page.resident-tickets.create-modal.form.visibility.options.private");
                          case "sharedWithBuilding":
                            return t("page.resident-tickets.create-modal.form.visibility.options.building", {
                              buildingName: sessionUser.address?.building?.name ?? sessionUser.company?.building?.name,
                            });
                          case "sharedWithCommunity":
                            return t("page.resident-tickets.create-modal.form.visibility.options.community");
                        }
                      }}
                    />
                  </FormField>
                )}
                <FormField
                  htmlFor="subject"
                  label={t("page.resident-tickets.create-modal.form.subject.title")}
                  required
                >
                  <FormInput<FormValues>
                    id="subject"
                    name="subject"
                    placeholder={t("page.resident-tickets.create-modal.form.subject.placeholder")}
                    rules={{
                      validate: {
                        required: createRequiredStringRule(t, "page.resident-tickets.create-modal.form.subject.title"),
                      },
                      maxLength: {
                        message: t("components.form.error.max-length", { length: MAX_LENGTH.SUBJECT }),
                        value: MAX_LENGTH.SUBJECT,
                      },
                    }}
                  />
                </FormField>
                <FormField
                  htmlFor="description"
                  label={t("page.resident-tickets.create-modal.form.description.title")}
                  required
                >
                  <FormTextArea<FormValues>
                    id="description"
                    name="description"
                    placeholder={t("page.resident-tickets.create-modal.form.description.placeholder")}
                    rules={{
                      validate: {
                        required: createRequiredStringRule(
                          t,
                          "page.resident-tickets.create-modal.form.description.title",
                        ),
                      },
                      maxLength: {
                        message: t("components.form.error.max-length", { length: MAX_LENGTH.DESCRIPTION }),
                        value: MAX_LENGTH.DESCRIPTION,
                      },
                    }}
                  />
                </FormField>
                <FormField label={t("page.resident-tickets.create-modal.form.images.title")} htmlFor="images">
                  <FormImageInput<FormValues, "images">
                    name="images"
                    id="images"
                    nOfImages={5}
                    rules={{
                      validate: {
                        size(images) {
                          if (images) {
                            return validateSize(t, images);
                          }
                        },
                      },
                    }}
                  />
                </FormField>
                {selectedCategory.requireSignOffRegulations || selectedCategory.regulations ? (
                  <FormErrorWrapper name="isRegulationsAccepted" encircle>
                    <div className="flex flex-col gap-2">
                      {selectedCategory.requireSignOffRegulations ? (
                        <FormCheckbox<FormValues, "isRegulationsAccepted">
                          name="isRegulationsAccepted"
                          label={t("page.resident-tickets.create-modal.form.regulations")}
                          alignTop
                          rules={{
                            validate: {
                              required: (value) => {
                                return value
                                  ? undefined
                                  : t("page.resident-tickets.create-modal.form.regulations.error");
                              },
                            },
                          }}
                        />
                      ) : selectedCategory.regulations ? (
                        <Capture2>{t("page.resident-tickets.create-modal.form.regulations.not-required")}</Capture2>
                      ) : null}
                      {selectedCategory.regulations ? (
                        <Button
                          styling="tertiary"
                          className="mt-2"
                          onClick={() => window.open(selectedCategory.regulations?.url, "_blank")}
                        >
                          <div className="flex max-w-full items-center gap-1">
                            <Icon name={fileIcon} size={16} />
                            <span className="truncate">
                              {selectedCategory.regulations.fileName ?? selectedCategory.regulations.url}
                            </span>
                          </div>
                        </Button>
                      ) : null}
                    </div>
                  </FormErrorWrapper>
                ) : null}
                <div className="pt-4">
                  <Capture2 className="text-grey">
                    {t("page.tickets.details.comment-field.resident.notification-reminder")}
                  </Capture2>
                </div>
                <Modal.Actions>
                  <Modal.Close>
                    <Button styling="secondary" disabled={isSending}>
                      {t("common.action.cancel")}
                    </Button>
                  </Modal.Close>
                  <Button styling="primary" type="submit" isLoading={isSending}>
                    {t("common.action.send")}
                  </Button>
                </Modal.Actions>
              </>
            )}
          </motion.div>
        </AnimatePresence>
      </Form>
    </Modal.Root>
  );
}
