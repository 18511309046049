import iconCheck from "assets/icons/check.svg";
import { Icon } from "components/Icon/Icon";
import type { ModalBaseProps } from "components/Modal/Modal";
import { Modal } from "components/Modal/Modal";
import { Capture2 } from "components/Text/Text";
import { useTranslation } from "react-i18next";

type TipsModalProps = ModalBaseProps;

export function TipsModal({ isOpened, onOpenChange }: TipsModalProps): React.ReactNode {
  const { t } = useTranslation();

  return (
    <Modal.Root title={t("page.message-feed.create.tips.title")} {...{ isOpened, onOpenChange }}>
      <ul className="flex flex-col gap-3 text-grey-darkest">
        <li className="flex items-center gap-2">
          <Icon name={iconCheck} size={16} />
          <Capture2>{t("page.message-feed.create.tips.1")}</Capture2>
        </li>
        <li className="flex items-center gap-2">
          <Icon name={iconCheck} size={16} />
          <Capture2>{t("page.message-feed.create.tips.2")}</Capture2>
        </li>
        <li className="flex items-center gap-2">
          <Icon name={iconCheck} size={16} />
          <Capture2>{t("page.message-feed.create.tips.3")}</Capture2>
        </li>
        <li className="flex items-center gap-2">
          <Icon name={iconCheck} size={16} />
          <Capture2>{t("page.message-feed.create.tips.4")}</Capture2>
        </li>
        <li className="flex items-center gap-2">
          <Icon name={iconCheck} size={16} />
          <Capture2>{t("page.message-feed.create.tips.5")}</Capture2>
        </li>
        <li className="flex items-center gap-2">
          <Icon name={iconCheck} size={16} />
          <Capture2>{t("page.message-feed.create.tips.6")}</Capture2>
        </li>
      </ul>
    </Modal.Root>
  );
}
