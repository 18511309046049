import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useApi } from "api/hooks/useApi";
import type { ResponsibilitySuggestionRatingRequest } from "api/types";
import iconThumbsDown from "assets/icons/thumbs-down.svg";
import { Anchor } from "components/Anchor/Anchor";
import { Button } from "components/Button/Button";
import { Icon } from "components/Icon/Icon";
import { Label } from "components/Label/Label";
import { Modal, type ModalBaseProps } from "components/Modal/Modal";
import { useProjectId } from "hooks/Network/useProjectId";
import { QUERY_KEYS } from "query-keys";
import type React from "react";
import { Trans, useTranslation } from "react-i18next";

type TicketResponsibilitySuggestionModalProps = ModalBaseProps & {
  description: string;
  ticketId: string;
  onDisagree: () => void;
};

export function TicketResponsibilitySuggestionModal({
  isOpened,
  onOpenChange,
  ticketId,
  description,
  onDisagree,
}: TicketResponsibilitySuggestionModalProps): React.ReactNode {
  const api = useApi();
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const projectId = useProjectId();

  const mutationTicketResponsibilitySuggestion = useMutation({
    mutationFn: (data: ResponsibilitySuggestionRatingRequest) =>
      api.postAdminTicketsResponsibilitySuggestionV1(ticketId, data),
    onSuccess: () => {
      void queryClient.invalidateQueries({
        queryKey: QUERY_KEYS.TICKETS_RESPONSIBILITY_SUGGESTION(projectId, ticketId),
      });
    },
  });

  const onClickDisagree = async () => {
    await mutationTicketResponsibilitySuggestion.mutateAsync({ rating: "bad" });

    onDisagree();
  };

  return (
    <Modal.Root
      title={t("page.tickets.details.responsibility-suggestion-tenant-modal.title")}
      description={
        <Trans
          i18nKey="page.tickets.details.responsibility-suggestion-tenant-modal.description"
          values={{
            governmentData: t(
              "page.tickets.details.responsibility-suggestion-tenant-modal.description.government-data",
            ),
          }}
          components={{
            governmentData: (
              <Anchor
                to="https://www.rijksoverheid.nl/onderwerpen/woning-huren/vraag-en-antwoord/welke-kosten-zijn-voor-de-huurder-en-welke-voor-de-verhuurder"
                inNewTab
                isExternal
              />
            ),
          }}
        />
      }
      labelComponent={<Label theme="purple">{t("common.label.ai-driven")}</Label>}
      {...{ isOpened, onOpenChange }}
    >
      <div className="flex w-full flex-col items-start gap-2 rounded-lg border border-grey-lightest p-2">
        <h2 className="text-headline4 font-semibold">
          {t("page.tickets.details.responsibility-suggestion-tenant-modal.reason.subtitle")}
        </h2>
        <p>{description}</p>
      </div>
      <Modal.Actions>
        <Button
          styling="secondary"
          icon={<Icon name={iconThumbsDown} />}
          isLoading={mutationTicketResponsibilitySuggestion.isPending}
          onClick={onClickDisagree}
        >
          {t("page.tickets.details.responsibility-suggestion-tenant-disagree.btn")}
        </Button>
        <Modal.Close>
          <Button disabled={mutationTicketResponsibilitySuggestion.isPending}>{t("common.action.ok")}</Button>
        </Modal.Close>
      </Modal.Actions>
    </Modal.Root>
  );
}
