import type { MassMessageCreatedEntityDto } from "api/types";
import MailManImage from "assets/images/mail-man.svg?react";
import { Button } from "components/Button/Button";
import { formatDate } from "components/FormattedDate/FormattedDate";
import type { ModalBaseProps } from "components/Modal/Modal";
import { Modal } from "components/Modal/Modal";
import { Subtitle2 } from "components/Text/Text";
import type React from "react";
import { Trans, useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { routes } from "routes";

type CreateMassPostSuccessModalProps = ModalBaseProps & {
  audienceReached: MassMessageCreatedEntityDto | null;
};

export function CreateMassPostSuccessModal({
  isOpened,
  onOpenChange,
  audienceReached,
}: CreateMassPostSuccessModalProps): React.ReactNode {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  return (
    <Modal.Root
      title={
        audienceReached?.minutesExpectedToFinish
          ? t("page.create-mass-message.success-modal.batched.title", {
              count: Math.ceil(audienceReached.minutesExpectedToFinish / 60),
            })
          : t("page.create-mass-message.success-modal.title")
      }
      description={
        audienceReached?.expectedFinishDateTime &&
        t("page.create-mass-message.success-modal.batched.subtitle", {
          date: formatDate(i18n, "datetime", audienceReached.expectedFinishDateTime),
        })
      }
      {...{ isOpened, onOpenChange }}
    >
      <div className="flex flex-col items-center gap-4">
        <MailManImage />
        {audienceReached?.projectCount !== undefined ||
        audienceReached?.addressCount !== undefined ||
        audienceReached?.audienceResidentsCount !== undefined ? (
          <div className="flex flex-col items-center gap-1">
            <Subtitle2 className="font-normal">
              {t("page.create-mass-message.success-modal.audience-reached.description.delivered-to")}
            </Subtitle2>
            {audienceReached.projectCount !== undefined && audienceReached.projectCount > 0 && (
              <Subtitle2 className="font-normal">
                <Trans
                  i18nKey="page.create-mass-message.success-modal.audience-reached.description.projects"
                  values={{
                    projectCount: audienceReached.projectCount,
                  }}
                  count={audienceReached.projectCount}
                  components={{
                    b: <b />,
                  }}
                />
              </Subtitle2>
            )}
            {audienceReached.addressCount !== undefined && audienceReached.addressCount > 0 && (
              <Subtitle2 className="font-normal">
                <Trans
                  i18nKey="page.create-mass-message.success-modal.audience-reached.description.addresses"
                  values={{
                    addressCount: audienceReached.addressCount,
                  }}
                  count={audienceReached.addressCount}
                  components={{
                    b: <b />,
                  }}
                />
              </Subtitle2>
            )}
            {audienceReached.audienceResidentsCount !== undefined ? (
              audienceReached.audienceResidentsCount === 0 ? (
                <Subtitle2 className="font-normal">
                  <Trans
                    i18nKey="page.create-mass-message.success-modal.audience-reached.description.residents.none"
                    values={{
                      residentCount: audienceReached.audienceResidentsCount,
                    }}
                    components={{
                      b: <b />,
                    }}
                  />
                </Subtitle2>
              ) : (
                <Subtitle2 className="font-normal">
                  <Trans
                    i18nKey="page.create-mass-message.success-modal.audience-reached.description.residents"
                    values={{
                      residentCount: audienceReached.audienceResidentsCount,
                    }}
                    count={audienceReached.audienceResidentsCount}
                    components={{
                      b: <b />,
                    }}
                  />
                </Subtitle2>
              )
            ) : null}
          </div>
        ) : (
          <Subtitle2 className="font-normal">{t("page.create-mass-message.success-modal.description")}</Subtitle2>
        )}
        <Modal.Actions>
          <Modal.Close>
            <Button styling="tertiary">{t("page.create-mass-message.success-modal.button.another-message")}</Button>
          </Modal.Close>
          <Button styling="primary" onClick={() => navigate(routes.portfolio.overview())}>
            {t("page.create-mass-message.success-modal.button.done")}
          </Button>
        </Modal.Actions>
      </div>
    </Modal.Root>
  );
}
