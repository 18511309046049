import { useQuery } from "@tanstack/react-query";
import { useApi } from "api/hooks/useApi";
import type { AIAnalysisModalState } from "components/AIAnalysisModal/AIAnalysisModal";
import { AIAnalysisModal } from "components/AIAnalysisModal/AIAnalysisModal";
import { commonAPIDataSelector } from "helpers/Network/selectors";
import { useProjectId } from "hooks/Network/useProjectId";
import { QUERY_KEYS } from "query-keys";
import type React from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

type SurveyQuestionGroupAnswerSentimentModalProps = {
  questionGroupId: string;
  isOpen: boolean;
  onOpenChange: (state: boolean) => void;
};

export function SurveyQuestionGroupAnswerSentimentModal({
  questionGroupId,
  isOpen,
  onOpenChange,
}: SurveyQuestionGroupAnswerSentimentModalProps): React.ReactNode {
  const { t } = useTranslation();
  const projectId = useProjectId();
  const api = useApi();
  const { id: queueId } = useParams<{ id: string }>();
  const { data, isPending, isError } = useQuery({
    queryKey: QUERY_KEYS.AUTOMATED_SURVEY_QUESTION_RESPONSE_SUMMARY(projectId, queueId!, questionGroupId),
    queryFn: () => api.getAutomatedSurveysQueuesQuestionsDetailsSummaryV1(queueId!, questionGroupId),
    select: commonAPIDataSelector,
    enabled: isOpen,
    staleTime: 1000 * 60 * 5,
  });

  let state: AIAnalysisModalState = "loading";
  if (!isPending && !isError && data) {
    state = "success";
  } else if (!isPending && isError) {
    state = "error";
  } else if (!isPending && !isError && !data) {
    state = "unavailable";
  }

  return (
    <AIAnalysisModal
      data={{
        sentiment: data?.sentiment || 0,
        summary: data?.summary || "",
        suggestion: data?.suggestion,
        hashtags: data?.hashtags,
      }}
      config={{
        success: {
          title: t("page.automated-surveys.sentiment.modal.title"),
        },
        unavailable: {
          title: t("page.automated-surveys.sentiment.no-data.notification"),
        },
        error: {
          title: t("page.automated-surveys.sentiment.failed.notification"),
        },
      }}
      {...{ state, isOpen, onOpenChange }}
    />
  );
}
