import { useQuery } from "@tanstack/react-query";
import { useApi } from "api/hooks/useApi";
import { Button } from "components/Button/Button";
import { FullSizeLoader } from "components/FullSizeLoader/FullSizeLoader";
import type { ModalBaseProps } from "components/Modal/Modal";
import { Modal } from "components/Modal/Modal";
import { format } from "date-fns";
import { asUtc } from "helpers/date";
import { useProjectId } from "hooks/Network/useProjectId";
import { QUERY_KEYS } from "query-keys";
import { useTranslation } from "react-i18next";

type FeelingAtHomeExportModalProps = ModalBaseProps & {
  month?: Date;
};

export function FeelingAtHomeExportModal({
  month,
  isOpened,
  onOpenChange,
}: FeelingAtHomeExportModalProps): React.ReactNode {
  const projectId = useProjectId();
  const { t } = useTranslation();
  const api = useApi();

  const query = month ? { month: asUtc(month).toISOString() } : undefined;
  const { data, isFetching, error } = useQuery({
    queryKey: QUERY_KEYS.FEELING_AT_HOME_EXPORT(projectId, query),
    queryFn: () => api.getAnalyticsFeelingAtHomeExportV1(query),
    enabled: isOpened,
  });

  return (
    <Modal.Root
      title={t("page.feeling-at-home-details.export.modal.title")}
      description={t("page.feeling-at-home-details.export.modal.description")}
      {...{ isOpened, onOpenChange }}
    >
      {isFetching && (
        <div className="pointer-events-none inset-0 h-16" tabIndex={-1}>
          <FullSizeLoader />
        </div>
      )}
      {!isFetching && error && (
        <span className="block max-w-96 text-red">{t("page.feeling-at-home-details.export.modal.error")}</span>
      )}
      {!isFetching && !error && (
        <Modal.Actions>
          <Button
            onClick={async () => {
              if (!data) {
                return;
              }

              const res = await data.blob();
              const newBlob = new Blob([res]);

              const blobUrl = window.URL.createObjectURL(newBlob);

              const link = document.createElement("a");
              link.href = blobUrl;

              const translationFileName = t("page.feeling-at-home-details.export.modal.file-name");
              const name = month ? "-" + format(month, "yyyy-MM") : "";

              link.setAttribute("download", `${translationFileName.replace(/[^a-zA-Z0-9]/g, "")}-${name}.xlsx`);
              document.body.appendChild(link);
              link.click();
              if (link.parentNode) {
                link.parentNode.removeChild(link);
              }

              window.URL.revokeObjectURL(blobUrl);
            }}
          >
            {t("page.feeling-at-home-details.export.modal.button")}
          </Button>
        </Modal.Actions>
      )}
    </Modal.Root>
  );
}
