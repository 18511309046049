import { useInfiniteQuery } from "@tanstack/react-query";
import { createColumnHelper, getCoreRowModel, useReactTable } from "@tanstack/react-table";
import { useApi } from "api/hooks/useApi";
import type { MessageInformDto } from "api/types";
import { Button } from "components/Button/Button";
import { formatDate } from "components/FormattedDate/FormattedDate";
import { LoadingIcon } from "components/Icons/Icons";
import type { ModalBaseProps } from "components/Modal/Modal";
import { Modal } from "components/Modal/Modal";
import { Table } from "components/Table/Table";
import { Capture2 } from "components/Text/Text";
import { UserNameLink } from "components/UserNameLink/UserNameLink";
import { useProjectId } from "hooks/Network/useProjectId";
import { useOnIntersection } from "hooks/useOnIntersection";
import { QUERY_KEYS } from "query-keys";
import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";

const INFORMED_ADMINS_LENGTH = 15;

type InformedAdminsModalProps = ModalBaseProps & {
  messageId: string;
};

export function InformedAdminsModal({ messageId, isOpened, onOpenChange }: InformedAdminsModalProps): React.ReactNode {
  const projectId = useProjectId();
  const { t, i18n } = useTranslation();
  const api = useApi();

  const {
    data: informedAdminsData,
    isPending: isLoadingInformedAdmins,
    hasNextPage: hasMoreInformedAdmins,
    isFetchingNextPage: isLoadingMoreInformedAdmins,
    fetchNextPage: fetchMoreInformedAdmins,
    error: informedAdminsError,
  } = useInfiniteQuery({
    queryKey: QUERY_KEYS.MESSAGES_INFORMED_ADMINS(projectId, messageId),
    queryFn: async ({ pageParam = 0 }) => {
      const { data } = await api.getMessagesInformsV1(messageId, {
        Offset: pageParam * INFORMED_ADMINS_LENGTH,
        Limit: INFORMED_ADMINS_LENGTH,
      });

      return data;
    },
    initialPageParam: 0,
    getNextPageParam: (lastPage, pages) => {
      if (!lastPage.hasMore) {
        return undefined;
      }

      return pages.length;
    },
    enabled: isOpened,
  });

  const informedAdminsModal = useMemo(
    () => informedAdminsData?.pages.flatMap((x) => x.items ?? []) ?? [],
    [informedAdminsData],
  );

  const loaderRef = useOnIntersection({
    threshold: 0,
    onIntersect: useCallback(() => {
      if (!isLoadingMoreInformedAdmins && hasMoreInformedAdmins) {
        void fetchMoreInformedAdmins();
      }
    }, [fetchMoreInformedAdmins, hasMoreInformedAdmins, isLoadingMoreInformedAdmins]),
  });

  const columns = useMemo(() => {
    const helper = createColumnHelper<MessageInformDto>();

    return [
      helper.accessor("informed", {
        header: t("component.community-post.informed-admins.modal.header.informed"),
        cell: (cell) => (
          <UserNameLink user={cell.getValue()}>
            <span className="text-aop-basic-blue hocus:text-blue-darker hocus:underline">
              {cell.getValue().fullName}
            </span>
          </UserNameLink>
        ),
      }),
      helper.accessor("informedBy", {
        header: t("component.community-post.informed-admins.modal.header.by"),
        cell: (cell) => (
          <UserNameLink user={cell.getValue()}>
            <span className="text-aop-basic-blue hocus:text-blue-darker hocus:underline">
              {cell.getValue().fullName}
            </span>
          </UserNameLink>
        ),
      }),
      helper.accessor("createdAt", {
        header: t("component.community-post.informed-admins.modal.header.on"),
        cell: (cell) => <span className="whitespace-nowrap pr-4">{formatDate(i18n, "date", cell.getValue())}</span>,
      }),
    ];
  }, [t, i18n]);

  const tableInstance = useReactTable<MessageInformDto>({
    columns,
    data: informedAdminsModal ?? [],
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <Modal.Root
      title={t("component.community-post.informed-admins.modal.title")}
      description={t("component.community-post.informed-admins.modal.description")}
      size="sm"
      {...{ isOpened, onOpenChange }}
    >
      <div className="flex flex-col gap-4" data-testid="informed-admins-modal-content">
        {isLoadingInformedAdmins ? (
          <LoadingIcon className="inset-0 mx-auto my-4 w-6" />
        ) : (
          <>
            {(informedAdminsError || !informedAdminsModal.length) && (
              <Capture2>{t("component.community-post.informed-admins.modal.no-data")}</Capture2>
            )}
            {informedAdminsModal.length > 0 && (
              <div className="max-h-64 overflow-y-auto">
                <Table
                  table={tableInstance}
                  data-testid="informed-admins-list"
                  getRowProps={() => ({
                    "data-testid": "informed-admins-item",
                  })}
                />
                {hasMoreInformedAdmins && (
                  <div className="p-4" ref={loaderRef}>
                    <LoadingIcon className="inset-0 mx-auto my-4 w-6" />
                  </div>
                )}
              </div>
            )}
            <Modal.Actions>
              <Modal.Close>
                <Button styling="secondary">{t("common.action.close")}</Button>
              </Modal.Close>
            </Modal.Actions>
          </>
        )}
      </div>
    </Modal.Root>
  );
}
