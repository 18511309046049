import { useQuery } from "@tanstack/react-query";
import { useApi } from "api/hooks/useApi";
import { Button } from "components/Button/Button";
import { FullSizeLoader } from "components/FullSizeLoader/FullSizeLoader";
import { Modal, type ModalBaseProps } from "components/Modal/Modal";
import { format } from "date-fns";
import { useProjectId } from "hooks/Network/useProjectId";
import { QUERY_KEYS } from "query-keys";
import { useTranslation } from "react-i18next";

type SurveyExportModalProps = ModalBaseProps & {
  surveyName?: string;
  surveyId?: string;
};

export function SurveyExportModal({
  isOpened,
  onOpenChange,
  surveyName,
  surveyId,
}: SurveyExportModalProps): React.ReactNode {
  const projectId = useProjectId();
  const { t } = useTranslation();
  const api = useApi();

  const { data, isFetching, error } = useQuery({
    queryKey: QUERY_KEYS.SURVEY_EXPORT(projectId, surveyId!),
    queryFn: () => api.getSurveysExportV2(surveyId!),
    enabled: !!surveyId && isOpened,
  });

  return (
    <Modal.Root
      title={t("page.surveys.export.modal.title")}
      description={t("page.surveys.export.modal.description")}
      isScrollable
      {...{ isOpened, onOpenChange }}
    >
      <div className="relative flex flex-col gap-4 p-4">
        {isFetching && (
          <div className="pointer-events-none absolute inset-0 z-10 bg-white" tabIndex={-1}>
            <FullSizeLoader size="small" />
          </div>
        )}
        {error && <span className="block w-full text-red">{t("page.surveys.export.modal.error")}</span>}
        <Modal.Actions>
          <Button
            onClick={async () => {
              if (!data) {
                return;
              }

              const res = await data.blob();
              const newBlob = new Blob([res]);

              const blobUrl = window.URL.createObjectURL(newBlob);

              const link = document.createElement("a");
              link.href = blobUrl;
              link.setAttribute(
                "download",
                `${surveyName?.replace(/[^a-zA-Z0-9]/g, "") || "survey"}-${format(new Date(), "yyyy-MM-dd")}.xlsx`,
              );
              document.body.appendChild(link);
              link.click();
              if (link.parentNode) {
                link.parentNode.removeChild(link);
              }

              window.URL.revokeObjectURL(blobUrl);
            }}
          >
            {t("page.surveys.export.modal.button")}
          </Button>
        </Modal.Actions>
      </div>
    </Modal.Root>
  );
}
