import type { ImageDto, UserDto } from "api/types";
import iconCamera01 from "assets/icons/camera-01.svg";
import iconEdit05 from "assets/icons/edit-05.svg";
import iconFileAttachment01 from "assets/icons/file-attachment-01.svg";
import iconSend03 from "assets/icons/send-03.svg";
import iconX from "assets/icons/x.svg";
import { Button } from "components/Button/Button";
import { IconButton } from "components/Button/IconButton";
import type { FormDocument } from "components/DocumentInput/useDocumentFile";
import { useFlashToast } from "components/FlashToast/FlashToast";
import { Gallery } from "components/Gallery/Gallery";
import { Icon } from "components/Icon/Icon";
import { LoadingIcon } from "components/Icons/Icons";
import type { FormImage } from "components/ImageInput/useImageInput";
import { Pdf } from "components/Pdf/Pdf";
import { ScalingTextArea } from "components/ScalingTextArea/ScalingTextArea";
import { Tooltip } from "components/Tooltip/Tooltip";
import { UserAvatar } from "components/UserAvatar/UserAvatar";
import { isTouchDevice } from "helpers/device";
import { DEFAULT_MAXIMUM_FILE_SIZE_IN_MEGA_BYTES, megaBytesToBytes } from "helpers/file-size";
import { twResolve } from "helpers/tw-resolve";
import { useBool } from "hooks/useBool";
import { useCombinedRefs } from "hooks/useCombinedRef";
import type React from "react";
import type { ComponentProps } from "react";
import { forwardRef, useEffect, useId, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { twJoin } from "tailwind-merge";

export type BaseCommentFieldProps = {
  value: string;
  tooltips?: {
    send?: string | undefined;
    uploadImage?: string | undefined;
    uploadDocument?: string | undefined;
  };
  isEdit?: boolean;
  isNote?: boolean;
  disabled?: boolean;
  allowedAttachments?: ("image" | "document")[];
  images?: FormImage[];
  documents?: FormDocument[];
  onSubmit: () => Promise<void>;
  onChange: (value: string) => void;
  onAddImages?: (images: FileList) => void;
  onRemoveImage?: (image: FormImage) => void;
  onAddDocument?: (document: FileList) => void;
  onRemoveDocument?: (document: FormDocument) => void;
  onCancel?: () => void;
} & Pick<React.InputHTMLAttributes<HTMLInputElement>, "autoFocus" | "placeholder">;

export interface CommentFieldWithAvatarProps extends BaseCommentFieldProps {
  user: UserDto;
  isReply?: boolean;
  actionComponent?: React.ReactNode;
}

export const CommentFieldWithAvatar = forwardRef<HTMLTextAreaElement, CommentFieldWithAvatarProps>(
  function CommentFieldWithAvatar(props, ref): React.ReactNode {
    const [isSubmitting, setSubmitting] = useBool();
    const showFlashToast = useFlashToast();
    const { t } = useTranslation();

    async function onSubmit() {
      try {
        setSubmitting.setTrue();
        await props.onSubmit();
      } finally {
        setSubmitting.setFalse();
      }
    }

    function onAddDocument(documents: FileList) {
      if (documents.length === 0) return;

      const maxFileSize = megaBytesToBytes(DEFAULT_MAXIMUM_FILE_SIZE_IN_MEGA_BYTES);
      if (Array.from(documents).some((document) => document.size > maxFileSize)) {
        showFlashToast({
          title: t("components.form.error.file-too-big", {
            sizeInMegaBytes: maxFileSize,
          }),
          type: "error",
        });
      } else {
        props.onAddDocument?.(documents);
      }
    }

    function onAddImages(images: FileList) {
      if (images.length === 0) return;

      const maxFileSize = megaBytesToBytes(DEFAULT_MAXIMUM_FILE_SIZE_IN_MEGA_BYTES);
      if (Array.from(images).some((image) => image.size > maxFileSize)) {
        showFlashToast({
          title: t("components.form.error.file-too-big", {
            sizeInMegaBytes: maxFileSize,
          }),
          type: "error",
        });
      } else {
        props.onAddImages?.(images);
      }
    }

    const isContentAvailable = !!props.value && props.value.length > 0;
    const isImageAttachmentAvailable = !!props.images && props.images.length > 0;
    const isDocumentAttachmentAvailable = !!props.documents && props.documents.length > 0;
    const canSendComment = isContentAvailable || isImageAttachmentAvailable || isDocumentAttachmentAvailable;

    return (
      <CommentFieldWrapper>
        <CommentFieldRow>
          <div className="flex w-full items-start gap-2">
            <div className="flex h-10 items-center">
              <CommentFieldAvatar avatar={props.user.avatar} isDeleted={!!props.user.deletedAt} />
            </div>
            <div className="flex w-full flex-col items-center gap-2">
              {props.actionComponent}
              <CommentFieldInputBox isSubmitting={isSubmitting} isNote={props.isNote} disabled={props.disabled}>
                <CommentFieldInput
                  ref={ref}
                  autoFocus={props.autoFocus}
                  value={props.value}
                  isEdit={props.isEdit}
                  isSubmitting={isSubmitting}
                  onChange={props.onChange}
                  isNote={props.isNote}
                  placeholder={props.placeholder}
                  disabled={props.disabled}
                />
                <CommentFieldActions isNote={props.isNote} isSubmitting={isSubmitting}>
                  {props.allowedAttachments?.includes("image") && !!onAddImages && (
                    <CommentFieldImageAction
                      isSubmitting={isSubmitting}
                      disabled={props.disabled || (props.documents && props.documents.length > 0)}
                      imageUploadTooltip={props.tooltips?.uploadImage}
                      {...{ onAddImages }}
                    />
                  )}
                  {props.allowedAttachments?.includes("document") && !!onAddDocument && (
                    <CommentFieldDocumentAction
                      isSubmitting={isSubmitting}
                      disabled={props.disabled || (props.images && props.images.length > 0)}
                      documentUploadTooltip={props.tooltips?.uploadDocument}
                      {...{ onAddDocument }}
                    />
                  )}
                  {!props.isReply && (
                    <div className="flex flex-row items-center gap-2">
                      <CommentFieldSend
                        isSubmitting={isSubmitting}
                        isEdit={props.isEdit}
                        canSend={canSendComment}
                        sendTooltip={props.tooltips?.send}
                        {...{ onSubmit }}
                      />
                      {Boolean(props.onCancel) && (
                        <CommentFieldInlineCancel size="sm" onCancel={props.onCancel} isCancellable={props.isEdit} />
                      )}
                    </div>
                  )}
                </CommentFieldActions>
              </CommentFieldInputBox>
            </div>
          </div>

          {props.isReply && (
            <>
              <CommentFieldSend
                isReply
                isSubmitting={isSubmitting}
                isEdit={props.isEdit}
                canSend={canSendComment}
                sendTooltip={props.tooltips?.send}
                {...{ onSubmit }}
              />
              {Boolean(props.onCancel) && (
                <CommentFieldInlineCancel onCancel={props.onCancel} isCancellable={props.isEdit} />
              )}
            </>
          )}
        </CommentFieldRow>
        {props.images && props.images.length > 0 && (
          <CommentFieldGallery images={props.images} onRemoveImage={props.onRemoveImage} />
        )}
        {props.documents && props.documents.length > 0 && (
          <CommentFieldDocumentPreview documents={props.documents} onRemoveDocument={props.onRemoveDocument} />
        )}
      </CommentFieldWrapper>
    );
  },
);

function CommentFieldWrapper({ children }: React.PropsWithChildren<{}>) {
  return <div className="flex flex-1 flex-col gap-4">{children}</div>;
}

function CommentFieldRow({ children }: React.PropsWithChildren<{}>) {
  return <div className="relative flex flex-col items-end gap-2 md:flex-row md:items-center">{children}</div>;
}

function CommentFieldInputBox({
  isSubmitting,
  isNote,
  disabled,
  children,
}: React.PropsWithChildren<{
  isSubmitting?: boolean;
  isNote?: boolean;
  disabled?: boolean;
}>) {
  return (
    <div
      className={twResolve(
        "flex w-full flex-1 flex-col items-center gap-2 rounded-lg border border-grey-lighter bg-white p-1 focus-within:border-grey-darkest @4xl:flex-row @4xl:items-start",
        isSubmitting && "pointer-events-none opacity-30",
        disabled ? "bg-grey-lightest" : isNote ? "bg-yellow-lightest" : "",
      )}
    >
      {children}
    </div>
  );
}

const CommentFieldInput = forwardRef<
  HTMLTextAreaElement,
  {
    autoFocus?: boolean;
    disabled?: boolean;
    value?: string;
    placeholder?: string;
    onChange: (value: string) => void;
    isEdit?: boolean;
    isNote?: boolean;
    isSubmitting?: boolean;
  }
>(function CommentFieldInput({ autoFocus, value, onChange, placeholder, isEdit, isNote, isSubmitting, disabled }, ref) {
  const { t } = useTranslation();
  const textAreaRef = useRef<HTMLTextAreaElement | null>(null);
  const combinedRef = useCombinedRefs(textAreaRef, ref);

  useEffect(() => {
    if (isEdit && textAreaRef.current) {
      textAreaRef.current.focus();
      textAreaRef.current.setSelectionRange(textAreaRef.current.value.length, textAreaRef.current.value.length);
      textAreaRef.current.scrollTop = textAreaRef.current.scrollHeight;
    }
  }, [isEdit, textAreaRef]);

  return (
    <ScalingTextArea
      className={twJoin(
        "block min-h-10 w-full resize-none bg-transparent py-2 pl-1 placeholder:text-grey focus:outline-none",
        isSubmitting && "pointer-events-none opacity-30",
        isNote && "bg-yellow-lightest",
      )}
      disabled={disabled}
      autoFocus={autoFocus && !isTouchDevice()}
      data-testid="comment-field"
      name="content"
      ref={combinedRef}
      value={value || ""}
      onChange={(event) => onChange(event.target.value)}
      placeholder={
        placeholder
          ? placeholder
          : isNote
            ? t("component.comment-field.create-new-note")
            : t("component.comment-field.create-new-reaction")
      }
    />
  );
});

function CommentFieldGallery({
  images,
  onRemoveImage,
}: {
  images: FormImage[];
  onRemoveImage?: (image: FormImage) => void;
}) {
  return (
    <div className="pl-10" data-testid="comment-attachment-image-preview">
      <Gallery images={images} onRemove={onRemoveImage} />
    </div>
  );
}

function CommentFieldDocumentPreview({
  documents,
  onRemoveDocument,
}: {
  documents: FormDocument[];
  onRemoveDocument?: (document: FormDocument) => void;
}) {
  const [fileUrl, setFileUrl] = useState<string | undefined>(undefined);

  const document = documents[0];

  useEffect(() => {
    if (!document) {
      setFileUrl(undefined);

      return;
    }

    let currFileUrl;
    if ("file" in document) {
      currFileUrl = URL.createObjectURL(document.file);
    } else {
      currFileUrl = document.url;
    }

    setFileUrl(currFileUrl);

    return () => {
      if (currFileUrl && currFileUrl.includes("blob")) {
        URL.revokeObjectURL(currFileUrl);
      }
    };
  }, [document]);

  const fileName = "file" in document ? document.file.name : document.fileName;
  let fileSize: number | undefined = undefined;
  if ("file" in document) {
    fileSize = document.file.size;
  } else if ("fileSize" in document) {
    fileSize = document.fileSize as number;
  }

  return (
    <div className="pl-10" data-testid="comment-attachment-document-preview">
      <Pdf
        onClick={() => window.open(fileUrl, "_blank")}
        onDelete={() => onRemoveDocument && onRemoveDocument(document)}
        {...{ fileName, fileSize }}
      />
    </div>
  );
}

function CommentFieldAvatar({ avatar, isDeleted }: { avatar?: ImageDto; isDeleted?: boolean }) {
  return (
    <div className="size-10 shrink-0">
      <UserAvatar img={avatar} isUserDeleted={isDeleted} />
    </div>
  );
}

function CommentFieldActions({
  isSubmitting,
  isNote,
  children,
}: {
  isSubmitting?: boolean;
  isNote?: boolean;
  children?: React.ReactNode;
}) {
  return (
    <div
      className={twResolve(
        "flex h-10 items-center self-end bg-transparent text-grey xl:self-start",
        isNote && "bg-yellow-lightest",
        isSubmitting && "pointer-events-none text-grey-lighter",
        isNote && isSubmitting && "bg-yellow-lightest/30",
      )}
    >
      {children}
    </div>
  );
}

function CommentFieldImageAction({
  isSubmitting,
  disabled,
  onAddImages,
  imageUploadTooltip,
}: {
  isSubmitting?: boolean;
  disabled?: boolean;
  onAddImages: (images: FileList) => void;
  imageUploadTooltip?: string;
}) {
  const id = useId();
  const fileInput = useRef<HTMLInputElement>(null);
  const { t } = useTranslation();

  const onSelectImage = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) return;

    onAddImages(e.target.files);
    e.target.value = "";
  };

  let tooltip: string | null = t("component.comment-field.upload-image");
  if (disabled) {
    tooltip = null;
  } else if (imageUploadTooltip) {
    tooltip = imageUploadTooltip;
  }

  return (
    <Tooltip tooltip={tooltip}>
      <label
        className={twJoin(
          "relative mr-1 flex size-7 cursor-pointer items-center justify-center rounded-full transition-colors",
          disabled
            ? "opacity-30"
            : "focus-within:text-grey-darkest hover:bg-blue-lightest hover:text-grey-darkest focus:outline-none",
          isSubmitting && "pointer-events-none opacity-30",
        )}
        htmlFor={`comment-image-input-${id}`}
        data-testid="add-image-btn"
      >
        <input
          data-testid="image-input"
          className="sr-only"
          ref={fileInput}
          type="file"
          id={`comment-image-input-${id}`}
          name="img"
          accept="image/png, image/jpeg"
          onChange={onSelectImage}
          disabled={disabled}
        />
        <Icon name={iconCamera01} size={20} />
      </label>
    </Tooltip>
  );
}

function CommentFieldDocumentAction({
  isSubmitting,
  disabled,
  documentUploadTooltip,
  onAddDocument,
}: {
  isSubmitting?: boolean;
  disabled?: boolean;
  documentUploadTooltip?: string;
  onAddDocument: (document: FileList) => void;
}) {
  const id = useId();
  const { t } = useTranslation();

  let tooltip: string | null = t("component.comment-field.upload-document");
  if (disabled) {
    tooltip = null;
  } else if (documentUploadTooltip) {
    tooltip = documentUploadTooltip;
  }

  const onSelectFile = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!event.target.files) return;

    onAddDocument(event.target.files);
    event.target.value = "";
  };

  return (
    <Tooltip tooltip={tooltip}>
      <label
        className={twJoin(
          "relative mr-1 flex size-7 cursor-pointer items-center justify-center rounded-full",
          disabled
            ? "opacity-30"
            : "transition-colors focus-within:text-grey-darkest hover:bg-blue-lightest hover:text-grey-darkest focus:outline-none",
          isSubmitting && "pointer-events-none opacity-30",
        )}
        htmlFor={`comment-document-input-${id}`}
        data-testid="add-document-btn"
      >
        <input
          data-testid="document-input"
          className="sr-only"
          type="file"
          id={`comment-document-input-${id}`}
          name="document"
          accept="application/pdf"
          onChange={onSelectFile}
          {...{ disabled }}
        />
        <Icon name={iconFileAttachment01} size={20} />
      </label>
    </Tooltip>
  );
}

function CommentFieldSend({
  sendTooltip,
  isSubmitting,
  isEdit,
  canSend,
  onSubmit,
  isReply,
}: {
  sendTooltip?: string;
  isSubmitting?: boolean;
  isEdit?: boolean;
  canSend?: boolean;
  onSubmit: () => Promise<void>;
  isReply?: boolean;
}) {
  const { t } = useTranslation();

  const onClickSend = () => {
    void onSubmit();
  };

  return isReply ? (
    <Button
      title={sendTooltip ? sendTooltip : t("component.comment-field.send-comment")}
      className="min-w-fit"
      data-testid="send-comment"
      styling="primary"
      disabled={isSubmitting || !canSend}
      onClick={onSubmit}
      isLoading={isSubmitting}
    >
      {isEdit ? t("component.community-post.comments.edit") : t("component.community-post.comments.reply")}
    </Button>
  ) : (
    <Tooltip tooltip={sendTooltip ? sendTooltip : t("component.comment-field.send-comment")}>
      <button
        className={twResolve(
          "flex size-7 cursor-pointer items-center justify-center rounded-full transition-colors hover:bg-blue-lightest focus:bg-blue-lighter focus:outline-none disabled:pointer-events-none",
          canSend ? "text-aop-basic-blue" : "opacity-30",
          isSubmitting && "pointer-events-none",
        )}
        data-testid="send-comment"
        disabled={isSubmitting || !canSend}
        type="button"
        onClick={onClickSend}
      >
        {isSubmitting ? (
          <LoadingIcon className="w-5" />
        ) : isEdit ? (
          <Icon name={iconEdit05} size={20} />
        ) : (
          <Icon name={iconSend03} size={20} />
        )}
      </button>
    </Tooltip>
  );
}

function CommentFieldInlineCancel({
  size,
  onCancel,
  isCancellable,
}: {
  size?: ComponentProps<typeof IconButton>["size"];
  onCancel?: () => void;
  isCancellable?: boolean;
}) {
  const { t } = useTranslation();

  if (!isCancellable || !onCancel) {
    return null;
  }

  return (
    <IconButton onClick={onCancel} size={size} styling="secondary" title={t("common.action.cancel")}>
      <Icon name={iconX} size={20} />
    </IconButton>
  );
}
