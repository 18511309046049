import type { MessageStatusChangeRequest } from "api/types";
import checkIcon from "assets/icons/check.svg";
import trashIcon from "assets/icons/trash-01.svg";
import { Button } from "components/Button/Button";
import { Form } from "components/Form/Form";
import { FormContent } from "components/Form/FormContent";
import { FormField } from "components/Form/FormField";
import { FormTextArea } from "components/Form/FormTextArea";
import { Icon } from "components/Icon/Icon";
import { Modal, type ModalBaseProps } from "components/Modal/Modal";
import type { Variants } from "framer-motion";
import { AnimatePresence, motion } from "framer-motion";
import { createRequiredStringRule } from "helpers/rules";
import { useState } from "react";
import { useForm, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";

const reasons = ["problemSolved", "mistake", "other"] as const;
const subReasons: MessageStatusChangeRequest["reason"][] = [
  "solvedHelpInsideCommunity",
  "solvedHelpOutsideCommunity",
  "solvedProblemIrrelevant",
  "solvedOther",
];

type ArchivePostModalProps = ModalBaseProps & {
  onDelete: (data: Omit<MessageStatusChangeRequest, "newStatus">) => void;
  onArchive: (data: Omit<MessageStatusChangeRequest, "newStatus">) => void;
};

export interface FormValues {
  type: "archive" | "delete";
  reason?: MessageStatusChangeRequest["reason"];
  details?: string;
}

const MIN_LENGTH = 8;
const MAX_LENGTH = 255;

const variants = {
  enter: (direction: "left" | "right") => {
    return {
      x: direction === "right" ? "100%" : "-100%",
      opacity: 0,
    };
  },
  center: {
    zIndex: 1,
    x: 0,
    opacity: 1,
  },
  exit: (direction: "left" | "right") => {
    return {
      zIndex: 0,
      x: direction === "left" ? "100%" : "-100%",
      opacity: 0,
    };
  },
} satisfies Variants;

export function ArchivePostModal({
  isOpened,
  onOpenChange,
  onDelete,
  onArchive,
}: ArchivePostModalProps): React.ReactNode {
  const { t } = useTranslation();
  const [step, setStep] = useState<number>(0);
  const [direction, setDirection] = useState<"left" | "right">("right");
  const form = useForm<FormValues>({});

  const reason = useWatch({ control: form.control, name: "reason" });

  function handlePrevStep(step: number) {
    setDirection("left");
    // Timeout to prevent race condition with the direction animation
    setTimeout(() => {
      setStep(step);
    }, 10);
  }

  function handleNextStep(step: number) {
    setDirection("right");
    // Timeout to prevent race condition with the direction animation
    setTimeout(() => {
      setStep(step);
    }, 10);
  }

  function handleClose() {
    form.reset();
    setDirection("right");
    setStep(0);
    onOpenChange(false);
  }

  async function handleDetailsValidation() {
    const isValid = await form.trigger("details");
    if (isValid) {
      handleNextStep(3);
    }
  }

  const handleDelete = (values: FormValues) => {
    onDelete({ reason: values.reason!, details: values.details });
    handleClose();
  };

  const handleArchive = (values: FormValues) => {
    onArchive({ reason: values.reason!, details: values.details });
    handleClose();
  };

  const handleSubmit = (values: FormValues) => {
    if (values.type === "archive") {
      handleArchive(values);
    } else {
      handleDelete(values);
    }
    handleClose();
  };

  let description = "";
  switch (step) {
    case 0:
    case 1:
      description = t("component.community-post.archive.modal.description.reason");
      break;
    case 2:
      description = t("component.community-post.archive.modal.description.other");
      break;
    default:
      description = t("component.community-post.archive.modal.description.options");
  }

  return (
    <Modal.Root
      title={t("component.community-post.archive.modal.title")}
      description={description}
      size="md"
      data-testid="archive-post-modal"
      {...{ onOpenChange, isOpened }}
    >
      <div className="flex flex-col gap-2 overflow-x-hidden">
        <Form formMethods={form} onSubmit={handleSubmit} className="flex w-full flex-1">
          <AnimatePresence mode="popLayout" initial={false}>
            <motion.div
              key={step}
              custom={direction}
              initial="enter"
              animate="center"
              exit="exit"
              transition={{
                x: { type: "spring", stiffness: 300, damping: 30 },
                opacity: { duration: 0.1 },
              }}
              variants={variants}
              className="flex flex-1 flex-col justify-between gap-6"
            >
              {step === 0 ? (
                <div className="flex flex-col gap-2">
                  {reasons.map((reason) => (
                    <button
                      type="button"
                      key={reason}
                      onClick={() => {
                        if (reason === "problemSolved") {
                          handleNextStep(1);
                        } else if (reason === "other") {
                          form.setValue("reason", reason);
                          handleNextStep(2);
                        } else {
                          form.setValue("reason", reason);
                          handleNextStep(3);
                        }
                      }}
                      className="flex w-full items-center gap-2 rounded-lg border border-grey-lighter p-4 text-left hover:bg-grey-lightest"
                    >
                      {reason === "problemSolved"
                        ? t("component.community-post.archive.reason.option.solved")
                        : reason === "mistake"
                          ? t("component.community-post.archive.reason.option.mistake")
                          : t("component.community-post.archive.reason.option.other")}
                    </button>
                  ))}
                </div>
              ) : step === 1 ? (
                <div className="flex flex-col gap-2">
                  {subReasons.map((reason) => (
                    <button
                      type="button"
                      key={reason}
                      onClick={() => {
                        form.setValue("reason", reason);
                        if (reason.includes("Other")) {
                          handleNextStep(2);
                        } else {
                          handleNextStep(3);
                        }
                      }}
                      className="flex w-full items-center gap-2 rounded-lg border border-grey-lighter p-4 text-left hover:bg-grey-lightest"
                    >
                      {reason === "solvedHelpInsideCommunity"
                        ? t("component.community-post.archive.subreason.option.solved-inside")
                        : reason === "solvedHelpOutsideCommunity"
                          ? t("component.community-post.archive.subreason.option.solved-outside")
                          : reason === "solvedProblemIrrelevant"
                            ? t("component.community-post.archive.subreason.option.irrelevant")
                            : t("component.community-post.archive.subreason.option.other")}
                    </button>
                  ))}
                  <Modal.Actions>
                    <Button styling="secondary" onClick={() => handlePrevStep(0)}>
                      {t("common.action.back")}
                    </Button>
                  </Modal.Actions>
                </div>
              ) : step === 2 ? (
                <>
                  <FormContent className="mb-0" maxWidth="none">
                    <FormField
                      htmlFor="details"
                      label={t("component.community-post.archive.modal.description.other")}
                      required
                    >
                      <FormTextArea<FormValues>
                        id="details"
                        name="details"
                        placeholder={t("component.community-post.archive.other.placeholder")}
                        rules={{
                          validate: {
                            required: createRequiredStringRule(
                              t,
                              "component.community-post.archive.modal.description.other",
                            ),
                          },
                          minLength: {
                            message: t("components.form.error.min-length", { length: MIN_LENGTH }),
                            value: MIN_LENGTH,
                          },
                          maxLength: {
                            message: t("components.form.error.max-length", { length: MAX_LENGTH }),
                            value: MAX_LENGTH,
                          },
                        }}
                      />
                    </FormField>
                  </FormContent>
                  <Modal.Actions>
                    <Button
                      styling="secondary"
                      onClick={() => {
                        form.resetField("details");
                        if (reason?.startsWith("solved")) {
                          handlePrevStep(1);
                        } else {
                          handlePrevStep(0);
                        }
                      }}
                    >
                      {t("common.action.back")}
                    </Button>
                    <Button type="button" styling="primary" onClick={handleDetailsValidation}>
                      {t("common.action.continue")}
                    </Button>
                  </Modal.Actions>
                </>
              ) : (
                <>
                  <Modal.Actions>
                    <Button
                      styling="secondary"
                      onClick={() => {
                        if (reason?.toLowerCase().includes("other")) {
                          handlePrevStep(2);
                        } else if (reason?.startsWith("solved")) {
                          handlePrevStep(1);
                        } else {
                          handlePrevStep(0);
                        }
                      }}
                    >
                      {t("common.action.back")}
                    </Button>
                    <Button
                      styling="danger"
                      type="submit"
                      icon={<Icon name={trashIcon} />}
                      onClick={() => form.setValue("type", "delete")}
                    >
                      {t("component.community-post.archive.modal.confirm.delete")}
                    </Button>
                    <Button
                      styling="primary"
                      type="submit"
                      icon={<Icon name={checkIcon} />}
                      onClick={() => form.setValue("type", "archive")}
                    >
                      {t("component.community-post.archive.modal.confirm.archive")}
                    </Button>
                  </Modal.Actions>
                </>
              )}
            </motion.div>
          </AnimatePresence>
        </Form>
      </div>
    </Modal.Root>
  );
}
