import { Button } from "components/Button/Button";
import { Modal } from "components/Modal/Modal";
import type { MouseEventHandler, PropsWithChildren, ReactNode } from "react";
import { useTranslation } from "react-i18next";

export interface ConfirmModalProps {
  isOpen: boolean;
  id: string;
  title: string;
  description?: string;
  renderTitle?: (title: string) => ReactNode;
  renderDescription?: (title: string) => ReactNode;
  isLoading: boolean;
  onResolve: MouseEventHandler;
  resolveBtnProps?: {
    hide?: boolean;
    text?: string;
    "data-testid"?: string;
  };
  onReject: MouseEventHandler;
  rejectBtnProps?: {
    hide?: boolean;
    text?: string;
    "data-testid"?: string;
  };
  theme?: "info" | "danger";
  "data-testid"?: string;
  shouldCloseOnEsc?: boolean;
  isActionRequired?: boolean;
  onAfterClose?: () => void;
  onOpenChange: (state: boolean) => void;
}

export function ConfirmModal({
  id,
  title,
  description,
  isLoading,
  renderTitle = (title) => title,
  renderDescription = (description) => description,
  onReject,
  rejectBtnProps,
  onResolve,
  resolveBtnProps,
  children,
  theme = "info",
  isOpen,
  shouldCloseOnEsc,
  isActionRequired,
  onAfterClose,
  onOpenChange,
  ...props
}: PropsWithChildren<ConfirmModalProps>): React.ReactNode {
  const { t } = useTranslation();
  const titleId = `${id}-title`;
  const descriptionId = `${id}-description`;

  return (
    <Modal.Root
      isOpened={isOpen}
      onOpenChange={(state) => {
        if (!state) {
          onAfterClose?.();
        }

        onOpenChange(state);
      }}
      ids={{
        root: id,
        title: titleId,
        description: descriptionId,
      }}
      size="sm"
      title={renderTitle(title)}
      description={description && renderDescription(description)}
      {...{ shouldCloseOnEsc }}
    >
      <article className="grid grid-cols-1 gap-y-2" data-testid={props["data-testid"]}>
        {children}
        <Modal.Actions>
          {!rejectBtnProps?.hide && (
            <Button
              className="w-full sm:w-auto"
              onClick={onReject}
              styling="secondary"
              disabled={isLoading}
              data-testid={rejectBtnProps?.["data-testid"] || "confirm-modal-reject"}
            >
              {rejectBtnProps?.text ?? t("common.action.cancel")}
            </Button>
          )}
          {!resolveBtnProps?.hide && (
            <Button
              className="w-full sm:w-auto"
              onClick={onResolve}
              isLoading={isLoading}
              styling={theme === "danger" ? "danger" : "primary"}
              data-testid={resolveBtnProps?.["data-testid"] || "confirm-modal-resolve"}
            >
              {resolveBtnProps?.text ?? t("common.action.ok")}
            </Button>
          )}
        </Modal.Actions>
      </article>
    </Modal.Root>
  );
}
