import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useApi } from "api/hooks/useApi";
import type { CommunityFeedSurveyDto } from "api/types";
import iconCheck from "assets/icons/check.svg";
import iconClock from "assets/icons/clock.svg";
import { Anchor } from "components/Anchor/Anchor";
import { Button } from "components/Button/Button";
import { ConfirmModal } from "components/ConfirmModal/ConfirmModal";
import { useFlashToast } from "components/FlashToast/FlashToast";
import { FormattedDate } from "components/FormattedDate/FormattedDate";
import { formatDistance } from "components/FormattedDistance/FormattedDistance";
import { Icon } from "components/Icon/Icon";
import { LinkFormatter } from "components/LinkFormatter/LinkFormatter";
import { Capture2, Headline4 } from "components/Text/Text";
import { parseISO } from "date-fns";
import { getDeepLinkUrl } from "helpers/deep-link";
import { useProject } from "hooks/Network/useProject";
import { useBool } from "hooks/useBool";
import { QUERY_KEYS } from "query-keys";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { CommunityItem } from "./CommunityItem";

interface CommunitySurveyProps {
  survey: CommunityFeedSurveyDto;
  updatedAt?: string;
}

export function CommunitySurvey({ survey, updatedAt }: CommunitySurveyProps): React.ReactNode {
  const project = useProject();
  const api = useApi();
  const queryClient = useQueryClient();
  const { t, i18n } = useTranslation();
  const showFlashToast = useFlashToast();

  const [isAppSurveyModalOpen, appSurveyModalHandler] = useBool(false);

  const markAsRead = useMutation({
    mutationFn: () => api.postSurveysReadV1(survey.id),
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: [...QUERY_KEYS.MESSAGES(project.id), "FEED"],
      });
      await queryClient.invalidateQueries({ queryKey: QUERY_KEYS.MESSAGES_FEED_STATS(project.id) });
    },
    onError: () => {
      showFlashToast({ type: "error", title: t("component.community-survey.mark-as-read.error") });
    },
  });

  const actions = useMemo(() => {
    const actions = [];

    if (!survey) return [];

    if (survey.isUnread) {
      actions.push({
        dataTestId: "post-context-menu-mark-read",
        text: t("component.community-post.actions.mark-as-read"),
        icon: <Icon name={iconCheck} size={16} />,
        callback: markAsRead.mutateAsync,
      });
    }

    return actions;
  }, [survey, t, markAsRead]);

  const onClickMarkAsRead = async () => {
    await markAsRead.mutateAsync();
  };

  if (!survey) {
    return null;
  }

  return (
    <>
      <CommunityItem
        ring={survey.isUnread ? "unread" : undefined}
        user={survey.createdBy}
        group={survey.group}
        actions={actions}
        dateSubtitle={survey.createdAt && <FormattedDate format="datetime" date={survey.createdAt} />}
      >
        <div className="flex flex-col gap-2 md:flex-row">
          <div className="flex flex-1 flex-col gap-2">
            <div className="flex max-w-[500px] flex-col gap-4 rounded-md p-2 shadow-sm">
              <div className="flex w-fit items-center gap-1 rounded-md bg-aop-basic-blue-lightest px-2 py-1 text-aop-basic-blue">
                <Icon name={iconClock} size={16} />
                {survey.endsAt && new Date(survey.endsAt) > new Date() ? (
                  <Capture2>{formatDistance(i18n, { start: new Date(), end: parseISO(survey.endsAt) })}</Capture2>
                ) : (
                  <Capture2>{t("component.community-post.survey-ended")}</Capture2>
                )}
              </div>
              <Headline4 className="break-words text-grey-darkest">{survey.title}</Headline4>
              <p className="text-grey-darkest">
                <LinkFormatter>{survey.description}</LinkFormatter>
              </p>
              <Button
                className="w-full text-sm"
                styling="primary"
                type="link"
                href={getDeepLinkUrl(project.appBranding, "/answer-survey")}
                onClick={onClickMarkAsRead}
                target="_blank"
                isExternal
              >
                {t("component.community-survey.button")}
              </Button>
            </div>
          </div>
        </div>
        <div className="-my-1 flex flex-row-reverse items-center justify-between gap-4 pb-2">
          {updatedAt && (
            <span className="text-sm text-grey">
              {t("component.community-post.last-activity", {
                time: formatDistance(i18n, { start: new Date(updatedAt) }),
              })}
            </span>
          )}
        </div>
      </CommunityItem>
      <ConfirmModal
        id="app-survey-modal"
        title={t("component.community-post.app-survey.modal.title")}
        description={t("component.community-post.app-survey.modal.description")}
        renderDescription={(description) => (
          <div className="flex flex-col items-center">
            <span>{description}</span>
            <Anchor to={t("component.community-post.app-survey.modal.link")}>
              {t("component.community-post.app-survey.modal.link")}
            </Anchor>
          </div>
        )}
        isLoading={false}
        theme="info"
        onResolve={appSurveyModalHandler.setFalse}
        onReject={appSurveyModalHandler.setFalse}
        rejectBtnProps={{ hide: true }}
        resolveBtnProps={{
          "data-testid": "app-survey-modal-confirm",
          text: t("common.action.ok"),
        }}
        isOpen={isAppSurveyModalOpen}
        shouldCloseOnEsc
        data-testid="cancel-app-survey-modal"
        onOpenChange={appSurveyModalHandler.set}
      />
    </>
  );
}
