import iconAlertTriangle from "assets/icons/alert-triangle.svg";
import { AlertBar } from "components/AlertBar/AlertBar";
import { Button } from "components/Button/Button";
import { Form } from "components/Form/Form";
import { FormCheckbox } from "components/Form/FormCheckbox";
import { FormField } from "components/Form/FormField";
import { FormInput } from "components/Form/FormInput";
import { Icon } from "components/Icon/Icon";
import type { ModalBaseProps } from "components/Modal/Modal";
import { Modal } from "components/Modal/Modal";
import { createRequiredStringRule } from "helpers/rules";
import { useForm, useWatch } from "react-hook-form";
import { Trans, useTranslation } from "react-i18next";

type CloseProjectModal = ModalBaseProps & {
  projectName: string;
  isLoading: boolean;
  onSubmit: (deleteUsers: boolean) => void;
};

export function CloseProjectModal({
  isOpened,
  onOpenChange,
  projectName,
  onSubmit,
  isLoading,
}: CloseProjectModal): React.ReactNode {
  const { t } = useTranslation();
  const form = useForm<CloseProjectModalValues>({
    defaultValues: {
      name: "",
      deleteUsers: true,
    },
  });

  const deleteUsers = useWatch({ control: form.control, name: "deleteUsers" });

  return (
    <Modal.Root
      title={t("page.projects.close-project.modal.title")}
      description={
        <Trans
          i18nKey="page.projects.close-project.modal.description"
          components={{
            b: <strong />,
          }}
        />
      }
      size="sm"
      {...{ isOpened, onOpenChange }}
    >
      <Form<CloseProjectModalValues> formMethods={form} onSubmit={(data) => onSubmit(data.deleteUsers)} noPrompt>
        <div className="flex flex-col items-center gap-4">
          <FormField label="">
            <FormInput<CloseProjectModalValues>
              id="name"
              name="name"
              rules={{
                validate: {
                  required: createRequiredStringRule(t, "page.projects.close-project.modal.form.name"),
                  notSameAsProjectName: (value) => {
                    if (!value) {
                      return undefined;
                    }

                    return value === projectName
                      ? undefined
                      : t("page.projects.close-project.modal.error.not-same-as-name");
                  },
                },
              }}
            />
          </FormField>
          <FormField label="">
            <FormCheckbox<CloseProjectModalValues>
              name="deleteUsers"
              label={t("page.projects.close-project.modal.form.delete-users")}
            />

            {deleteUsers && (
              <div className="mt-2">
                <AlertBar theme="blue" icon={<Icon name={iconAlertTriangle} />}>
                  {t("page.projects.close-project.modal.warning")}
                </AlertBar>
              </div>
            )}
          </FormField>

          <Modal.Actions>
            <Modal.Close>
              <Button type="button" styling="secondary" disabled={isLoading}>
                {t("common.action.cancel")}
              </Button>
            </Modal.Close>
            <Button type="submit" styling="danger" isLoading={isLoading}>
              {t("page.projects.close-project.modal.confirm")}
            </Button>
          </Modal.Actions>
        </div>
      </Form>
    </Modal.Root>
  );
}

interface CloseProjectModalValues {
  name: string;
  deleteUsers: boolean;
}
