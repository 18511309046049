import { useInfiniteQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { useApi } from "api/hooks/useApi";
import type { CreateGroupChatRequest, UserDto } from "api/types";
import usersIcon from "assets/icons/users-plus.svg";
import { Button } from "components/Button/Button";
import { Checkbox } from "components/Checkbox/Checkbox";
import { useFlashToast } from "components/FlashToast/FlashToast";
import { Form } from "components/Form/Form";
import { FormField } from "components/Form/FormField";
import { FormImageInput } from "components/Form/FormImageInput";
import { FormInput } from "components/Form/FormInput";
import { Icon } from "components/Icon/Icon";
import { LoadingIcon } from "components/Icons/Icons";
import type { FormImage } from "components/ImageInput/useImageInput";
import type { ModalBaseProps } from "components/Modal/Modal";
import { Modal } from "components/Modal/Modal";
import { SearchInput } from "components/SearchInput/SearchInput";
import { Capture1, Capture2, Overline2 } from "components/Text/Text";
import { UserAvatar } from "components/UserAvatar/UserAvatar";
import { validateSize } from "helpers/file-size";
import { commonAPIDataSelector } from "helpers/Network/selectors";
import { createRequiredStringRule } from "helpers/rules";
import { useProjectId } from "hooks/Network/useProjectId";
import { useSessionUser } from "hooks/Network/useSessionUser";
import { useUploadImage } from "hooks/Network/useUploadImage";
import { useDebounce } from "hooks/useDebounce";
import { useOnIntersection } from "hooks/useOnIntersection";
import { useSlug } from "hooks/useSlug";
import { QUERY_KEYS } from "query-keys";
import { useCallback, useDeferredValue, useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import { routes } from "routes";

interface CreateChatFormValues {
  groupMembers: UserDto[];
  groupName: string;
  groupImage?: FormImage[];
}

enum Step {
  CreateSingleChat,
  CreateGroupChatSelectMembers,
  CreateGroupChatConfirm,
}

type CreateChatModalProps = ModalBaseProps;

const DEBOUNCE_WAIT = 200;
const AVAILABLE_USERS_PAGE = 20;
export const MIN_GROUP_SEARCH_CHARACTERS = 2;
export const GROUP_NAME_MIN_LENGTH = 3;
export const GROUP_NAME_MAX_LENGTH = 40;

export function CreateChatModal({ isOpened, onOpenChange }: CreateChatModalProps): React.ReactNode {
  const { t } = useTranslation();
  const slug = useSlug();
  const projectId = useProjectId();
  const sessionUser = useSessionUser();
  const api = useApi();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const showFlashToast = useFlashToast();
  const { uploadFormImage, isUploadingImage } = useUploadImage();

  const [step, setStep] = useState<Step>(Step.CreateSingleChat);
  const [query, setQuery] = useState<string>("");

  const formMethods = useForm<CreateChatFormValues>({ defaultValues: { groupMembers: [] } });
  const debouncedQuery = useDeferredValue(
    useDebounce(query.trim().length < MIN_GROUP_SEARCH_CHARACTERS ? "" : query, DEBOUNCE_WAIT),
  );

  useEffect(() => {
    if (!isOpened) {
      formMethods.reset();
      setQuery("");
      setStep(0);
    }
  }, [formMethods, isOpened]);

  const {
    data: availableUsersData,
    hasNextPage: isMoreUsersAvailable,
    fetchNextPage: fetchMoreUsers,
    isLoading: isLoadingUsers,
    isFetchingNextPage: isFetchingMoreUsers,
  } = useInfiniteQuery({
    queryKey: QUERY_KEYS.CHATS_AVAILABLE_USERS(projectId, debouncedQuery),
    queryFn: ({ pageParam = 0 }) =>
      api
        .getChatsAvailableUsersV1({
          search: debouncedQuery,
          Offset: pageParam * AVAILABLE_USERS_PAGE,
          Limit: AVAILABLE_USERS_PAGE,
        })
        .then(commonAPIDataSelector),
    initialPageParam: 0,
    getNextPageParam: (lastPage, pages) => {
      if (!lastPage.hasMore) {
        return undefined;
      }

      return pages.length;
    },
    enabled: isOpened,
    staleTime: 30 * 1000, // 30 seconds
  });

  const { mutateAsync: createGroupChat, isPending: isCreatingGroupChat } = useMutation({
    mutationFn: (payload: CreateGroupChatRequest) => api.postChatsGroupV2(payload).then(({ data }) => data),
    onSettled: () => {
      void queryClient.invalidateQueries({ queryKey: QUERY_KEYS.CHATS_LIST(projectId) });
    },
    onSuccess: (data) => {
      onOpenChange(false);
      navigate(routes.chats.details({ slug, id: data.id }));
    },
    onError: () => {
      showFlashToast({
        type: "error",
        title: t("page.chats.create-chat.group.error"),
      });
    },
  });

  const amountTotalUsers = useMemo(() => availableUsersData?.pages[0].total ?? 0, [availableUsersData]);
  const availableUsers = useMemo(() => availableUsersData?.pages.flatMap((x) => x.items) ?? [], [availableUsersData]);
  const selectedUsers = formMethods.watch("groupMembers");

  const ref = useOnIntersection({
    threshold: 0,
    onIntersect: useCallback(() => {
      if (!isFetchingMoreUsers && isMoreUsersAvailable) {
        void fetchMoreUsers();
      }
    }, [fetchMoreUsers, isMoreUsersAvailable, isFetchingMoreUsers]),
  });

  const handlePrevStep = (step: number) => {
    if (step === 0) {
      formMethods.reset();
      setQuery("");
    }
    setStep(step);
  };

  const handleNextStep = (step: number) => {
    setQuery("");
    setStep(step);
  };

  const onToggleSelectUser = (checked: boolean, user: UserDto) => {
    if (checked) {
      formMethods.setValue("groupMembers", [...selectedUsers, user]);
    } else {
      formMethods.setValue(
        "groupMembers",
        selectedUsers.filter((selectedUser) => selectedUser.id !== user.id),
      );
    }
  };

  const onSubmit = async (values: CreateChatFormValues) => {
    const image = await uploadFormImage(values.groupImage?.[0]);

    await createGroupChat({
      name: values.groupName.trim(),
      imageId: image?.id,
      userIds: values.groupMembers.map((x) => x.id),
    });
  };

  const isSubmitting = isCreatingGroupChat || isUploadingImage;
  let title = "";
  switch (step) {
    case Step.CreateSingleChat:
      title = t("page.chats.modal.new-chat.start-title");
      break;
    case Step.CreateGroupChatSelectMembers:
      title = t("page.chats.modal.new-chat.group-chat.select-member.title");
      break;
    case Step.CreateGroupChatConfirm:
      title = t("page.chats.modal.new-chat.group-chat.group-details.title");
      break;
  }

  return (
    <Modal.Root {...{ title, isOpened, onOpenChange }} shouldCloseOnClickOutside={false}>
      <Form
        data-testid="chats-user-search-modal"
        className="flex max-h-full w-full flex-col gap-4"
        {...{ formMethods, onSubmit }}
      >
        {step === Step.CreateSingleChat && (
          <>
            <SearchInput
              data-testid="search-input"
              onChange={(e) => setQuery(e.target.value.trim())}
              placeholder={
                sessionUser.project.type === "addressBased"
                  ? t("page.chats.modal.new-chat.input-placeholder.address-based")
                  : t("page.chats.modal.new-chat.input-placeholder.company-based")
              }
            />
            {amountTotalUsers > 0 && (
              <Capture1>{t("page.chats.modal.new-chat.total-results", { count: amountTotalUsers })}</Capture1>
            )}
            {isLoadingUsers ? (
              <LoadingIcon className="inset-0 mx-auto my-4 w-6" />
            ) : availableUsers.length === 0 ? (
              <Capture2 className="px-3 pb-5 pt-1 text-grey-dark" as="p">
                {t("page.chats.modal.new-chat.no-results")}
              </Capture2>
            ) : (
              <ul className="h-64 w-full overflow-y-auto rounded-lg border border-grey-lighter">
                {availableUsers.map((availableUser) => (
                  <li
                    key={availableUser.id}
                    data-testid="available-user"
                    className="cursor-pointer border-b border-b-grey-lighter last:border-b-0 odd:bg-grey-lightest hover:bg-aop-basic-blue-lightest"
                  >
                    <Link to={routes.users.details({ slug, id: availableUser.id })}>
                      <div className="p-4">
                        <div className="flex grow items-center gap-3">
                          <div className="size-10 shrink-0">
                            <UserAvatar img={availableUser?.avatar} />
                          </div>
                          <div className="text-sm">
                            <span className="font-semibold text-black">{availableUser.fullName}</span>
                            <p className="text-grey">
                              <span>{availableUser.locatedAt}</span>
                            </p>
                          </div>
                        </div>
                      </div>
                    </Link>
                  </li>
                ))}
                {isMoreUsersAvailable && (
                  <li className="p-4" ref={ref}>
                    <LoadingIcon className="inset-0 mx-auto my-4 w-6" />
                  </li>
                )}
              </ul>
            )}
            <Modal.Actions>
              <Button
                data-testid="create-chat-group-btn"
                styling="primary"
                className="w-full"
                onClick={() => handleNextStep(1)}
                icon={<Icon name={usersIcon} />}
              >
                {t("page.chats.modal.new-chat.group-chat.button")}
              </Button>
            </Modal.Actions>
          </>
        )}
        {step === Step.CreateGroupChatSelectMembers && (
          <>
            {/* Search */}
            <SearchInput
              data-testid="search-input"
              onChange={(e) => setQuery(e.target.value.trim())}
              placeholder={
                sessionUser.project.type === "addressBased"
                  ? t("page.chats.modal.new-chat.input-placeholder.address-based")
                  : t("page.chats.modal.new-chat.input-placeholder.company-based")
              }
            />
            {selectedUsers.length > 0 && (
              <div className="flex h-fit max-w-full shrink-0 gap-6 overflow-x-auto rounded-lg border border-grey-lighter bg-grey-lightest p-4">
                {selectedUsers.map((user) => (
                  <div key={user.id} className="flex flex-col items-center gap-2 text-center">
                    <UserAvatar
                      key={user.id}
                      img={user.avatar}
                      showDeleteButton
                      onDelete={() =>
                        formMethods.setValue(
                          "groupMembers",
                          selectedUsers.filter((x) => x.id !== user.id),
                        )
                      }
                      className="size-14"
                    />
                    <Overline2>{user.firstName}</Overline2>
                  </div>
                ))}
              </div>
            )}
            <Capture1>{t("page.chats.modal.new-chat.total-results", { count: amountTotalUsers })}</Capture1>
            {/* Loading state */}
            {isLoadingUsers && <LoadingIcon className="w-6" />}
            {/* Empty state */}
            {!isLoadingUsers && amountTotalUsers === 0 && (
              <Capture2 className="text-grey-dark" as="p">
                {t("page.chats.modal.new-chat.no-results")}
              </Capture2>
            )}
            {/* Contentful state */}
            {!isLoadingUsers && availableUsers.length > 0 && (
              <ul className="h-64 w-full overflow-y-auto rounded-lg border border-grey-lighter">
                {availableUsers.map((user) => {
                  const isUserSelected = !!selectedUsers.find((selectedUser) => selectedUser.id === user.id);

                  return (
                    <li
                      key={user.id}
                      data-testid="available-user"
                      className="cursor-pointer border-b border-b-grey-lighter last:border-b-0 odd:bg-grey-lightest hover:bg-aop-basic-blue-lightest"
                    >
                      <label
                        htmlFor={`user-${user.id}`}
                        className="flex w-full cursor-pointer items-center justify-between p-4"
                      >
                        <div className="flex grow items-center gap-3">
                          <div className="size-10 shrink-0">
                            <UserAvatar img={user.avatar} />
                          </div>
                          <div className="text-sm">
                            <span className="font-semibold text-black">{user.fullName}</span>
                            <p className="text-grey">
                              <span>{user.locatedAt}</span>
                            </p>
                          </div>
                        </div>
                        <Checkbox
                          id={`user-${user.id}`}
                          name="groupMembers"
                          checked={isUserSelected}
                          onChange={(e) => onToggleSelectUser(e.target.checked, user)}
                          shape="rounded"
                          color="green"
                        />
                      </label>
                    </li>
                  );
                })}
                {isMoreUsersAvailable && (
                  <div className="p-4" ref={ref}>
                    <LoadingIcon className="inset-0 mx-auto my-4 w-6" />
                  </div>
                )}
              </ul>
            )}
            <Modal.Actions>
              <Button styling="secondary" onClick={() => handlePrevStep(0)}>
                {t("common.action.back")}
              </Button>
              <Button
                data-testid="next-step-btn"
                styling="primary"
                disabled={selectedUsers.length === 0}
                onClick={() => handleNextStep(2)}
              >
                {t("common.action.next")}
              </Button>
            </Modal.Actions>
          </>
        )}
        {step === Step.CreateGroupChatConfirm && (
          <>
            <div className="flex flex-col gap-2">
              <span className="self-center">
                <FormField label={t("component.group-chat-info-modal.form.image.label")} htmlFor="groupImage" hideLabel>
                  <FormImageInput<CreateChatFormValues, "groupImage">
                    name="groupImage"
                    rules={{
                      validate: {
                        size(images) {
                          const filteredImages = images ? images.filter((image) => Boolean(image)) : [];

                          if (filteredImages.length > 0) {
                            return validateSize(t, filteredImages);
                          }
                        },
                      },
                    }}
                    nOfImages={1}
                    isAvatarInput
                  />
                </FormField>
              </span>
              <FormField label={t("page.chats.modal.new-chat.group.group-name")} required>
                <FormInput<CreateChatFormValues, "groupName">
                  name="groupName"
                  placeholder={t("page.chats.modal.new-chat.group.group-name.placeholder")}
                  rules={{
                    validate: {
                      required: createRequiredStringRule(t, "page.chats.modal.new-chat.group.group-name"),
                    },
                    minLength: {
                      message: t("components.form.error.min-length", {
                        length: GROUP_NAME_MIN_LENGTH,
                      }),
                      value: GROUP_NAME_MIN_LENGTH,
                    },
                    maxLength: {
                      message: t("components.form.error.max-length", { length: GROUP_NAME_MAX_LENGTH }),
                      value: GROUP_NAME_MAX_LENGTH,
                    },
                  }}
                />
              </FormField>
            </div>
            <div className="w-full flex-col gap-2">
              <Capture1>
                {t("page.chats.modal.new-chat.group.selected-members", { count: selectedUsers.length })}
              </Capture1>
              <div className="grid size-full grid-cols-6 overflow-y-auto rounded-lg border border-grey-lighter bg-grey-lightest p-4">
                {selectedUsers.map((user) => (
                  <div key={user.id} className="flex flex-col items-center gap-2 text-center">
                    <UserAvatar
                      key={user.id}
                      img={user.avatar}
                      showDeleteButton
                      onDelete={() =>
                        formMethods.setValue(
                          "groupMembers",
                          selectedUsers.filter((x) => x.id !== user.id),
                        )
                      }
                      className="size-14"
                    />
                    <Overline2>{user.firstName}</Overline2>
                  </div>
                ))}
              </div>
            </div>
            <Modal.Actions>
              <Button styling="secondary" onClick={() => handlePrevStep(1)}>
                {t("common.action.back")}
              </Button>
              <Button type="submit" styling="primary" disabled={selectedUsers.length === 0} isLoading={isSubmitting}>
                {t("common.action.create")}
              </Button>
            </Modal.Actions>
          </>
        )}
      </Form>
    </Modal.Root>
  );
}
