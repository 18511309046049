import { useImageResolver } from "api/hooks/useImageResolver";
import type { CommunityFeedItemV2Dto, CommunityGroupV2Dto, MessageFeedStatsDto, SelfDto } from "api/types";
import arrowNarrowUpIcon from "assets/icons/arrow-narrow-up.svg";
import CheckIcon from "assets/icons/check.svg";
import iconFilterFunnel01 from "assets/icons/filter-funnel-01.svg";
import iconPlus from "assets/icons/plus.svg";
import iconX from "assets/icons/x.svg";
import { AIButton } from "components/Button/AIButton";
import { Button } from "components/Button/Button";
import { IconButton } from "components/Button/IconButton";
import { CheckboxMultiSelect } from "components/CheckboxMultiSelect/CheckboxMultiSelect";
import { ConfirmModal } from "components/ConfirmModal/ConfirmModal";
import { ContentTabs, type Tab } from "components/ContentTabs/ContentTabs";
import type { ContextMenuAction } from "components/ContextMenu/ContextMenu";
import { ContextMenu } from "components/ContextMenu/ContextMenu";
import { DateAndTimePicker } from "components/DateAndTimePicker/DateAndTimePicker";
import { DateRange } from "components/DateRange/DateRange";
import { FormattedDate } from "components/FormattedDate/FormattedDate";
import { FullSizeLoader } from "components/FullSizeLoader/FullSizeLoader";
import { Icon } from "components/Icon/Icon";
import { LoadingIcon } from "components/Icons/Icons";
import { DocumentPaper } from "components/Paper/DocumentPaper";
import { PillButton } from "components/PillButton/PillButton";
import { SearchInput } from "components/SearchInput/SearchInput";
import { Select } from "components/Select/Select";
import { Capture1, Capture2 } from "components/Text/Text";
import { addDays, endOfDay, format, parse, startOfDay } from "date-fns";
import { useAIToolingManager } from "hooks/useAIToolingManager";
import { useBool } from "hooks/useBool";
import { useKey } from "hooks/useKey";
import { useOnIntersection } from "hooks/useOnIntersection";
import { usePermission } from "hooks/usePermission";
import { useScrollToPageTop } from "hooks/useScrollToPageTop";
import { useSignalRHub, useSignalRInvocation, useSignalRSubscription } from "hooks/useSignalR";
import { useSlug } from "hooks/useSlug";
import { debounce } from "lodash-es";
import { GroupIcon } from "modules/community-groups/components/GroupIcons";
import { AskAiModal } from "modules/messages/components/AskAiModal";
import { MessagesSentimentModal } from "modules/messages/components/MessagesSentimentModal";
import { ResidentPostType } from "modules/messages/constants";
import { canCreateMessage, canPostInMarketplace } from "modules/messages/permissions";
import { usePostHog } from "posthog-js/react";
import type { ChangeEvent } from "react";
import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { routes } from "routes";
import { twJoin } from "tailwind-merge";

import { CommunityPoll } from "./components/CommunityPoll";
import { CommunityPost } from "./components/CommunityPost/CommunityPost";
import { CommunitySurvey } from "./components/CommunitySurvey";
import { ResidentCreatePostModal } from "./components/ResidentCreatePost";
import type { MessageFeedParams, OnUpdateFilters } from "./Loader";

export interface LayoutProps {
  sessionUser: SelfDto;
  groups: CommunityGroupV2Dto[];
  isLoadingGroups: boolean;
  stats: MessageFeedStatsDto | undefined;
  messages: CommunityFeedItemV2Dto[];
  hasMoreMessages: boolean | undefined;
  totalMessages: number;
  fetchMoreMessages: () => void;
  refetchMessages: () => void;
  markAllMessagesAsRead: () => void;
  isLoadingMessages: boolean;
  isLoadingMoreMessages: boolean;
  filters: MessageFeedParams;
  onUpdateFilters: OnUpdateFilters;
}

export function Layout({
  sessionUser,
  onUpdateFilters,
  filters,
  groups,
  stats,
  messages,
  totalMessages,
  hasMoreMessages,
  fetchMoreMessages,
  refetchMessages,
  markAllMessagesAsRead,
  isLoadingMessages,
  isLoadingMoreMessages,
}: LayoutProps): React.ReactNode {
  const postHog = usePostHog();
  const slug = useSlug();
  const hasPermission = usePermission();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const scrollToTop = useScrollToPageTop();
  const [residentPostType, setResidentPostType] = useState<ResidentPostType | null>(null);
  const [isFiltersOpen, filtersOpenHandlers] = useBool();
  const [isMarkAsReadModalOpen, markAsReadModalOpenHandlers] = useBool();
  const [isNewMessageButtonShown, newMessageButtonShowHandler] = useBool(false);
  const [isSentimentModalOpen, sentimentModalOpenHandlers] = useBool(false);
  const [isAskAiModalOpened, askAiModalOpenHandlers] = useBool(false);
  const { isAvailable: isAIToolingAvailable } = useAIToolingManager();

  const { signalRConnection } = useSignalRHub("community-feed-hub", { query: `userId=${sessionUser.id}` });
  const { invoke: onJoinGroup } = useSignalRInvocation(signalRConnection, "JoinGroup");

  const onNewItemOnFeed = useCallback(
    (...args: [{ entityId: string; entityType: "message" | "comment" | "poll" | "survey"; authorId: string }]) => {
      function isFiltersEmpty(): boolean {
        return (
          !filters.Query &&
          filters.Connection === "included" &&
          !filters.StartDate &&
          !filters.EndDate &&
          (!filters.GroupIds || filters.GroupIds?.length === 0)
        );
      }

      if (args[0].authorId === sessionUser.id) {
        return;
      }

      if ((filters.Tab === "myFeed" || filters.Tab === "all") && isFiltersEmpty()) {
        newMessageButtonShowHandler.setTrue();
      }
    },
    [sessionUser, filters, newMessageButtonShowHandler],
  );
  useSignalRSubscription(signalRConnection, "NewItemOnFeed", onNewItemOnFeed);
  useKey("Escape", filtersOpenHandlers.setFalse, isFiltersOpen);

  const ref = useOnIntersection({
    threshold: 0,
    onIntersect: useCallback(() => {
      if (!isLoadingMoreMessages && hasMoreMessages) {
        fetchMoreMessages();
      }
    }, [fetchMoreMessages, hasMoreMessages, isLoadingMoreMessages]),
  });

  const resolveImage = useImageResolver();

  const visibleFilters = useMemo(() => {
    const tempFilters = [];

    if (filters.EndDate && filters.StartDate) {
      tempFilters.push({
        key: "daterange",
        value: (
          <span>
            {t("page.message-feed.filter-bar.date")}:{" "}
            <DateRange className="inline-block" start={filters.StartDate} end={filters.EndDate} format="noTime" />
          </span>
        ),
        onRemove: () => {
          onUpdateFilters("EndDate", undefined);
          onUpdateFilters("StartDate", undefined);
        },
      });
    } else if (filters.StartDate) {
      tempFilters.push({
        key: "startdate",
        value: (
          <span>
            {t("page.message-feed.filter-bar.date-from")}:{" "}
            <FormattedDate key="startdate" date={filters.StartDate} format="date" />
          </span>
        ),
        onRemove: () => onUpdateFilters("StartDate", undefined),
      });
    } else if (filters.EndDate) {
      tempFilters.push({
        key: "enddate",
        value: (
          <span>
            {t("page.message-feed.filter-bar.date-to")}:{" "}
            <FormattedDate key="enddate" date={filters.EndDate} format="date" />
          </span>
        ),
        onRemove: () => onUpdateFilters("EndDate", undefined),
      });
    }

    if (filters.GroupIds) {
      for (const id of filters.GroupIds) {
        tempFilters.push({
          key: `group-${id}`,
          value: groups.find((x) => x.id === id)?.name || id,
          onRemove: () =>
            onUpdateFilters(
              "GroupIds",
              filters.GroupIds?.filter((x) => x !== id),
            ),
        });
      }
    }

    if (filters.Connection !== "included") {
      tempFilters.push({
        key: "connection",
        value:
          filters.Connection === "excluded"
            ? t("page.message-feed.filter-bar.only-in-project")
            : t("page.message-feed.filter-bar.only-in-connection"),
        onRemove: () => onUpdateFilters("Connection", "included"),
      });
    }

    if (filters.Query) {
      tempFilters.push({ key: "query", value: filters.Query, onRemove: () => onUpdateFilters("Query", "") });
    }

    return tempFilters;
  }, [
    filters.Connection,
    filters.EndDate,
    filters.GroupIds,
    filters.Query,
    filters.StartDate,
    groups,
    onUpdateFilters,
    t,
  ]);

  const onSearch = useMemo(
    () =>
      debounce((e: ChangeEvent<HTMLInputElement>) => {
        const MIN_SEARCH_CHARS = 2;

        const value = e.target.value.trim();
        if (value.length >= MIN_SEARCH_CHARS) {
          onUpdateFilters("Query", value);
        } else {
          onUpdateFilters("Query", "");
        }
      }, 500),
    [onUpdateFilters],
  );

  const onStartDateChange = useMemo(
    () => (date: string | undefined) => onUpdateFilters("StartDate", date),
    [onUpdateFilters],
  );

  const onEndDateChange = useMemo(
    () => (date: string | undefined) => onUpdateFilters("EndDate", date),
    [onUpdateFilters],
  );

  const residentPostCreationOptions = useMemo(() => {
    const actions: ContextMenuAction[] = [];

    if (hasPermission(canCreateMessage)) {
      actions.push({
        text: t("page.message-feed.resident-create-post.create-options.inform"),
        callback: () => {
          postHog?.capture("clicked_inform");
          setResidentPostType(ResidentPostType.Inform);
        },
      });
      actions.push({
        text: t("page.message-feed.resident-create-post.create-options.help"),
        callback: () => {
          postHog?.capture("clicked_ask_help");
          setResidentPostType(ResidentPostType.AskForHelp);
        },
      });
    }

    if (hasPermission(canPostInMarketplace)) {
      actions.push({
        text: t("page.message-feed.resident-create-post.create-options.marketplace"),
        callback: () => {
          postHog?.capture("clicked_marketplace");
          setResidentPostType(ResidentPostType.SellItem);
        },
      });
    }

    return actions;
  }, [t, postHog, hasPermission]);

  const from = startOfDay(addDays(new Date(), -14)).toISOString();
  const to = endOfDay(new Date()).toISOString();

  const tabs: Tab<Exclude<MessageFeedParams["Tab"], undefined>>[] = useMemo(() => {
    if (sessionUser.isAdmin) {
      return [
        {
          id: "all",
          name: t("page.message-feed.tabs.all"),
          count: stats?.allCount,
        },
        {
          id: "announcements",
          name: t("page.message-feed.tabs.announcements"),
          count: stats?.announcementsCount,
        },
        {
          id: "own",
          name: t("page.message-feed.tabs.mine"),
          count: stats?.myPostsCount,
        },
      ] as const;
    }

    return [
      {
        id: "myFeed",
        name: t("page.message-feed.tabs.my-feed"),
        count: stats?.allCount,
      },
      {
        id: "announcements",
        name: t("page.message-feed.tabs.announcements"),
        count: stats?.announcementsCount,
      },
      {
        id: "all",
        name: t("page.message-feed.tabs.all"),
        count: undefined,
      },
    ] as const;
  }, [sessionUser.isAdmin, stats?.allCount, stats?.announcementsCount, stats?.myPostsCount, t]);

  const onClickAskAI = () => {
    postHog?.capture("clicked_community_feed_ask_ai");

    askAiModalOpenHandlers.setTrue();
  };

  const onClickSentimentAnalysis = () => {
    postHog?.capture("clicked_community_feed_sentiment_analysis");

    sentimentModalOpenHandlers.setTrue();
  };

  const onClickMarkAllAsRead = () => {
    postHog?.capture("clicked_maar_community_feed");

    markAsReadModalOpenHandlers.setTrue();
  };

  return (
    <DocumentPaper
      theme="minimal"
      title={t("page.message-feed.title")}
      subTitle={t("page.message-feed.subtitle")}
      actions={
        <>
          {sessionUser.isAdmin && isAIToolingAvailable && (
            <>
              <AIButton onClick={onClickAskAI}>{t("page.message-feed.ask-ai.btn")}</AIButton>
              <AIButton onClick={onClickSentimentAnalysis}>{t("page.message-feed.sentiment.btn")}</AIButton>
            </>
          )}
          {hasPermission(canCreateMessage) && sessionUser.isAdmin && (
            <>
              <Button
                type="link"
                className="hidden lg:flex"
                data-testid="create-message"
                href={routes.messageFeed.adminCreatePost({ slug })}
              >
                <span>{t("page.message-feed.button.create.post")}</span>
              </Button>
              <IconButton
                styling="primary"
                className="lg:hidden"
                title={t("page.message-feed.button.create.post")}
                onClick={() => navigate(routes.messageFeed.adminCreatePost({ slug }))}
                isCircular
              >
                <Icon name={iconPlus} size={24} />
              </IconButton>
            </>
          )}
          {hasPermission(canCreateMessage) && !sessionUser.isAdmin && (
            <ContextMenu actions={residentPostCreationOptions}>
              {(props) => (
                <>
                  <Button
                    className="hidden lg:flex"
                    data-testid="create-message"
                    onClick={props.openHandlers.toggle}
                    isPressed={props.isOpen}
                  >
                    <span>{t("page.message-feed.button.create.post")}</span>
                  </Button>
                  <IconButton
                    styling="primary"
                    className="md:hidden"
                    title={t("page.message-feed.button.create.post")}
                    onClick={props.openHandlers.toggle}
                    isPressed={props.isOpen}
                    isCircular
                  >
                    <Icon name={iconPlus} size={24} />
                  </IconButton>
                </>
              )}
            </ContextMenu>
          )}
        </>
      }
      header={
        <div className="flex flex-col gap-3 py-4 lg:py-2">
          {/* Desktop */}
          <div className="hidden items-center lg:flex lg:justify-between">
            <div className="flex items-center gap-3">
              <SearchInput
                className="w-80"
                defaultValue={filters.Query}
                placeholder={t("page.message-feed.filters.search.label")}
                onChange={onSearch}
              />
              <Button
                icon={<Icon name={iconFilterFunnel01} size={16} />}
                styling="secondary"
                onClick={filtersOpenHandlers.toggle}
              >
                {t("page.tickets.header.button.filter")}
              </Button>
            </div>

            <Button styling="secondary" icon={<Icon name={CheckIcon} size={16} />} onClick={onClickMarkAllAsRead}>
              {t("page.message-feed.mark-all-as-read")}
            </Button>
          </div>
          {/* Mobile */}
          <div className="flex w-full flex-col items-stretch gap-4 lg:hidden">
            <SearchInput
              defaultValue={filters.Query}
              placeholder={t("page.message-feed.filters.search.label")}
              onChange={onSearch}
            />
            <div className="flex gap-4">
              <Button styling="secondary" onClick={filtersOpenHandlers.toggle}>
                {t("page.message-feed.filters.title")}
              </Button>
              <Button styling="secondary" icon={<Icon name={CheckIcon} />} onClick={onClickMarkAllAsRead}>
                {t("page.message-feed.mark-all-as-read")}
              </Button>
            </div>
          </div>

          {isFiltersOpen ? (
            <div className="flex flex-col gap-6">
              <div className="flex w-full flex-wrap items-center gap-6">
                {sessionUser.connections.length > 0 && hasPermission((x) => x.community.canListGeneralPosts) ? (
                  <Select
                    placeholder={t("page.calendar.tabs.events.filter.type.placeholder")}
                    renderOption={(opt) => {
                      switch (opt) {
                        case "included":
                          return t("page.message-feed.filters.on-project-connection.all");
                        case "only":
                          return t("page.message-feed.filters.on-project-connection.in-your-connection");
                        case "excluded":
                          return t("page.message-feed.filters.on-project-connection.in-your-project");
                      }
                    }}
                    keySelector={(opt) => opt}
                    items={["included", "only", "excluded"]}
                    selected={filters.Connection ?? "included"}
                    onChange={(x) => onUpdateFilters("Connection", x as "included" | "only" | "excluded")}
                  />
                ) : null}
                <div className="max-w-96">
                  <DateAndTimePicker
                    placeholder={t("page.message-feed.filters.on-date.start-date")}
                    value={filters.StartDate ? parse(filters.StartDate, "yyyy-MM-dd", new Date()) : ""}
                    onChange={(value) => onStartDateChange(value ? format(value, "yyyy-MM-dd") : undefined)}
                    min={new Date("1753-01-01T12:00:00")}
                    max={
                      filters.EndDate
                        ? parse(filters.EndDate, "yyyy-MM-dd", new Date())
                        : new Date("9999-12-31T11:59:59")
                    }
                  />
                </div>
                <div className="max-w-80">
                  <DateAndTimePicker
                    placeholder={t("page.message-feed.filters.on-date.end-date")}
                    value={filters.EndDate ? parse(filters.EndDate, "yyyy-MM-dd", new Date()) : ""}
                    onChange={(value) => onEndDateChange(value ? format(value, "yyyy-MM-dd") : undefined)}
                    min={
                      filters.StartDate
                        ? parse(filters.StartDate, "yyyy-MM-dd", new Date())
                        : new Date("1753-01-01T12:00:00")
                    }
                    max={new Date("9999-12-31T11:59:59")}
                  />
                </div>
                <div className="w-80">
                  <CheckboxMultiSelect<CommunityGroupV2Dto>
                    placeholder={t("page.message-feed.filters.on-group")}
                    selected={
                      filters.GroupIds
                        ? groups
                            .filter((x) => x.type === "interest" || x.type === "realEstate")
                            .filter((x) => filters.GroupIds!.includes(x.id))
                        : []
                    }
                    items={groups.filter((x) => x.type === "interest" || x.type === "realEstate")}
                    onChange={(x) =>
                      onUpdateFilters(
                        "GroupIds",
                        filters.GroupIds
                          ? [
                              ...groups
                                .filter((g) => g.type === "helpCategory")
                                .filter((g) => filters.GroupIds!.includes(g.id))
                                .map((g) => g.id),
                              ...x.map((x) => x.id),
                            ]
                          : x.map((x) => x.id),
                      )
                    }
                    keySelector={(x) => x.id}
                    renderOption={(x) => (
                      <div className="flex items-center gap-2">
                        {x.isResidentGroup && x.image ? (
                          <img src={resolveImage(x.image, "sm")} alt={x.name} className="size-6 rounded-full" />
                        ) : (
                          <span className="size-6 p-1">
                            <GroupIcon icon={x.icon} size={16} />
                          </span>
                        )}
                        <span>{x.name}</span>
                      </div>
                    )}
                    renderValue={(value) => (
                      <span className="block max-w-72 truncate pr-5">{value.map((x) => x.name).join(", ")}</span>
                    )}
                  />
                </div>
                <div className="w-80">
                  <CheckboxMultiSelect<CommunityGroupV2Dto>
                    placeholder={t("page.message-feed.filters.on-help-category")}
                    selected={
                      filters.GroupIds
                        ? groups
                            .filter((x) => x.type === "helpCategory")
                            .filter((x) => filters.GroupIds!.includes(x.id))
                        : []
                    }
                    items={groups.filter((x) => x.type === "helpCategory")}
                    onChange={(x) =>
                      onUpdateFilters(
                        "GroupIds",
                        filters.GroupIds
                          ? [
                              ...groups
                                .filter((g) => g.type === "interest" || g.type === "realEstate")
                                .filter((g) => filters.GroupIds!.includes(g.id))
                                .map((g) => g.id),
                              ...x.map((x) => x.id),
                            ]
                          : x.map((x) => x.id),
                      )
                    }
                    keySelector={(x) => x.id}
                    renderOption={(x) => (
                      <div className="flex items-center gap-2">
                        {x.isResidentGroup && x.image ? (
                          <img src={resolveImage(x.image, "sm")} alt={x.name} className="size-6 rounded-full" />
                        ) : (
                          <span className="size-6 p-1">
                            <GroupIcon icon={x.icon} size={16} />
                          </span>
                        )}
                        <span>{x.name}</span>
                      </div>
                    )}
                    renderValue={(value) => (
                      <span className="block max-w-72 truncate pr-5">{value.map((x) => x.name).join(", ")}</span>
                    )}
                  />
                </div>
              </div>
              <div className="self-center justify-self-end">
                <Button
                  icon={<Icon name={iconX} size={16} />}
                  styling="tertiary"
                  onClick={filtersOpenHandlers.setFalse}
                >
                  {t("page.calendar.tabs.events.filter.close")}
                </Button>
              </div>
            </div>
          ) : null}
        </div>
      }
    >
      <div className="flex gap-8">
        <div className="flex w-full flex-col">
          {visibleFilters.length > 0 ? (
            <div className="mb-4 flex flex-wrap gap-4">
              {visibleFilters.map(({ key, value, onRemove }) => (
                <div className="flex items-center gap-2 rounded-full bg-white p-2.5 px-4 text-grey-darkest" key={key}>
                  <Capture2>{value}</Capture2>
                  <IconButton
                    styling="tertiary"
                    size="sm"
                    className="rounded-full"
                    title={t("page.message-feed.filters.remove")}
                    onClick={onRemove}
                  >
                    <Icon name={iconX} size={16} />
                  </IconButton>
                </div>
              ))}
            </div>
          ) : null}

          <ContentTabs
            tabs={tabs}
            activeTabId={filters.Tab || "all"}
            onTabChange={(tab) => onUpdateFilters("Tab", tab)}
          >
            <div className="flex items-center justify-between p-4">
              <Capture2>{t("page.message-feed.total-messages", { count: totalMessages })}</Capture2>
            </div>
          </ContentTabs>

          {isLoadingMessages ? (
            <FullSizeLoader withPadding />
          ) : (
            <div className="relative mt-4 max-w-4xl">
              {isNewMessageButtonShown && (
                <PillButton
                  className="sticky top-4 mx-auto"
                  onClick={() => {
                    refetchMessages();
                    newMessageButtonShowHandler.setFalse();
                    scrollToTop();
                  }}
                >
                  <Icon name={arrowNarrowUpIcon} size={16} />
                  <Capture1>{t("page.message-feed.button.new-messages")}</Capture1>
                </PillButton>
              )}
              <ul className={twJoin("relative flex flex-col gap-5", isNewMessageButtonShown && "-top-9")}>
                {messages.map((message) =>
                  message.type === "message" && message.message ? (
                    <li key={message.message.id} data-testid="community-post">
                      <CommunityPost message={message.message} isExpanded={false} onJoinGroup={onJoinGroup} />
                    </li>
                  ) : message.type === "poll" && message.poll ? (
                    <li key={message.poll.id} data-testid="poll">
                      <CommunityPoll poll={message.poll} />
                    </li>
                  ) : message.type === "survey" && message.survey ? (
                    <li key={message.survey.id} data-testid="survey">
                      <CommunitySurvey survey={message.survey} updatedAt={message.updatedAt} />
                    </li>
                  ) : null,
                )}
              </ul>
              {hasMoreMessages && (
                <div className="p-4" ref={ref}>
                  <LoadingIcon className="inset-0 mx-auto my-4 w-6" />
                </div>
              )}
            </div>
          )}
        </div>
      </div>
      <ConfirmModal
        id="mark-all-read-modal"
        title={t("component.community-post.mark-all-as-read.modal.title")}
        description={t("component.community-post.mark-all-as-read.modal.description")}
        isLoading={false}
        onOpenChange={markAsReadModalOpenHandlers.set}
        onReject={markAsReadModalOpenHandlers.setFalse}
        rejectBtnProps={{
          "data-testid": "mark-all-read-modal-cancel",
        }}
        onResolve={() => {
          markAllMessagesAsRead();
          markAsReadModalOpenHandlers.setFalse();
        }}
        resolveBtnProps={{
          text: t("common.action.confirm"),
          "data-testid": "mark-all-read-modal-confirm",
        }}
        isOpen={isMarkAsReadModalOpen}
        shouldCloseOnEsc
        data-testid="mark-all-read-modal"
      />
      {isAIToolingAvailable && (
        <>
          <MessagesSentimentModal
            startDate={from}
            endDate={to}
            isOpen={isSentimentModalOpen}
            onOpenChange={sentimentModalOpenHandlers.set}
          />
          <AskAiModal isOpened={isAskAiModalOpened} onOpenChange={askAiModalOpenHandlers.set} />
        </>
      )}
      <ResidentCreatePostModal
        isOpened={!!residentPostType}
        onOpenChange={(state) => {
          if (!state) {
            setResidentPostType(null);
          }
        }}
        postType={residentPostType}
      />
    </DocumentPaper>
  );
}
