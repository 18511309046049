import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useApi } from "api/hooks/useApi";
import type { PollDto } from "api/types";
import iconCheck from "assets/icons/check.svg";
import iconClock from "assets/icons/clock.svg";
import iconEyeOff from "assets/icons/eye-off.svg";
import iconMarkerPin01 from "assets/icons/marker-pin-01.svg";
import iconStar01 from "assets/icons/star-01.svg";
import iconTrash02 from "assets/icons/trash-02.svg";
import { Anchor } from "components/Anchor/Anchor";
import { Button } from "components/Button/Button";
import { ConfirmModal } from "components/ConfirmModal/ConfirmModal";
import { useFlashToast } from "components/FlashToast/FlashToast";
import { FormattedDate } from "components/FormattedDate/FormattedDate";
import { formatDistance } from "components/FormattedDistance/FormattedDistance";
import { Icon } from "components/Icon/Icon";
import { Label } from "components/Label/Label";
import { LinkFormatter } from "components/LinkFormatter/LinkFormatter";
import { Pdf } from "components/Pdf/Pdf";
import { Capture2, Headline4 } from "components/Text/Text";
import { parseISO } from "date-fns";
import { getDeepLinkUrl } from "helpers/deep-link";
import { isDefined } from "helpers/util";
import { useProjectId } from "hooks/Network/useProjectId";
import { useSessionUser } from "hooks/Network/useSessionUser";
import { useBool } from "hooks/useBool";
import { sumBy } from "lodash-es";
import { useViewportCollision } from "modules/messages/helpers";
import { QUERY_KEYS } from "query-keys";
import { useMemo, useRef } from "react";
import { useTranslation } from "react-i18next";
import { twJoin } from "tailwind-merge";

import { CommunityItem } from "./CommunityItem";
import { CommunityItemGallery } from "./CommunityItemGallery";

interface CommunityPollProps {
  poll: PollDto;
}

export function CommunityPoll({ poll }: CommunityPollProps): React.ReactNode {
  const projectId = useProjectId();
  const api = useApi();
  const queryClient = useQueryClient();
  const { t, i18n } = useTranslation();
  const sessionUser = useSessionUser();
  const showFlashToast = useFlashToast();
  const refContainer = useRef<HTMLDivElement | null>(null);
  const hasBeenInViewport = useViewportCollision(refContainer);

  const [isDeleteModalOpen, deleteModalHandler] = useBool(false);
  const [isAppPollModalOpen, appPollModalHandler] = useBool(false);

  const markAsRead = useMutation({
    mutationFn: () => api.postPollsReadV1(poll.id),
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: [...QUERY_KEYS.MESSAGES(projectId), "FEED"],
      });
      await queryClient.invalidateQueries({ queryKey: QUERY_KEYS.MESSAGES_FEED_STATS(projectId) });
    },
    onError: () => {
      showFlashToast({ type: "error", title: t("component.community-poll.mark-as-read.error") });
    },
  });

  const deletePoll = useMutation({
    mutationFn: () => api.deletePollsByIdV1(poll.id),
    onSuccess: async () => {
      showFlashToast({ type: "success", title: t("component.community-poll.delete.success") });
      await queryClient.invalidateQueries({
        queryKey: [...QUERY_KEYS.MESSAGES(projectId), "FEED"],
      });
    },
    onError: () => {
      showFlashToast({ type: "error", title: t("component.community-poll.delete.error") });
    },
  });

  const actions = useMemo(() => {
    const actions = [];

    if (!poll || !!poll.deletedAt) return [];

    if (poll.isUnread) {
      actions.push({
        dataTestId: "post-context-menu-mark-read",
        text: t("component.community-post.actions.mark-as-read"),
        icon: <Icon name={iconCheck} size={16} />,
        callback: markAsRead.mutateAsync,
      });
    }

    if (poll.canDelete) {
      actions.push({
        dataTestId: "context-menu-delete-btn",
        text: t("component.community-poll.actions.delete"),
        icon: <Icon name={iconTrash02} size={16} />,
        callback: deleteModalHandler.toggle,
      });
    }

    return actions;
  }, [poll, t, deleteModalHandler.toggle, markAsRead]);

  if (!poll) {
    return null;
  }

  const onClickPollButton = async () => {
    await markAsRead.mutateAsync();
  };

  const total = sumBy(poll.results, (x) => x.count);

  return (
    <>
      <CommunityItem
        ring={poll.deletedAt ? "greyed-out" : poll.isUnread ? "unread" : undefined}
        user={poll.createdBy}
        group={poll.group}
        actions={actions}
        dateSubtitle={poll.createdAt ? <FormattedDate format="datetime" date={poll.createdAt} /> : undefined}
        warningHeaders={
          poll.isHiddenFromAdmins
            ? [
                {
                  icon: <Icon name={iconEyeOff} size={16} />,
                  text: t("component.community-post.hidden-from-property-manager"),
                },
              ]
            : undefined
        }
        labels={[
          poll.projectConnection ? (
            <Label theme="aopDarkBlue">
              <Icon name={iconMarkerPin01} size={16} />
              {poll.projectConnection.name}
            </Label>
          ) : null,
          poll.deletedAt ? <Label theme="red">{t("component.community-post.removed")}</Label> : undefined,
        ].filter(isDefined)}
        ref={refContainer}
      >
        <div className="flex flex-col gap-2 md:flex-row">
          <div className="flex flex-1 flex-col gap-2">
            {!!poll.deletedAt && !sessionUser.isSuperAdmin ? (
              <p className="whitespace-pre-line text-grey">{t("component.community-post.content.removed")}</p>
            ) : (
              <>
                <p className={twJoin("whitespace-pre-line font-semibold", poll.deletedAt && "text-grey line-through")}>
                  {poll.title}
                </p>
                <p className={poll.deletedAt ? "text-grey line-through" : "text-grey-darkest"}>
                  <LinkFormatter>{poll.description}</LinkFormatter>
                </p>
                <div className="flex flex-col gap-4 lg:w-1/2">
                  {/* Gallery */}
                  <CommunityItemGallery images={poll.images} shouldLoad={hasBeenInViewport} />
                  {/* Poll documents */}
                  {poll.documents && poll.documents.length > 0 && (
                    <div className="flex flex-wrap gap-2">
                      {poll.documents.map((x) => (
                        <Pdf
                          key={x.id}
                          previewImage={x.previewImage}
                          fileName={x.fileName}
                          fileSize={x.fileSize}
                          onClick={() => window.open(x.url, "_blank")}
                        />
                      ))}
                    </div>
                  )}
                  {/* Poll results */}
                  <div className="flex w-full flex-col gap-4">
                    <div className="flex w-fit items-center gap-1 rounded-md bg-aop-basic-blue-lightest px-2 py-1 text-aop-basic-blue">
                      <Icon name={iconClock} size={16} />
                      {poll.endsAt && new Date(poll.endsAt) > new Date() ? (
                        <Capture2>{formatDistance(i18n, { start: new Date(), end: parseISO(poll.endsAt) })}</Capture2>
                      ) : (
                        <Capture2>{t("component.community-post.poll-ended")}</Capture2>
                      )}
                    </div>
                    <Headline4
                      className={twJoin("break-words", poll.deletedAt ? "text-grey line-through" : "text-grey-darkest")}
                    >
                      {poll.question}
                    </Headline4>
                    {poll.results ? (
                      <div className="flex flex-col gap-0.5">
                        {poll.results?.map((result) => (
                          <div
                            className="relative h-10 w-full overflow-hidden rounded-lg border border-grey-lightest"
                            key={result.answer.id}
                          >
                            <div className="flex w-full items-center justify-between p-2">
                              <div className="flex items-center gap-1">
                                <span>{result.answer.text}</span>
                                {result.hasWon && (
                                  <span className="text-yellow">
                                    <Icon name={iconStar01} size={16} />
                                  </span>
                                )}
                              </div>
                              <span className="font-semibold">{result.count}</span>
                            </div>
                            <div
                              className="absolute left-0 top-0 flex h-full items-center bg-grey-dark/20"
                              style={{ width: total === 0 ? 0 : `${(result.count / total) * 100}%` }}
                            />
                          </div>
                        ))}
                      </div>
                    ) : (
                      <>
                        <div className="flex flex-col gap-0.5">
                          {poll.answers.map((answer) => (
                            <div
                              className="relative h-10 w-full rounded-lg border border-grey-lightest"
                              key={answer.id}
                            >
                              <div className="flex w-full items-center justify-between p-2">
                                <span>{answer.text}</span>
                              </div>
                            </div>
                          ))}
                        </div>
                        <Button
                          type="link"
                          onClick={onClickPollButton}
                          className="w-full text-sm"
                          styling="primary"
                          href={getDeepLinkUrl(sessionUser.project.appBranding, "/answer-survey")}
                          target="_blank"
                          isExternal
                        >
                          {t("component.community-poll.button")}
                        </Button>
                      </>
                    )}
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
        <div className="-my-1 flex flex-row-reverse items-center justify-between gap-4 pb-2">
          {poll.updatedAt && (
            <span className="text-sm text-grey">
              {t("component.community-post.last-activity", {
                time: formatDistance(i18n, { start: new Date(poll.updatedAt) }),
              })}
            </span>
          )}
        </div>
      </CommunityItem>
      <ConfirmModal
        id="delete-modal"
        title={t("component.community-poll.delete.modal.title")}
        description={t("component.community-poll.delete.modal.description")}
        isLoading={false}
        theme="danger"
        onReject={deleteModalHandler.setFalse}
        onOpenChange={deleteModalHandler.set}
        rejectBtnProps={{
          "data-testid": "delete-modal-cancel",
        }}
        onResolve={() => {
          void deletePoll.mutateAsync();
          deleteModalHandler.setFalse();
        }}
        resolveBtnProps={{
          "data-testid": "modal-confirm-delete",
          text: t("common.action.delete"),
        }}
        isOpen={isDeleteModalOpen}
        shouldCloseOnEsc
        data-testid="cancel-edit-modal"
      />
      <ConfirmModal
        id="app-poll-modal"
        title={t("component.community-post.app-poll.modal.title")}
        description={t("component.community-post.app-poll.modal.description")}
        renderDescription={(description) => (
          <div className="flex flex-col items-center">
            <span>{description}</span>
            <Anchor to={t("component.community-post.app-poll.modal.link")}>
              {t("component.community-post.app-poll.modal.link")}
            </Anchor>
          </div>
        )}
        isLoading={false}
        theme="info"
        onResolve={appPollModalHandler.setFalse}
        onReject={appPollModalHandler.setFalse}
        onOpenChange={appPollModalHandler.set}
        rejectBtnProps={{ hide: true }}
        resolveBtnProps={{
          "data-testid": "app-poll-modal-confirm",
          text: t("common.action.ok"),
        }}
        isOpen={isAppPollModalOpen}
        shouldCloseOnEsc
        data-testid="cancel-app-poll-modal"
      />
    </>
  );
}
