import { useMutation } from "@tanstack/react-query";
import { useApi } from "api/hooks/useApi";
import { AIButton } from "components/Button/AIButton";
import { Button } from "components/Button/Button";
import { useFlashToast } from "components/FlashToast/FlashToast";
import { Form } from "components/Form/Form";
import { FormField } from "components/Form/FormField";
import { FormTextArea } from "components/Form/FormTextArea";
import type { ModalBaseProps } from "components/Modal/Modal";
import { Modal } from "components/Modal/Modal";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useLocation, useParams } from "react-router-dom";

type TicketCopilotProps = ModalBaseProps & {
  onGenerate: (message: string) => void;
  ticketId: string;
  defaultInput?: FormValues;
};

interface FormValues {
  input: string;
}

export function TicketAICopilotModal({
  isOpened,
  onOpenChange,
  onGenerate,
  ticketId,
  defaultInput,
}: TicketCopilotProps): React.ReactNode {
  const { t } = useTranslation();
  const form = useForm<FormValues>({ defaultValues: defaultInput });
  const api = useApi();
  const showFlashToast = useFlashToast();
  const { pathname } = useLocation();
  const { token } = useParams<{ token?: string }>();

  useEffect(() => {
    if (defaultInput) {
      form.reset(defaultInput);
    }
  }, [defaultInput, form]);

  const quickReplyTicketSuggestion = useMutation({
    mutationFn: (query: { additionalInfo: string } | undefined) =>
      api.postQuickReplyRepairRequestAiSuggestionV1(token!, query).then((res) => res.data),
    onError: () => {
      showFlashToast({ type: "error", title: t("page.tickets.details.copilot.generate.error") });
    },
  });

  const ticketSuggestion = useMutation({
    mutationFn: (query: { additionalInfo: string } | undefined) =>
      api.postTicketsSuggestionV1(ticketId, query).then((res) => res.data),
    onError: () => {
      showFlashToast({ type: "error", title: t("page.tickets.details.copilot.generate.error") });
    },
  });

  async function handleSubmit() {
    const input = form.getValues("input");

    let suggestion = "";
    if (pathname.includes("quick-repair-request") && Boolean(token)) {
      suggestion = await quickReplyTicketSuggestion
        .mutateAsync(input ? { additionalInfo: input } : undefined)
        .then((res) => res.response);
    } else {
      suggestion = await ticketSuggestion
        .mutateAsync(input ? { additionalInfo: input } : undefined)
        .then((res) => res.response);
    }

    onGenerate(suggestion);

    form.reset();
    onOpenChange(false);
  }

  return (
    <Modal.Root
      title={t("page.tickets.details.copilot.modal.title")}
      description={t("page.tickets.details.copilot.modal.description")}
      isOpened={isOpened}
      onOpenChange={onOpenChange}
      shouldCloseOnClickOutside={false}
      shouldCloseOnEsc={false}
    >
      <Form formMethods={form} onSubmit={handleSubmit}>
        <FormField label={t("page.tickets.details.copilot.modal.text-area.label")} htmlFor="input">
          <FormTextArea
            id="input"
            name="input"
            data-testid="ai-copilot-input"
            placeholder={t("page.tickets.details.copilot.modal.text-area.placeholder")}
          />
        </FormField>
        <Modal.Actions>
          <Button type="button" styling="secondary" onClick={() => onOpenChange(false)}>
            {t("common.action.cancel")}
          </Button>
          <AIButton
            data-testid="submit-ai-response"
            type="submit"
            isLoading={ticketSuggestion.isPending || quickReplyTicketSuggestion.isPending}
          >
            {t("page.tickets.details.copilot.modal.button")}
          </AIButton>
        </Modal.Actions>
      </Form>
    </Modal.Root>
  );
}
