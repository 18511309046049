import { LoadingIcon } from "components/Icons/Icons";
import { Modal, type ModalBaseProps } from "components/Modal/Modal";
import { ProjectLoader } from "providers/ProjectLoader";
import { SessionUserLoader } from "providers/SessionUserLoader";
import type React from "react";

import { PortfolioTicketDetails } from "../pages/PortfolioTicketDetails/PortfolioTicketDetails";

type PortfolioTicketDetailsModalProps = ModalBaseProps & {
  data?: {
    ticketId: string;
    projectId: string;
  };
  leftInSession: number | undefined;
  showNextTicketBtn: boolean;
  onNavigateNextTicket: () => void;
};

export function PortfolioTicketDetailsModal({
  isOpened,
  onOpenChange,
  data,
  leftInSession,
  showNextTicketBtn,
  onNavigateNextTicket,
}: PortfolioTicketDetailsModalProps): React.ReactNode {
  return (
    <Modal.Root size="fullscreen" isScrollable {...{ isOpened, onOpenChange }}>
      {data ? (
        <div className="flex [&>div]:p-0">
          <ProjectLoader projectId={data.projectId}>
            <SessionUserLoader>
              <PortfolioTicketDetails
                ticketId={data.ticketId}
                leftInSession={leftInSession}
                showNextTicketBtn={showNextTicketBtn}
                onGoToNextTicket={onNavigateNextTicket}
              />
            </SessionUserLoader>
          </ProjectLoader>
        </div>
      ) : (
        <div className="flex size-full items-center justify-center">
          <LoadingIcon className="size-12" />
        </div>
      )}
    </Modal.Root>
  );
}
