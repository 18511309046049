import { useImageResolver } from "api/hooks/useImageResolver";
import type { ImageDto } from "api/types";
import trashIcon from "assets/icons/trash-01.svg";
import iconUser01 from "assets/icons/user-01.svg";
import xIcon from "assets/icons/x-close.svg";
import { IconButton } from "components/Button/IconButton";
import { Icon } from "components/Icon/Icon";
import { twResolve } from "helpers/tw-resolve";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

export interface UserAvatarProps {
  img?: ImageDto | undefined;
  isUserDeleted?: boolean;
  hideDeletedIcon?: boolean;
  showDeleteButton?: boolean;
  onDelete?: () => void;
  className?: string;
  big?: boolean;
}

export function UserAvatar({
  img,
  isUserDeleted,
  hideDeletedIcon = false,
  showDeleteButton = false,
  onDelete,
  className,
  big,
}: UserAvatarProps): React.ReactNode {
  const { t } = useTranslation();
  const resolveImage = useImageResolver();
  const [errored, setErrored] = useState(!img);

  useEffect(() => {
    setErrored(!img);
  }, [img]);

  return (
    <div
      className={twResolve(
        "relative aspect-square size-full shrink-0 rounded-full object-cover",
        isUserDeleted && "opacity-40",
        className,
      )}
    >
      {img && !errored ? (
        <img
          className="relative size-full select-none rounded-full bg-white object-cover"
          src={resolveImage(img, big ? "md" : "sm")}
          alt="user avatar"
          loading="lazy"
          onError={() => setErrored(true)}
        />
      ) : (
        <div className="size-full rounded-full border border-grey-lightest bg-white">
          <Icon
            name={iconUser01}
            className="flex size-full items-center justify-center text-grey-lighter"
            size="62.5%"
          />
        </div>
      )}
      {!hideDeletedIcon && isUserDeleted && (
        <div className="absolute -left-1/6 -top-1/6 flex size-1/2 items-center justify-center rounded-full bg-red text-white shadow">
          <Icon name={trashIcon} />
        </div>
      )}
      {showDeleteButton && onDelete && (
        <IconButton
          title={t("common.action.delete")}
          onClick={onDelete}
          styling="danger"
          className="absolute right-0 top-0 flex size-6 -translate-y-1/4 translate-x-1/4 items-center justify-center rounded-full"
          withTooltip={false}
          size="sm"
        >
          <Icon name={xIcon} size={16} />
        </IconButton>
      )}
    </div>
  );
}
