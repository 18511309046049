import type { Row, Table as TableType } from "@tanstack/react-table";
import { flexRender } from "@tanstack/react-table";
import { FullSizeLoader } from "components/FullSizeLoader/FullSizeLoader";
import { TableSortIcon } from "components/Icons/Icons";
import { Subtitle2 } from "components/Text/Text";
import { twResolve } from "helpers/tw-resolve";
import { twJoin } from "tailwind-merge";

export interface TableProps<TColumnData extends {}> {
  table: TableType<TColumnData>;
  className?: string;
  "data-testid"?: string;
  getRowProps?: (row: Row<TColumnData>) => any;
  showSorting?: boolean;
  isLoading?: boolean;
  children?: React.ReactNode;
  onRowClick?: (row: Row<TColumnData>) => void;
  firstCellIsIndicator?: boolean;
  hideBorder?: boolean;
}

export function Table<TColumnData extends {}>({
  table,
  getRowProps,
  isLoading,
  children,
  showSorting,
  onRowClick,
  firstCellIsIndicator,
  hideBorder,
  className,
  ...props
}: TableProps<TColumnData>): React.ReactNode {
  const rows = table.getRowModel().rows;
  const hasRows = rows.length > 0;

  return (
    <div className="relative">
      {hasRows && isLoading ? (
        <div className="absolute inset-0">
          <FullSizeLoader />
        </div>
      ) : null}
      <table {...props} className={twResolve("m-0 w-full p-0 text-subtitle2 leading-subtitle2", className)}>
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr className="bg-white" key={headerGroup.id}>
              {headerGroup.headers.map((header) => {
                const isSorted = header.column.getIsSorted();
                const canSort = showSorting && header.column.getCanSort();

                return (
                  <th
                    className={twJoin(
                      "border-r border-grey-light/20 px-2 py-4 text-left align-bottom capitalize text-black first:rounded-tl-lg first:pl-3 last:rounded-tr-lg last:border-r-0 md:px-4 first:lg:pl-5 xl:px-6 first:xl:pl-8",
                      canSort ? "cursor-pointer hocus:bg-grey-lightest" : undefined,
                      firstCellIsIndicator ? "first:px-0" : undefined,
                      hideBorder ? "border-r-0" : undefined,
                    )}
                    key={header.id}
                    colSpan={header.colSpan}
                    style={{
                      width: header.column.columnDef.size,
                      minWidth: header.column.columnDef.minSize,
                      maxWidth: header.column.columnDef.maxSize,
                    }}
                    onClick={header.column.getToggleSortingHandler()}
                  >
                    {header.isPlaceholder ? null : (
                      <div className="flex items-center gap-1.5">
                        <Subtitle2>{flexRender(header.column.columnDef.header, header.getContext())}</Subtitle2>
                        {canSort ? (
                          <span
                            className={twJoin(
                              "shrink-0 text-grey-dark",
                              isSorted ? "text-aop-basic-blue" : "text-grey-dark",
                            )}
                          >
                            <TableSortIcon sorted={!!isSorted} desc={isSorted === "desc"} />
                          </span>
                        ) : null}
                      </div>
                    )}
                  </th>
                );
              })}
            </tr>
          ))}
        </thead>
        <tbody className={isLoading ? "pointer-events-none opacity-30" : undefined}>
          {!hasRows && isLoading ? (
            <tr>
              <td>
                <div className="h-52">
                  <FullSizeLoader withPadding />
                </div>
              </td>
            </tr>
          ) : (
            rows.map((row) => {
              return (
                <tr
                  data-testid="table-row"
                  key={row.id}
                  className={twJoin(
                    "group odd:bg-aop-light-grey even:bg-white hover:bg-grey-lightest",
                    onRowClick && "cursor-pointer",
                  )}
                  onClick={onRowClick ? () => onRowClick(row) : undefined}
                >
                  {row.getVisibleCells().map((cell) => {
                    return (
                      <td
                        className={twResolve(
                          "h-12 border-r border-grey-light/20 px-2 text-left first:pl-3 last:border-r-0 last:pr-0 lg:px-4 first:lg:pl-5 xl:px-6 first:xl:pl-8",
                          children ? undefined : "group-last:first:rounded-bl-lg group-last:last:rounded-br-lg",
                          firstCellIsIndicator ? "first:px-0" : undefined,
                          hideBorder ? "border-r-0" : undefined,
                        )}
                        key={cell.id}
                        style={{
                          width: cell.column.columnDef.size ? Math.round(cell.column.columnDef.size) : undefined,
                          minWidth: cell.column.columnDef.minSize
                            ? Math.round(cell.column.columnDef.minSize)
                            : undefined,
                          maxWidth: cell.column.columnDef.maxSize
                            ? Math.round(cell.column.columnDef.maxSize)
                            : undefined,
                        }}
                      >
                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                      </td>
                    );
                  })}
                </tr>
              );
            })
          )}
        </tbody>
      </table>
      {children ? (
        <div className="rounded-b-lg border-t border-grey-light/20 bg-white px-4 pb-2">{children}</div>
      ) : null}
    </div>
  );
}
