import iconUndo from "assets/icons/reverse-left.svg";
import iconRedo from "assets/icons/reverse-right.svg";
import { AIButton } from "components/Button/AIButton";
import { IconButton } from "components/Button/IconButton";
import { Icon } from "components/Icon/Icon";
import { useTranslation } from "react-i18next";

interface TicketCommentFieldAIActionsProps {
  isCopilotText: boolean;
  copilotText: string;
  onGenerate: () => void;
  onUndo: () => void;
  onRedo: () => void;
}

export function TicketCommentFieldAIActions({
  isCopilotText,
  copilotText,
  onGenerate,
  onUndo,
  onRedo,
}: TicketCommentFieldAIActionsProps): React.ReactNode {
  const { t } = useTranslation();

  return (
    <div className="flex shrink-0 grow flex-row gap-1 self-start @4xl:gap-2">
      <AIButton data-testid="ticket-response-ai-button" onClick={onGenerate}>
        {isCopilotText
          ? t("page.tickets.details.copilot.button.regenerate")
          : t("page.tickets.details.copilot.button.generate")}
      </AIButton>
      {isCopilotText && (
        <IconButton styling="secondary" title={t("common.action.undo")} onClick={onUndo}>
          <Icon name={iconUndo} size={16} />
        </IconButton>
      )}
      {copilotText !== "" && (
        <IconButton styling="secondary" title={t("common.action.redo")} onClick={onRedo}>
          <Icon name={iconRedo} size={16} />
        </IconButton>
      )}
    </div>
  );
}
